import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import classNames from "classnames";

import { FIXME } from "../../../shared/types";

import classes from "./ListView.module.css";

interface ListViewProps {
  className?: string;
  items: FIXME;
  itemSize: number;
  row: FIXME;
}

export default function ListView({ className = "", items, itemSize, row }: ListViewProps) {
  return (
    <div className={classNames(classes.wrapper, className)}>
      <AutoSizer>
        {({ height, width }) => (
          <FixedSizeList
            height={height}
            itemCount={items.length}
            itemData={items}
            itemSize={itemSize}
            width={width}
          >
            {row}
          </FixedSizeList>
        )}
      </AutoSizer>
    </div>
  );
}
