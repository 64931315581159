import { useState } from "react";
import { useQuery } from "@apollo/client";
import { ChevronRight as ChevronRightIcon, Wc as WcIcon } from "@mui/icons-material";
import classNames from "classnames";

import { GET_ALL_TAGS } from "../../../graphql/tags.graphql";
import { getTagCategories } from "../../../shared/tag-helpers";
import { FIXME } from "../../../shared/types";
import FormModal from "../FormModal";

import classes from "./Gender.module.css";

interface GenderProps {
  gender: string;
  onChange?: FIXME;
}

export default function Gender({ gender, onChange = () => null }: GenderProps) {
  const [showingModal, setShowingModal] = useState(false);

  const handleOnSelect = (gender: string) => {
    onChange(gender);
    setShowingModal(false);
  };

  const { data: tagsData } = useQuery(GET_ALL_TAGS);
  const { genders } = getTagCategories(tagsData?.tags || []);

  if (showingModal) {
    return (
      <FormModal onClose={() => setShowingModal(false)} title="Gender">
        <div className={classes.list}>
          {genders.map((tag) => (
            <button
              className={classNames(classes.gender, gender === tag.name && classes.selected)}
              key={tag.name}
              onClick={() => handleOnSelect(tag.name)}
              type="button"
            >
              {tag.name}
            </button>
          ))}
        </div>
      </FormModal>
    );
  }

  return (
    <div className={classes.wrapper}>
      <button className={classes.header} onClick={() => setShowingModal(true)} type="button">
        <WcIcon className={classes.icon} />
        <span className={classes.text}>Gender</span>
        <ChevronRightIcon className={classes.arrow} />
      </button>

      {gender && (
        <div className={classes.selection}>
          <span className={classNames(classes.gender, classes.selected)}>{gender}</span>
        </div>
      )}
    </div>
  );
}
