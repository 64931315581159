import { graphql } from "./__generated__/gql";

export const GET_ALL_TAGS = graphql(`
  query GetAllTags {
    tags {
      tagId
      name
      parent {
        tagId
        name
      }
      grandparent {
        tagId
        name
      }
    }
  }
`);