import { VisibilityOutlined as VisibilityOutlinedIcon } from "@mui/icons-material";

import { FIXME } from "../../../shared/types";
import Toggle from "../Toggle";

import classes from "./AuthorHidden.module.css";

interface AuthorHiddenProps {
  isAuthorHidden?: boolean;
  onChange?: FIXME;
}

export default function AuthorHidden({ isAuthorHidden, onChange }: AuthorHiddenProps) {
  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <VisibilityOutlinedIcon className={classes.icon} />
        <span className={classes.text}>Hide author</span>
      </div>
      <p className={classes.copy}>Clubs can show the author, or hide them</p>
      <label className={classes.setting}>
        <span className={classes.label}>Hide Author</span>
        <Toggle checked={isAuthorHidden} onChange={onChange} />
      </label>
    </div>
  );
}
