import { useContext, useEffect, useRef, useState } from "react";
import { ArrowBack as ArrowBackIcon, MoreVert as MoreVertIcon, InfoOutlined as InfoIcon } from "@mui/icons-material";
import { Popper } from "@mui/material";

import { AppContext } from "../../../providers/AppProvider";
import { AuthContext } from "../../../providers/AuthProvider";
import { ChatsContext } from "../../../providers/ChatsProvider";
import { getImageUrl } from "../../../shared/image-helpers";
import { FIXME } from "../../../shared/types";
import IconButton from "../../Forms/IconButton";
import Link from "../../Shared/Link";

import classes from "./ChatHeader.module.css";

type ChatHeaderProps = {
  chat: FIXME;
};

export default function ChatHeader({ chat }: ChatHeaderProps) {
  const { deleteChat } = useContext(ChatsContext);
  const { goBack, navigate } = useContext(AppContext);
  const { userId } = useContext(AuthContext);

  const [link, setLink] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  const [chatInfoLink, setChatInfoLink] = useState("")

  const ref = useRef<FIXME>();

  const handleDeleteChat = async () => {
    const confirmed = window.confirm(`Are you sure you want to delete this chat?`);
    if (confirmed) {
      await deleteChat(chat.chatId);
      navigate("/");
    }
  };

  const handleOnShowMenu = () => setShowMenu(!showMenu);

  useEffect(() => {
    if (chat?.userId) setLink(`/profile/${chat.userId}`);
    else if (chat?.clubId) setLink(`/clubs/${chat.clubId}`);
    // else if (chat?.eventId) setLink(`/events/${chat.eventId}`);

    if(chat)
      setChatInfoLink(`/chats/${chat.chatId}/info`)
  }, [chat]);

  const isAdmin =
    chat?.club?.admins?.find((admin: FIXME) => admin?.userId === userId) ||
    chat?.event?.admins?.find((admin: FIXME) => admin?.userId === userId);

  return (
    <header className={classes.wrapper} ref={ref}>
      <button className={classes.back} onClick={goBack} type="button">
        <ArrowBackIcon />
      </button>
      <Link to={link} className={classes.content}>
        {chat?.clubId && (
          <>
            <img
              className={classes.image}
              src={getImageUrl({ url: chat.club.image, height: 36, width: 36 })}
            />
            <div className={classes.name}>{chat.name}</div>
            <div className={classes.room}>{chat.club.name}</div>
          </>
        )}
        {chat?.eventId && (
          <>
            <img
              className={classes.image}
              src={getImageUrl({ url: chat.event.image, height: 36, width: 36 })}
            />
            <div className={classes.name}>{chat.name}</div>
            <div className={classes.room}>{chat.event.name}</div>
          </>
        )}
        {!chat?.clubId && !chat?.eventId && (
          <>
            <img
              className={classes.image}
              src={getImageUrl({ url: chat?.participants[0]?.avatar, height: 36, width: 36 })}
            />
            <div className={classes.name}>{chat?.participants[0].name}</div>
            <div className={classes.room}>{chat?.participants[0].username}</div>
          </>
        )}
      </Link>
      <Link className={classes.info} to={chatInfoLink}>
        <IconButton icon={<InfoIcon />} />
      </Link>
      {isAdmin && (
        <>
          <div className={classes.actions}>
            <IconButton icon={<MoreVertIcon />} onClick={handleOnShowMenu} />
          </div>
          <Popper anchorEl={ref.current} placement="bottom-end" open={showMenu}>
            <div className={classes.menu}>
              <div className={classes.contents}>
                <ul>
                  <li>
                    <button className={classes.action} onClick={handleDeleteChat} type="button">
                      Delete chat
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </Popper>
        </>
      )}
    </header>
  );
}
