import { ReactElement, useEffect, useRef, useState } from "react";

import { NearMeConnect } from "../../NearMe/Tabs/ConnectsTab.tsx";
import Loading from "../../Shared/Loading";
import Card from "../Card";

import classes from "./List.module.css";
import LoadMoreConnectsCard from "../Card/LoadMore.tsx";

interface ListProps {
  title: ReactElement | string;
  users: NearMeConnect[];
  isLoading?: boolean
  listTotal?: number
  loadMoreAction?: () => Promise<void>
  error?: string
}

export default function List({ title, users, isLoading=false, loadMoreAction, error, listTotal=0 }: ListProps) {
  const currentLastCardRef = useRef<HTMLLIElement>(null)
  const containerRef = useRef<HTMLUListElement>(null)
  const [savedLastCardRef,setSavedLastCardRef] = useState<HTMLLIElement|null>(null)
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false)

  useEffect(() => {
    if(!isLoadingMore) return

    if(savedLastCardRef && containerRef.current){
      containerRef.current.scrollLeft = savedLastCardRef.offsetLeft
    }
    setIsLoadingMore(false)
  }, [users]);

  useEffect(() => {
    if(!error) return

    setIsLoadingMore(false)
  }, [error]);

  const onLoadMoreAction = async () =>{
    setIsLoadingMore(true)
    setSavedLastCardRef(currentLastCardRef.current)
    if(loadMoreAction) {
      await loadMoreAction()
    }
  }

  return (
    <div className={classes.wrapper}>
      <h2 className={classes.title}>{title}</h2>
      <ul className={classes.list} ref={containerRef}>
        {isLoading ? (
            <Loading />
          ) : (
            users.map((user, index) => (
          <li
            ref={index === users.length -1 ? currentLastCardRef : undefined}
            className={classes.item} key={user.userId}
          >
            <Card user={user} />
          </li>
        ))
          )}
        { users.length < listTotal && !isLoading &&
          <li className={classes.item} key={`load-more`}>
            {isLoadingMore? <Loading /> :<LoadMoreConnectsCard clickAction={onLoadMoreAction} text={error? error: 'Load More Users'}/>}
          </li>
        }
      </ul>
    </div>
  );
}
