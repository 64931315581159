import { useQuery } from "@apollo/client";

import Actions from "../../components/Clubs/Actions";
import DeleteClub from "../../components/Clubs/DeleteClub";
import Description from "../../components/Clubs/Description";
import Header from "../../components/Clubs/Header";
import JoinChat from "../../components/Clubs/JoinChat";
import JoinRequests from "../../components/Clubs/JoinRequests";
import Members from "../../components/Clubs/Members";
import List from "../../components/Events/List";
import BackTo from "../../components/Shared/BackTo";
import ErrorMessage from "../../components/Shared/ErrorMessage";
import Loading from "../../components/Shared/Loading";
import { GET_CLUB } from "../../graphql/clubs.graphql";
import { FIXME } from "../../shared/types";
import { Event } from "../../graphql/__generated__/graphql.ts";

import classes from "./ClubPage.module.css";

export default function ClubPage({ clubId }: FIXME) {
  if (!clubId) throw new Error("You must provide a valid clubId");

  const {
    data: clubData,
    loading,
    error,
  } = useQuery(GET_CLUB, {
    fetchPolicy: "cache-and-network",
    variables: { clubId },
  });

  if (error) return <ErrorMessage message={error.message} />;
  if (!clubData || !clubData?.club || loading) return <Loading />;

  const events = (clubData.club.events as Event[] | null) || undefined;
  const pastEvents = (clubData.club.pastEvents as Event[] | null) || undefined;

  const hasEvents = events?.length && events?.length > 0;
  const hasPastEvents = pastEvents?.length && pastEvents?.length > 0;

  return (
    <div className={classes.page}>
      <JoinRequests club={clubData.club} />
      <BackTo />
      <Header club={clubData.club} />
      <Actions club={clubData.club} />
      <Description club={clubData.club} />
      <Members club={clubData.club} />
      <JoinChat club={clubData.club} />
      {hasEvents ? <List events={events} title="Upcoming Events" /> : null}
      {hasPastEvents ? <List events={pastEvents} title="Past Events" /> : null}
      <DeleteClub club={clubData.club} />
    </div>
  );
}
