import { ReactElement, useEffect, useRef, useState } from "react";
import classNames from "classnames";

import {Event} from '../../../graphql/__generated__/graphql.ts'
import LoadMoreEventsCard from "../../Events/Card/LoadMoreEvents.tsx";
import Loading from "../../Shared/Loading";
import Card from "../Card";

import classes from "./List.module.css";

interface ListProps {
  condensed?: boolean;
  events?: Event[] | null;
  title: ReactElement | string;
  isLoading?: boolean
  listTotal?: number
  loadMoreAction?: () => Promise<void>
  error?: string
}

export default function List({ condensed = false, events=[], title, isLoading=false, loadMoreAction, error, listTotal=0 }: ListProps) {
  const currentLastCardRef = useRef<HTMLLIElement>(null)
  const containerRef = useRef<HTMLUListElement>(null)
  const [savedLastCardRef,setSavedLastCardRef] = useState<HTMLLIElement|null>(null)
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false)

  useEffect(() => {
    if(!isLoadingMore) return

    if(savedLastCardRef && containerRef.current){
      containerRef.current.scrollLeft = savedLastCardRef.offsetLeft
    }
    setIsLoadingMore(false)
  }, [events]);

  useEffect(() => {
    if(!error) return

    setIsLoadingMore(false)
  }, [error]);

  const onLoadMoreAction = async () =>{
    setIsLoadingMore(true)
    setSavedLastCardRef(currentLastCardRef.current)
    if(loadMoreAction) {
      await loadMoreAction()
    }
  }

  return (
    <div className={classes.wrapper}>
      <h2 className={classes.title}>{title}</h2>
      <ul className={classes.list} ref={containerRef}>
        {isLoading ? (
          <Loading />
        ) : (events?.map((event: Event, index) => (
          <li
            ref={index === events.length -1 ? currentLastCardRef : undefined}
            className={classNames(classes.item, condensed && classes.condensed)}
            key={event.eventId}
          >
            <Card condensed={condensed} event={event} />
          </li>
        )))}
        { events?.length && events.length < listTotal && !isLoading &&
          <li className={classes.item} key={`load-more`}>
            {isLoadingMore? <Loading /> :<LoadMoreEventsCard clickAction={onLoadMoreAction} text={error? error: 'Load More Events'}/>}
          </li>
        }
      </ul>
    </div>
  );
}
