import classNames from "classnames";

import { FIXME } from "../../../shared/types";

import classes from "../Onboarding.module.css";

type InterestsProps = {
  error: string;
  interestIds: string[];
  interests: FIXME[];
  onChange: (interestIds: string[]) => void;
};

export default function Interests({
  error,
  interestIds = [],
  interests,
  onChange,
}: InterestsProps) {
  const handleOnToggle = (tagId: string) => {
    const updated = interestIds.includes(tagId)
      ? interestIds.filter((selectedId) => selectedId !== tagId)
      : [...interestIds, tagId];

    onChange(updated);
  };

  return (
    <div className={classes.container}>
      <div className={classes.intro}>Sign Up</div>
      <div className={classes.title}>Select your interests</div>
      {error && <div className={classes.error}>{error}</div>}
      <div className={classes.tags}>
        {interests.map((tag) => (
          <button
            className={classNames(classes.tag, interestIds.includes(tag.tagId) && classes.selected)}
            key={tag.tagId}
            onClick={() => handleOnToggle(tag.tagId)}
            type="button"
          >
            {tag.name}
          </button>
        ))}
      </div>
    </div>
  );
}
