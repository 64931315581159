import { useContext, useState } from "react";
import { CircularProgress } from "@mui/material";

import { Club, User } from "../../../graphql/__generated__/graphql";
import { NotificationsContext } from "../../../providers/NotificationsProvider";
import { Button } from "../../Forms";
import Avatar from "../../Shared/Avatar";
import Link from "../../Shared/Link";

import classes from "./ClubJoinedRequestNotification.module.css";

interface ClubJoinedRequestNotificationProps {
  club: Club;
  user: User;
  notificationId: string;
}

export default function ClubJoinedRequestNotification({
  club,
  user,
  notificationId,
}: ClubJoinedRequestNotificationProps) {
  const { markNotificationRead } = useContext(NotificationsContext);

  const [loading, setLoading] = useState(false);

  const handleOnOK = () => {
    setLoading(true);
    markNotificationRead(notificationId);
  };

  return (
    <div className={classes.notification}>
      <Link className={classes.avatar} to={`/profile/${user.userId}`}>
        <Avatar size={46} user={user} />
      </Link>
      <Link className={classes.text} to={`/clubs/${club.clubId}`}>
        <span>{user.name}</span> wants to join <span>{club.name}</span>
      </Link>
      <div className={classes.action}>
        {loading ? (
          <CircularProgress size={30} />
        ) : (
          <Button color="secondary" onClick={handleOnOK} size="small">
            OK
          </Button>
        )}
      </div>
    </div>
  );
}
