import { useContext } from "react";
import { ApolloClient, ApolloProvider, InMemoryCache, from, HttpLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";

import Loading from "../components/Shared/Loading";
import { FIXME } from "../shared/types";

import { AuthContext } from "./AuthProvider";
import { callNativeApp, NativeAppActions } from "../shared/native-app-helpers.ts";

const { VITE_APOLLO_ENABLE_DEVTOOLS, VITE_GRAPHQL_ENDPOINT } = import.meta.env;

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
      if(message.includes('The user record is disabled')) {
        console.log('Login out')
        callNativeApp({ action: NativeAppActions.LOG_OUT });
        // TODO: Remove once everyone is at a latest version
        callNativeApp({ action: NativeAppActions.SIGN_OUT });
      }
    });

  }
  if (networkError) console.error(`[Network error]: ${networkError}`);
});

export const GraphQLProvider = ({ children }: FIXME) => {
  const { token } = useContext(AuthContext);

  if (!token) {
    return <Loading />;
  }

  const httpLink = new HttpLink({
    uri: VITE_GRAPHQL_ENDPOINT,
    headers:  {
      Authorization: token,
    },
  })

  const client = new ApolloClient({
    connectToDevTools: VITE_APOLLO_ENABLE_DEVTOOLS === "true",
    cache: new InMemoryCache({
      typePolicies: {
        Chat: { keyFields: ["chatId"] },
        Club: { keyFields: ["clubId"] },
        Event: { keyFields: ["eventId"] },
        Location: { keyFields: ["locationId"] },
        Tag: { keyFields: ["tagId"] },
        User: { keyFields: ["userId"] },
      },
    }),
    link: from([errorLink, httpLink]),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
