import { useContext } from "react";

import { AppContext } from "../../../providers/AppProvider";
import { FIXME } from "../../../shared/types";

type LinkProps = {
  className?: string;
  children: FIXME;
  id?: string;
  onClick?: FIXME;
  reset?: boolean;
  style?: FIXME;
  to: string;
};

export default function Link({
  className = "",
  children,
  id,
  onClick = () => null,
  reset: shouldReset,
  style = {},
  to,
  ...props
}: LinkProps) {
  const { navigate, reset } = useContext(AppContext);

  return (
    <div
      className={className}
      id={id}
      onClick={() => {
        if (shouldReset) reset(to);
        else navigate(to);
        onClick();
      }}
      style={style}
      {...props}
    >
      {children}
    </div>
  );
}
