import { ReactNode } from "react";
import { TextareaAutosize } from "@mui/material";
import classNames from "classnames";
import { v4 as uuid } from "uuid";

import { FIXME } from "../../../shared/types";

import useScrollToInput from "../../Chats/NewMessageForm/scrollIntoView";
import classes from "./Input.module.css";

interface InputProps {
  autoCapitalize?: "characters" | "off" | "on" | "words";
  autoComplete?: "bday-day" | "bday-month" | "bday-year" | "name" | "off" | "username";
  autoCorrect?: "off" | "on";
  autoFocus?: boolean;
  className?: string;
  help?: ReactNode;
  hideLabel?: boolean;
  inputmode?: "numeric";
  label: string;
  max?: number | string;
  min?: number | string;
  name?: string;
  onChange?: (value: string) => void;
  onKeyUp?: (value: string) => void;
  pattern?: "[0-9]*";
  placeholder?: string;
  ref?: FIXME;
  required?: boolean;
  rows?: number;
  type?:
    | "autoresize"
    | "date"
    | "datetime-local"
    | "number"
    | "search"
    | "text"
    | "time"
    | "textarea"
    | "url";
  value?: number | string;
  width?: "width-33" | "width-50" | "width-100";
}

export default function Input({
  autoCapitalize,
  autoComplete,
  autoCorrect,
  autoFocus = false,
  className = "",
  help,
  hideLabel = false,
  inputmode,
  label,
  max,
  min,
  name = `field-${uuid()}`,
  onChange = () => null,
  onKeyUp = () => null,
  pattern,
  placeholder = "",
  ref,
  required = false,
  rows = 2,
  type = "text",
  value,
  width = "width-100",
}: InputProps) {
  const [inputRef, scrollToInput] = useScrollToInput();
  const isIOS = () => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent);
  };

  const handleBlur = () => {
    if (inputRef.current) {
      if (isIOS()) {
        setTimeout(() => {
          inputRef.current.style.position = "";
          inputRef.current.style.bottom = "";
          inputRef.current.style.left = "";
          inputRef.current.style.width = "";
          inputRef.current.style.zIndex = "";
        }, 100);
      }
    }
  };

  return (
    <div className={classes.input} ref={inputRef}>
      <label className={classNames(classes.label, hideLabel && "sr-only")} htmlFor={name}>
        {label}
      </label>
      {help && <div className={classes.help}>{help}</div>}
      {type === "autoresize" && (
        <TextareaAutosize
          autoCapitalize={autoCapitalize}
          autoComplete={autoComplete}
          autoCorrect={autoCorrect}
          autoFocus={autoFocus}
          className={classNames(classes.field, classes[width], className)}
          defaultValue={value}
          id={name}
          inputMode={inputmode}
          name={name}
          onChange={(evt) => onChange(evt.target.value)}
          onKeyUp={(evt: FIXME) => onKeyUp(evt.target.value)}
          placeholder={placeholder}
          ref={ref}
          required={required}
          maxRows={rows}
          onFocus={scrollToInput}
          onBlur={handleBlur}
        />
      )}
      {type === "textarea" ? (
        <textarea
          autoCapitalize={autoCapitalize}
          autoComplete={autoComplete}
          autoCorrect={autoCorrect}
          autoFocus={autoFocus}
          className={classNames(classes.field, classes[width], className)}
          defaultValue={value}
          id={name}
          inputMode={inputmode}
          name={name}
          onChange={(evt) => onChange(evt.target.value)}
          onKeyUp={(evt: FIXME) => onKeyUp(evt.target.value)}
          placeholder={placeholder}
          ref={ref}
          required={required}
          rows={rows}
        />
      ) : (
        <input
          autoCapitalize={autoCapitalize}
          autoComplete={autoComplete}
          autoCorrect={autoCorrect}
          autoFocus={autoFocus}
          className={classNames(classes.field, classes[width], className)}
          defaultValue={value}
          id={name}
          inputMode={inputmode}
          max={max}
          min={min}
          name={name}
          onChange={(evt) => onChange(evt.target.value)}
          onKeyUp={(evt: FIXME) => onKeyUp(evt.target.value)}
          pattern={pattern}
          placeholder={placeholder}
          ref={ref}
          required={required}
          type={type}
        />
      )}
    </div>
  );
}
