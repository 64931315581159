import { useContext, useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import { GET_CLUB } from "../../../graphql/clubs.graphql";
import { GET_IS_JOINED, JOINING, LEAVING, REQUESTING } from "../../../graphql/joining.graphql";
import { ChatsContext } from "../../../providers/ChatsProvider";
import { FIXME } from "../../../shared/types";
import { Button } from "../../Forms";

interface JoinButtonProps {
  club: FIXME;
}

export default function JoinButton({ club }: JoinButtonProps) {
  const { updateChats } = useContext(ChatsContext);

  const [loading, setLoading] = useState(false);
  const [isJoined, setIsJoined] = useState(false);
  const [hasRequestedJoin, setHasRequestedJoin] = useState(false);

  const [refreshClub] = useLazyQuery(GET_CLUB, {
    variables: { clubId: club.clubId },
  });

  const { data, refetch: refetchIsJoined } = useQuery(GET_IS_JOINED, {
    fetchPolicy: "cache-and-network",
    variables: { clubId: club.clubId },
  });

  const [joining] = useMutation(JOINING);
  const [requesting] = useMutation(REQUESTING);
  const [leaving] = useMutation(LEAVING);

  const handleOnJoin = async () => {
    setLoading(true);

    if (club.isPrivate) {
      setHasRequestedJoin(true);
      await requesting({
        onCompleted: async () => {
          await refreshClub();
          await refetchIsJoined();
          await updateChats();
          setLoading(false);
        },
        variables: { clubId: club.clubId },
      });
    } else {
      setIsJoined(true);
      await joining({
        onCompleted: async () => {
          await refreshClub();
          await refetchIsJoined();
          await updateChats();
          setLoading(false);
        },
        variables: { clubId: club.clubId },
      });
    }
  };

  const handleOnLeave = async () => {
    const confirmed = window.confirm(`Are you sure you want to leave ${club.name}?`);
    if (confirmed) {
      setIsJoined(false);
      await leaving({
        onCompleted: async () => {
          await refreshClub();
          await refetchIsJoined();
          await updateChats();
        },
        variables: { clubId: club.clubId },
      });
    }
  };

  useEffect(() => {
    setIsJoined(!!data?.isJoined);
    setHasRequestedJoin(!!data?.hasRequestedJoin);
  }, [data]);

  if (isJoined)
    return (
      <Button color="secondary" data-testid="leave-club-action" fullWidth onClick={handleOnLeave}>
        Joined
      </Button>
    );

  if (hasRequestedJoin)
    return (
      <Button color="secondary" data-testid="joining-club-action" fullWidth>
        Joining
      </Button>
    );

  return (
    <Button data-testid="join-club-action" disabled={loading} fullWidth onClick={handleOnJoin}>
      Join
    </Button>
  );
}
