import { graphql } from "./__generated__/gql";

export const GET_NOTIFICATIONS = graphql(`
  query GetNotifications {
    notifications {
      notificationId
      type
      recipientId
      clubId
      club {
        clubId
        name
        image
      }
      eventId
      event {
        eventId
        name
        image
      }
      userId
      user {
        userId
        name
        avatar
      }
      createdAt
    }
  }
`);

export const READ_NOTIFICATION = graphql(`
  mutation MarkNotificationRead($notificationId: ID!) {
    markNotificationRead(notificationId: $notificationId)
  }
`);

export const CREATE_USER_NOTIFICATION_TOKEN = graphql(`
  mutation CreateUserNotificationToken($token: String!) {
    createUserNotificationToken(token: $token)
  }
`);

export const DELETE_USER_NOTIFICATION_TOKEN = graphql(`
    mutation DeleteUserNotificationToken($token: String!) {
        deleteUserNotificationToken(token: $token)
    }
`);
