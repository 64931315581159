import { useContext, useState } from "react";
import { TextareaAutosize } from "@mui/base";
// import { AddBox as AddBoxIcon, ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { AddBox as AddBoxIcon } from "@mui/icons-material";

import { AppContext } from "../../../providers/AppProvider";
import { AuthContext } from "../../../providers/AuthProvider";
import { ChatsContext } from "../../../providers/ChatsProvider";
import { getImageUrl } from "../../../shared/image-helpers";
import { FIXME } from "../../../shared/types";
import { Button } from "../../Forms";

import classes from "./ChatsHeader.module.css";

type ChatsHeaderProps = {
  club?: FIXME;
  event?: FIXME;
};

export default function ChatsHeader({ club, event }: ChatsHeaderProps) {
  const { navigate } = useContext(AppContext);
  const { userId } = useContext(AuthContext);

  const { createClubChat, createEventChat } = useContext(ChatsContext);

  const [adding, setAdding] = useState(false);
  const [saving, setSaving] = useState(false);
  const [newChatName, setNewChatName] = useState("");

  const handleOnChangeName = (evt: FIXME) => {
    const { value } = evt.target;
    setNewChatName(value);
  };

  const handleOnCreateChat = async () => {
    if (newChatName.trim().length === 0) return;

    setSaving(true);

    if (club) {
      await createClubChat(club.clubId, newChatName.trim(), (chatId: FIXME) => {
        navigate(`/chats/${chatId}`);
        setNewChatName("");
        setAdding(false);
        setSaving(false);
      });
    }

    if (event) {
      await createEventChat(event.eventId, newChatName.trim(), (chatId: FIXME) => {
        navigate(`/chats/${chatId}`);
        setNewChatName("");
        setAdding(false);
        setSaving(false);
      });
    }
  };

  const isAdmin =
    club?.admins?.find((admin: FIXME) => admin?.userId === userId) ||
    event?.admins?.find((admin: FIXME) => admin?.userId === userId);

  return (
    <>
      <div className={classes.wrapper}>
        {(club || event || isAdmin) && (
          <button className={classes.button} type="button">
            {/* <ArrowBackIcon /> */}
            {club && club.image && (
              <img
                className={classes.image}
                src={getImageUrl({ url: club.image, height: 46, width: 46 })}
              />
            )}
            {event && event.image && (
              <img
                className={classes.image}
                src={getImageUrl({ url: event.image, height: 46, width: 46 })}
              />
            )}
            <div>
              {club && <div className={classes.subtitle}>{club.name}</div>}
              {event && <div className={classes.subtitle}>{event.name}</div>}
            </div>
          </button>
        )}

        {isAdmin && (
          <button className={classes.add} type="button">
            <AddBoxIcon onClick={() => setAdding(true)} />
          </button>
        )}
      </div>

      {adding && (
        <div className={classes.adding} onClick={() => setAdding(false)}>
          <form className={classes.form} onClick={(evt: FIXME) => evt.stopPropagation()}>
            <p className={classes.text}>What is the name of your new chat?</p>
            <TextareaAutosize
              autoFocus
              className={classes.textarea}
              maxRows={4}
              onChange={handleOnChangeName}
              placeholder="New Chat name"
              value={newChatName}
            />
            <Button disabled={saving} fullWidth onClick={handleOnCreateChat}>
              {saving ? "Saving" : "Create Chat"}
            </Button>
          </form>
        </div>
      )}
    </>
  );
}
