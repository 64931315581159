import { useEffect, useState } from "react";
import classNames from "classnames";

import Input from "../../Forms/Input";

import classes from "../Onboarding.module.css";

type DateOfBirthProps = {
  dob: string;
  error: string;
  onChange: (value: string) => void;
};

export default function DateOfBirth({ dob, error, onChange }: DateOfBirthProps) {
  const [day, setDay] = useState<number>();
  const [month, setMonth] = useState<number>();
  const [year, setYear] = useState<number>();

  useEffect(() => {
    if (day && month && year) {
      try {
        const date = new Date(year, month - 1, day);
        onChange(date.toISOString());
      } catch {
        //
      }
    }
  }, [day, month, year]);

  useEffect(() => {
    if (dob) {
      try {
        const date = new Date(dob);
        setDay(date.getUTCDate());
        setMonth(date.getUTCMonth() + 1);
        setYear(date.getUTCFullYear());
      } catch {
        //
      }
    }
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.intro}>Sign Up</div>
      <div className={classes.title}>Enter your date of birth</div>
      {error && <div className={classes.error}>{error}</div>}
      <div className={classes.dateOfBirth}>
        <div className={classes.dateOfBirthField}>
          <Input
            autoComplete="bday-day"
            className={classes.input}
            inputmode="numeric"
            label="Day"
            max={31}
            min={1}
            name="day"
            onChange={(value) => setDay(parseInt(value))}
            required
            type="number"
            value={day}
          />
        </div>
        <div className={classes.dateOfBirthField}>
          <Input
            autoComplete="bday-month"
            className={classes.input}
            inputmode="numeric"
            label="Month"
            max={12}
            min={1}
            name="month"
            onChange={(value) => setMonth(parseInt(value))}
            required
            type="number"
            value={month}
          />
        </div>
        <div className={classes.dateOfBirthField}>
          <Input
            autoComplete="bday-year"
            className={classNames(classes.input, classes.width4)}
            inputmode="numeric"
            label="Year"
            min={new Date().getFullYear() - 100}
            max={new Date().getFullYear()}
            name="year"
            onChange={(value) => setYear(parseInt(value))}
            required
            type="number"
            value={year}
          />
        </div>
      </div>
    </div>
  );
}
