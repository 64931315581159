import { useState } from "react";
import { useQuery } from "@apollo/client";

import { default as ConnectListViewRow } from "../../components/Connects/ListViewRow";
import BackTo from "../../components/Shared/BackTo";
import ErrorMessage from "../../components/Shared/ErrorMessage";
import ListView from "../../components/Shared/ListView";
import Loading from "../../components/Shared/Loading";
import Tabs from "../../components/Shared/Tabs";
import { GET_USER_FOLLOWERS_FOLLOWING } from "../../graphql/users.graphql";

import classes from "./FollowersFollowingPage.module.css";

interface FollowersFollowingPageProps {
  defaultTab?: number;
  profileId: string;
}

export default function FollowersFollowingPage({
  defaultTab = 0,
  profileId: userId,
}: FollowersFollowingPageProps) {
  if (!userId) throw new Error(`Must provide a userId`);

  const [selectedTab, setSelectedTab] = useState(defaultTab);

  const { data, error, loading } = useQuery(GET_USER_FOLLOWERS_FOLLOWING, {
    variables: { userId },
  });

  if (error) return <ErrorMessage message={error.message} />;
  if (!data || !data.user || loading) return <Loading />;

  const tabs = [
    { label: `Followers (${data?.user?.followers?.length})`, onSelect: () => setSelectedTab(0) },
    { label: `Following  (${data?.user?.following?.length})`, onSelect: () => setSelectedTab(1) },
  ];

  return (
    <div className={classes.page}>
      <BackTo />
      <Tabs className={classes.tabs} selectedIndex={selectedTab} tabs={tabs} />
      {selectedTab === 0 && (
        <ListView items={data?.user?.followers} itemSize={50} row={ConnectListViewRow} />
      )}
      {selectedTab === 1 && (
        <ListView items={data?.user?.following} itemSize={50} row={ConnectListViewRow} />
      )}
    </div>
  );
}
