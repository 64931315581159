import { useContext } from "react";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import classNames from "classnames";

import { AppContext } from "../../../providers/AppProvider";

import classes from "./BackTo.module.css";

interface BackToProps {
  className?: string;
}

export default function BackTo({ className = "" }: BackToProps) {
  const { goBack, stack } = useContext(AppContext);

  const previous = stack[stack.length - 2];
  // alert(JSON.stringify(stack));

  if (previous.name === 'Near Me') {
    return null;
  }

  return (
    <button className={classNames(classes.button, className)} onClick={goBack} type="button">
      <ArrowBackIcon />
      Back to {previous.name}
    </button>
  );
}
