import { useQuery } from "@apollo/client";
import classNames from "classnames";

import ClubsList from "../../components/Clubs/List";
import ConnectsList from "../../components/Connects/List";
import EventsList from "../../components/Events/List";
import ErrorMessage from "../../components/Shared/ErrorMessage";
import Loading from "../../components/Shared/Loading";
import { GET_MY_CLIQ } from "../../graphql/my-cliq.graphql";

import classes from "./MyCliqPage.module.css";

export default function MyCliqPage() {
  const { data, error, loading } = useQuery(GET_MY_CLIQ, {
    fetchPolicy: "cache-and-network",
  });

  if (error) return <ErrorMessage message={error.message} />;
  if (!data || !data.myCLIQ || loading) return <Loading />;

  const isEmpty =
    data.myCLIQ.clubsOwned.length === 0 &&
    data.myCLIQ.clubsJoined.length === 0 &&
    data.myCLIQ.eventsAttending.length === 0 &&
    data.myCLIQ.eventsOwned.length === 0 &&
    data.myCLIQ.following.length === 0 &&
    data.myCLIQ.followers.length === 0;

  return (
    <div className={classNames(classes.page, isEmpty && classes.isEmpty)}>
      {isEmpty && (
        <p className={classes.empty}>
          Your CLIQ is currently empty!
          <br />
          <br />
          Connect with like-minded individuals, join clubs and discover events in the near me
          section to add them to your CLIQ.
        </p>
      )}
      {data.myCLIQ.clubsOwned.length > 0 && (
        <ClubsList clubs={data.myCLIQ.clubsOwned} title={<>Clubs you own:</>} />
      )}
      {data.myCLIQ.clubsJoined.length > 0 && (
        <ClubsList clubs={data.myCLIQ.clubsJoined} title={<>Clubs you've joined:</>} />
      )}
      {data.myCLIQ.eventsOwned.length > 0 && (
        <EventsList events={data.myCLIQ.eventsOwned} title={<>Events you own:</>} />
      )}
      {data.myCLIQ.eventsAttending.length > 0 && (
        <EventsList events={data.myCLIQ.eventsAttending} title={<>Events you're going to:</>} />
      )}
      {data.myCLIQ.following.length > 0 && (
        <ConnectsList users={data.myCLIQ.following} title={<>People you're following:</>} />
      )}
      {data.myCLIQ.followers.length > 0 && (
        <ConnectsList users={data.myCLIQ.followers} title={<>People who follow you:</>} />
      )}
    </div>
  );
}
