import { Tag } from "../graphql/__generated__/graphql";
import { FIXME } from "../shared/types";

export enum ProfileActions {
  UPDATE_ALL = "UPDATE_ALL",

  UPDATE_BIO = "UPDATE_BIO",
  UPDATE_GENDER = "UPDATE_GENDER",
  UPDATE_HEADLINE = "UPDATE_HEADLINE",
  UPDATE_IMAGES = "UPDATE_IMAGES",
  UPDATE_IS_HIDDEN = "UPDATE_IS_HIDDEN",
  UPDATE_IS_SHOW_AGE = "UPDATE_IS_SHOW_AGE",
  UPDATE_LINKS = "UPDATE_LINKS",
  UPDATE_LOCATION_ID = "UPDATE_LOCATION_ID",
  UPDATE_NAME = "UPDATE_NAME",
  UPDATE_TAGS = "UPDATE_TAGS",
}

export const profileReducer = (state: FIXME, action: FIXME) => {
  const updated = { ...state };

  switch (action.type) {
    case ProfileActions.UPDATE_ALL:
      updated.bio = action.bio;
      updated.gender = action.gender;
      updated.headline = action.headline;
      updated.images = action.images;
      updated.isHidden = action.isHidden;
      updated.isShowAge = action.isShowAge;
      updated.links = action.links;
      updated.locationId = action.location?.locationId;
      updated.name = action.name;
      updated.tags = action.tags?.map((tag: Tag) => tag.tagId);
      break;
    case ProfileActions.UPDATE_BIO:
      updated.bio = action.bio;
      break;
    case ProfileActions.UPDATE_GENDER:
      updated.gender = action.gender;
      break;
    case ProfileActions.UPDATE_HEADLINE:
      updated.headline = action.headline;
      break;
    case ProfileActions.UPDATE_IMAGES:
      updated.images = action.images;
      break;
    case ProfileActions.UPDATE_IS_HIDDEN:
      updated.isHidden = action.isHidden;
      break;
    case ProfileActions.UPDATE_IS_SHOW_AGE:
      updated.isShowAge = action.isShowAge;
      break;
    case ProfileActions.UPDATE_LINKS:
      updated.links = action.links;
      break;
    case ProfileActions.UPDATE_LOCATION_ID:
      updated.locationId = action.locationId;
      break;
    case ProfileActions.UPDATE_NAME:
      updated.name = action.name;
      break;
    case ProfileActions.UPDATE_TAGS:
      updated.tags = action.tags;
      break;
    default:
      throw new Error("Unknown profileReducer action");
  }

  return updated;
};
