import { useContext, useState } from "react";

import { ChatsContext } from "../../../providers/ChatsProvider";
import { FIXME } from "../../../shared/types";
import ListView from "../../Shared/ListView";
import Tabs from "../../Shared/Tabs";
import ChatListItem from "../ChatListItem";
import Loading from "../../Shared/Loading";

import classes from "./ChatList.module.css";

type ChatListProps = {
  clubId?: string;
  eventId?: string;
};

export default function ChatList({ clubId, eventId }: ChatListProps) {
  const {
    chats,
    childLoader,
    unreadConnectsCount,
    unreadClubsCount,
    unreadEventsCount,
    alldataLoad,
    updateTabAction,
    updateOffset,
  } = useContext(ChatsContext);

  const [selectedTab, setSelectedTab] = useState(0);
  const tabs = [
    {
      label: "Connects",
      onSelect: async () => {
        setSelectedTab(0);
        await updateTabAction("connects");
      },
      prepend:
        unreadConnectsCount > 0 ? (
          <span className={classes.unread}>{unreadConnectsCount}</span>
        ) : null,
    },
    {
      label: "Clubs",
      onSelect: async () => {
        setSelectedTab(1);
        await updateTabAction("clubs");
      },
      prepend:
        unreadClubsCount > 0 ? <span className={classes.unread}>{unreadClubsCount}</span> : null,
    },
    {
      label: "Events",
      onSelect: async () => {
        setSelectedTab(2);
        await updateTabAction("events");
      },
      prepend:
        unreadEventsCount > 0 ? <span className={classes.unread}>{unreadEventsCount}</span> : null,
    },
  ];

  const sort = (list: FIXME) => {
    list.sort((a: FIXME, b: FIXME) => {
      if (!a || !b) return 0;

      const messageACreatedAt = a.messages?.[0]?.createdAt;
      const messageBCreatedAt = b.messages?.[0]?.createdAt;

      const dateA = messageACreatedAt || a.createdAt;
      const dateB = messageBCreatedAt || b.createdAt;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return new Date(dateB) - new Date(dateA);
    });
  };

  if (clubId) {
    const list = chats.filter((chat: FIXME) => chat.clubId === clubId);
    if (list.length === 0) {
      return (
        <div className={classes.empty}>
          <p>
            There currently aren’t any chats set up in this club, please wait for the admin to
            create a chat!
          </p>
        </div>
      );
    }
    sort(list);
    return <ListView items={list} itemSize={56 + 8 + 8} row={ChatListItem} />;
  }

  if (eventId) {
    const list = chats.filter((chat: FIXME) => chat.eventId === eventId);
    if (list.length === 0) {
      return (
        <div className={classes.empty}>
          <p>
            There currently aren’t any chats set up for this event, please wait for the admin to
            create a chat!
          </p>
        </div>
      );
    }
    sort(list);
    return <ListView items={list} itemSize={56 + 8 + 8} row={ChatListItem} />;
  }

  const list = chats
    .map((chat: FIXME) => chat)
    .filter((chat: FIXME) => chat?.messages?.length > 0)
    .filter((chat: FIXME) => {
      if (selectedTab === 0 && !chat.clubId && !chat.eventId) return true;
      else if (selectedTab === 1 && chat.clubId) return true;
      else if (selectedTab === 2 && chat.eventId) return true;
      return false;
    });

  sort(list);

  return (
    <>
      {childLoader && (
        <div style={{ height: "100dvh" }}>
          <Loading />
        </div>
      )}

      <div className={classes.tabs}>
        <Tabs selectedIndex={selectedTab} tabs={tabs} />
      </div>
      {selectedTab === 0 && list.length === 0 && (
        <div className={classes.empty}>
          <p>No user chats</p>
        </div>
      )}
      {selectedTab === 1 && list.length === 0 && (
        <div className={classes.empty}>
          <p>No club chats</p>
        </div>
      )}
      {selectedTab === 2 && list.length === 0 && (
        <div className={classes.empty}>
          <p>No event chats</p>
        </div>
      )}

      <ListView items={list} itemSize={56 + 8 + 8} row={ChatListItem} />
      {!alldataLoad && (
        <div className={classes.loadmorecontainer}>
          <button
            className={classes.button}
            onClick={() => {
              updateOffset();
            }}
          >
            <span className={classes.label}>Load More</span>
          </button>
        </div>
      )}
    </>
  );
}
