import { useContext, useEffect, useState } from "react";

import ChatHeader from "../../components/Chats/ChatHeader";
import ChatThread from "../../components/Chats/ChatThread";
import NewMessageForm from "../../components/Chats/NewMessageForm";
import { Chat } from "../../graphql/__generated__/graphql.ts";
import { ChatsContext } from "../../providers/ChatsProvider";
import { FIXME } from "../../shared/types";

import classes from "./ChatPage.module.css";
type ChatPageProps = {
  chatId: string;
};

export default function ChatPage({ chatId }: ChatPageProps) {
  const { chats, markAsRead, getChatByID, loadMoreChatMessages } = useContext(ChatsContext);

  const [chatLoad, setChatLoad] = useState(false);
  const [replyingTo, setReplyingTo] = useState();
  const [chat, setChat] = useState<Chat>();

  useEffect(() => {
    if (!chatLoad) {
      getChatByID({ chatId });
      setChatLoad(true);
    }

    const chatFound = chats.find((chat: FIXME) => chat.chatId === chatId);
    console.log({ chat });
    setChat(chatFound);
  }, [chats]);

  useEffect(() => {
    if (chat && chat.unread > 0) {
      markAsRead(chatId);
    }
  }, [chat, chatId, markAsRead]);

  if (!chat) return null;

  return (
    <div className={classes.page} id="chat-page">
      <div className={classes.header}>
        <ChatHeader chat={chat} />
      </div>
      <div className={classes.messages}>
        <ChatThread
          chat={chat}
          messages={chat?.messages || []}
          setReplyingTo={setReplyingTo}
          loadPreviousMessages={loadMoreChatMessages}
        />
      </div>
      <div className={classes.form}>
        <NewMessageForm chat={chat} replyingTo={replyingTo} setReplyingTo={setReplyingTo} />
      </div>
    </div>
  );
}
