import { useContext, useEffect, useRef, useState } from "react";
import { useMutation } from "@apollo/client";
import { Delete as DeleteIcon, Reply as ReplyIcon, ContentCopy as CopyIcon } from "@mui/icons-material";
import { Popper } from "@mui/material";
import classNames from "classnames";
import { useLongPress } from "use-long-press";

import { Reaction, ReactionType } from "../../../graphql/__generated__/graphql";
import { CREATE_REACTION, DELETE_REACTION } from "../../../graphql/chat.graphql";
import useLinkifiedText from "../../../hooks/useLinkifiedText";
import { AuthContext } from "../../../providers/AuthProvider";
import { ChatsContext } from "../../../providers/ChatsProvider";
import { getTime, linkify } from "../../../shared/chat-helpers";
import { getImageUrl } from "../../../shared/image-helpers";
import { FIXME } from "../../../shared/types";
import Avatar from "../../Shared/Avatar";
import Link from "../../Shared/Link";

import classes from "./ChatMessage.module.css";
import { NativeAppActions, callNativeApp } from "../../../shared/native-app-helpers";

type ChatMessageProps = {
  chat: FIXME;
  message: FIXME;
  setReplyingTo: FIXME;
};

export default function ChatMessage({ chat, message, setReplyingTo }: ChatMessageProps) {
  const { addReaction, deleteMessage, deleteReaction } = useContext(ChatsContext);
  const { userId } = useContext(AuthContext);

  const bodyRef = useLinkifiedText();
  const messageRef = useRef<FIXME>();

  const [menuShowing, setMenuShowing] = useState(false);
  const [imageShowing, setImageShowing] = useState<string | null>();

  const [createReactionMutation] = useMutation(CREATE_REACTION);
  const [deleteReactionMutation] = useMutation(DELETE_REACTION);

  const [reactions, setReactions] = useState<FIXME>({
    total: 0,
    [ReactionType.Angry]: {
      count: [],
      you: null,
    },
    [ReactionType.Laugh]: {
      count: [],
      you: null,
    },
    [ReactionType.Like]: {
      count: [],
      you: null,
    },
    [ReactionType.Love]: {
      count: [],
      you: null,
    },
    [ReactionType.Sad]: {
      count: [],
      you: null,
    },
    [ReactionType.Wow]: {
      count: [],
      you: null,
    },
  });

  const admins = chat?.club?.admins || chat?.events?.admins;
  const isAdmin = admins?.map((admin: FIXME) => admin.userId).includes(userId);

  useEffect(() => {
    const reactions = {
      total: message.reactions.length,
      [ReactionType.Angry]: {
        count: message.reactions.filter(
          (reaction: Reaction) => reaction.type === ReactionType.Angry
        ),
        you: message.reactions.find(
          (reaction: Reaction) =>
            reaction.type === ReactionType.Angry && reaction.authorId === userId
        ),
      },
      [ReactionType.Laugh]: {
        count: message.reactions.filter(
          (reaction: Reaction) => reaction.type === ReactionType.Laugh
        ),
        you: message.reactions.find(
          (reaction: Reaction) =>
            reaction.type === ReactionType.Laugh && reaction.authorId === userId
        ),
      },
      [ReactionType.Like]: {
        count: message.reactions.filter(
          (reaction: Reaction) => reaction.type === ReactionType.Like
        ),
        you: message.reactions.find(
          (reaction: Reaction) =>
            reaction.type === ReactionType.Like && reaction.authorId === userId
        ),
      },
      [ReactionType.Love]: {
        count: message.reactions.filter(
          (reaction: Reaction) => reaction.type === ReactionType.Love
        ),
        you: message.reactions.find(
          (reaction: Reaction) =>
            reaction.type === ReactionType.Love && reaction.authorId === userId
        ),
      },
      [ReactionType.Sad]: {
        count: message.reactions.filter((reaction: Reaction) => reaction.type === ReactionType.Sad),
        you: message.reactions.find(
          (reaction: Reaction) => reaction.type === ReactionType.Sad && reaction.authorId === userId
        ),
      },
      [ReactionType.Wow]: {
        count: message.reactions.filter((reaction: Reaction) => reaction.type === ReactionType.Wow),
        you: message.reactions.find(
          (reaction: Reaction) => reaction.type === ReactionType.Wow && reaction.authorId === userId
        ),
      },
    };
    setReactions(reactions);
  }, [chat, message.reactions, userId]);

  const longPress = useLongPress(() => {
    setMenuShowing(true);
    if (messageRef?.current) {
      messageRef.current.style.position = "static";
      messageRef.current.style.zIndex = "1001";
    }
  });

  const handleOnDelete = () => {
    const confirmed = window.confirm("Are you sure you want to delete this message?");
    if (confirmed) {
      deleteMessage(chat.chatId, message.messageId);
      handleOnHideMenu();
    }
  };

  const handleOnHideImage = () => {
    setImageShowing(null);
  };

  const handleOnHideMenu = () => {
    setMenuShowing(false);
    if (messageRef?.current) {
      messageRef.current.style.position = null;
      messageRef.current.style.zIndex = null;
    }
  };

  const handleOnReact = async (reaction: ReactionType, reactionObj: Reaction) => {
    setMenuShowing(false);
    const youDidIt = reactions[reaction].you;
    if (youDidIt) {
      deleteReactionMutation({
        variables: {
          messageId: message.messageId,
          reaction: reaction,
        },
      });
      deleteReaction(chat.chatId, message.messageId, reactionObj.reactionId);
    } else {
      createReactionMutation({
        variables: {
          messageId: message.messageId,
          reaction: reaction,
        },
      });
      addReaction(chat.chatId, message.messageId, reaction);
    }
  };

  const handleOnReply = () => {
    setReplyingTo(message);
    handleOnHideMenu();
  };

  const handleOnCopy = async () => {
    try{
      await navigator.clipboard.writeText(message.text);
    } catch (err) {
      await callNativeApp({action: NativeAppActions.COPY_TO_CLIPBOARD, props: {
        text: message.text
      }})
    }
    console.log('copied to clipboard')
    handleOnHideMenu()
  }

  return (
    <>
      {imageShowing && (
        <div className={classes.overlay} onClick={handleOnHideImage}>
          <img className={classes.bigImage} src={imageShowing} />
        </div>
      )}
      {menuShowing && <div className={classes.overlay} onClick={handleOnHideMenu}></div>}
      <div
        className={classNames(classes.message, message.author.userId === userId && classes.yours)}
        ref={messageRef}
      >
        {message.deletedAt ? (
          <div className={classes.body}>
            <div className={classNames(classes.text, classes.blocked)}>
              This message was deleted
            </div>
            <div className={classes.timestamp}>{getTime(message)}</div>
          </div>
        ) : (
          <>
            {message.author.isBlocked ? (
              <div className={classes.body}>
                <div className={classNames(classes.text, classes.blocked)}>
                  This message is from a user you have blocked
                </div>
                <div className={classes.timestamp}>{getTime(message)}</div>
              </div>
            ) : (
              <>
                {(chat.clubId || chat.eventId) && (
                  <Link className={classes.avatar} to={`/profile/${message.author.userId}`}>
                    <Avatar user={message.author} />
                  </Link>
                )}
                <div className={classes.body} {...longPress()}>
                  {(chat.clubId || chat.eventId) && (
                    <h2 className={classes.name}>
                      {message.authorId === userId ? "You" : message.author.name}
                    </h2>
                  )}
                  {message.replyingTo && (
                    <div className={classes.replyingTo}>
                      {message.replyingTo.deletedAt ? (
                        <div className={classes.replyingToText}>(message deleted)</div>
                      ) : (
                        <>
                          <div className={classes.replyingToName}>
                            {message.replyingTo?.author?.name}
                          </div>
                          {message.replyingTo.text && (
                            <div className={classes.replyingToText}>{message.replyingTo.text}</div>
                          )}
                          {message.replyingTo.image && (
                            <img
                              className={classes.replyingToImage}
                              src={getImageUrl({
                                url: message.replyingTo.image,
                                height: 30,
                                width: 50,
                              })}
                            />
                          )}
                        </>
                      )}
                    </div>
                  )}
                  {message.image && (
                    <div className={classes.image}>
                      <img onClick={() => setImageShowing(message.image)} src={message.image} />
                    </div>
                  )}
                  {message.text && (
                    <div
                      className={classes.text}
                      dangerouslySetInnerHTML={{ __html: linkify(message.text) }}
                      ref={bodyRef}
                    />
                  )}
                  <div className={classes.timestamp}>{getTime(message)}</div>
                </div>

                {reactions.total > 0 && (
                  <div className={classes.messageReactions}>
                    <ul>
                      {reactions[ReactionType.Like].count.length > 0 && (
                        <li>
                          <div
                            className={classNames(
                              reactions[ReactionType.Like].count.length > 1 && classes.lots
                            )}
                          >
                            👍{" "}
                            {reactions[ReactionType.Like].count.length > 1 && (
                              <span>{reactions[ReactionType.Like].count.length}</span>
                            )}
                          </div>
                        </li>
                      )}
                      {reactions[ReactionType.Love].count.length > 0 && (
                        <li>
                          <div
                            className={classNames(
                              reactions[ReactionType.Love].count.length > 1 && classes.lots
                            )}
                          >
                            ❤️{" "}
                            {reactions[ReactionType.Love].count.length > 1 && (
                              <span>{reactions[ReactionType.Love].count.length}</span>
                            )}
                          </div>
                        </li>
                      )}
                      {reactions[ReactionType.Sad].count.length > 0 && (
                        <li>
                          <div
                            className={classNames(
                              reactions[ReactionType.Sad].count.length > 1 && classes.lots
                            )}
                          >
                            😢{" "}
                            {reactions[ReactionType.Sad].count.length > 1 && (
                              <span>{reactions[ReactionType.Sad].count.length}</span>
                            )}
                          </div>
                        </li>
                      )}
                      {reactions[ReactionType.Wow].count.length > 0 && (
                        <li>
                          <div
                            className={classNames(
                              reactions[ReactionType.Wow].count.length > 1 && classes.lots
                            )}
                          >
                            😳{" "}
                            {reactions[ReactionType.Wow].count.length > 1 && (
                              <span>{reactions[ReactionType.Wow].count.length}</span>
                            )}
                          </div>
                        </li>
                      )}
                      {reactions[ReactionType.Laugh].count.length > 0 && (
                        <li>
                          <div
                            className={classNames(
                              reactions[ReactionType.Laugh].count.length > 1 && classes.lots
                            )}
                          >
                            🤣{" "}
                            {reactions[ReactionType.Laugh].count.length > 1 && (
                              <span>{reactions[ReactionType.Laugh].count.length}</span>
                            )}
                          </div>
                        </li>
                      )}
                      {reactions[ReactionType.Angry].count.length > 0 && (
                        <li>
                          <div
                            className={classNames(
                              reactions[ReactionType.Angry].count.length > 1 && classes.lots
                            )}
                          >
                            😡{" "}
                            {reactions[ReactionType.Angry].count.length > 1 && (
                              <span>{reactions[ReactionType.Angry].count.length}</span>
                            )}
                          </div>
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </>
            )}
          </>
        )}
        <Popper
          anchorEl={messageRef.current}
          className={classes.menu}
          open={menuShowing}
          modifiers={[{ name: "flip", enabled: false }]}
          placement="bottom-start"
        >
          {message.author.userId !== userId && (
            <div className={classNames(classes.contents, classes.reactions)}>
              <button
                className={classNames(
                  classes.reaction,
                  reactions[ReactionType.Like].you && classes.selected
                )}
                onClick={() => handleOnReact(ReactionType.Like, reactions[ReactionType.Like].you)}
                type="button"
              >
                👍
              </button>
              <button
                className={classNames(
                  classes.reaction,
                  reactions[ReactionType.Love].you && classes.selected
                )}
                onClick={() => handleOnReact(ReactionType.Love, reactions[ReactionType.Love].you)}
                type="button"
              >
                ❤️
              </button>
              <button
                className={classNames(
                  classes.reaction,
                  reactions[ReactionType.Sad].you && classes.selected
                )}
                onClick={() => handleOnReact(ReactionType.Sad, reactions[ReactionType.Sad].you)}
                type="button"
              >
                😢
              </button>
              <button
                className={classNames(
                  classes.reaction,
                  reactions[ReactionType.Wow].you && classes.selected
                )}
                onClick={() => handleOnReact(ReactionType.Wow, reactions[ReactionType.Wow].you)}
                type="button"
              >
                😳
              </button>
              <button
                className={classNames(
                  classes.reaction,
                  reactions[ReactionType.Laugh].you && classes.selected
                )}
                onClick={() => handleOnReact(ReactionType.Laugh, reactions[ReactionType.Laugh].you)}
                type="button"
              >
                🤣
              </button>
              <button
                className={classNames(
                  classes.reaction,
                  reactions[ReactionType.Angry].you && classes.selected
                )}
                onClick={() => handleOnReact(ReactionType.Angry, reactions[ReactionType.Angry].you)}
                type="button"
              >
                😡
              </button>
            </div>
          )}
          <div className={classes.contents}>
            <ul>
              <li>
                <button
                  className={classes.action}
                  data-testid="reply-action"
                  onClick={handleOnReply}
                  type="button"
                >
                  <span>Reply</span>
                  <ReplyIcon />
                </button>
              </li>
              {false && <li>
                <button
                  className={classes.action}
                  data-testid="copy-action"
                  onClick={handleOnCopy}
                  type="button"
                >
                  <span>Copy text</span>
                  <CopyIcon />
                </button>
              </li>}
              {(isAdmin || message.author.userId === userId) && (
                <li>
                  <button
                    className={classes.action}
                    data-testid="delete-action"
                    onClick={handleOnDelete}
                    type="button"
                  >
                    <span>Delete</span>
                    <DeleteIcon />
                  </button>
                </li>
              )}
            </ul>
          </div>
        </Popper>
      </div>
    </>
  );
}
