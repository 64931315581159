import { Tag } from "../graphql/__generated__/graphql";

import { FIXME } from "./types";

export function getTagCategories(tags: FIXME[]): {
  careers: FIXME[];
  courses: FIXME[];
  faiths: FIXME[];
  genders: FIXME[];
  goals: FIXME[];
  industries: FIXME[];
  interests: FIXME[];
  nationalities: FIXME[];
  pronouns: FIXME[];
  relationshipStatuses: FIXME[];
  sexualOrientations: FIXME[];
  starSigns: FIXME[];
  traits: FIXME[];
  universities: FIXME[];
} {
  const careers = tags.filter((tag: FIXME) => tag.parent?.name === "Career");
  const courses = tags.filter((tag: FIXME) => tag.parent?.name === "Courses");
  const faiths = tags.filter((tag: FIXME) => tag.parent?.name === "Faith");
  const genders = tags.filter((tag: FIXME) => tag.parent?.name === "Gender");
  const goals = tags.filter(
    (tag: FIXME) => tag.parent?.name === "Goals" && tag.grandparent?.name === "Goals"
  );
  const industries = tags.filter((tag: FIXME) => tag.parent?.name === "Industry");
  const interests = tags.filter(
    (tag: FIXME) => tag.parent?.name === "Interests" && tag.grandparent?.name === "Interests"
  );
  const nationalities = tags.filter((tag: FIXME) => tag.parent?.name === "Nationality");
  const pronouns = tags.filter((tag: FIXME) => tag.parent?.name === "Pronoun");
  const relationshipStatuses = tags.filter(
    (tag: FIXME) => tag.parent?.name === "Relationship Status"
  );
  const sexualOrientations = tags.filter((tag: FIXME) => tag.parent?.name === "Sexual Orientation");
  const starSigns = tags.filter((tag: FIXME) => tag.parent?.name === "Star Signs");
  const traits = tags.filter((tag: FIXME) => tag.parent?.name === "Traits");
  const universities = tags.filter((tag: FIXME) => tag.parent?.name === "University");

  return {
    careers,
    courses,
    faiths,
    genders,
    goals,
    industries,
    interests,
    nationalities,
    pronouns,
    relationshipStatuses,
    sexualOrientations,
    starSigns,
    traits,
    universities,
  };
}

export function isTagInList({ selection, tag }: { selection: FIXME[]; tag: Tag }): boolean {
  return selection.some((selected) => selected.tagId === tag.tagId);
}
