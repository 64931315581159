import { useContext } from "react";

import NotificationsList from "../../components/Notifications/List";
import { NotificationsContext } from "../../providers/NotificationsProvider";

import classes from "./NotificationsPage.module.css";

export default function NotificationsPage() {
  const { notifications } = useContext(NotificationsContext);

  return (
    <div className={classes.page}>
      {notifications.length === 0 ? (
        <p className={classes.empty}>No notifications</p>
      ) : (
        <NotificationsList notifications={notifications} />
      )}
    </div>
  );
}
