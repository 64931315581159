import { ReactElement } from "react";
import classNames from "classnames";

import { FIXME } from "../../../shared/types";

import classes from "./IconButton.module.css";

interface IconButtonProps {
  className?: string;
  icon: ReactElement;
  label?: string;
  onClick?: FIXME;
  size?: "small" | "normal";
}

export default function IconButton({
  className,
  icon,
  label,
  onClick,
  size = "normal",
  ...props
}: IconButtonProps) {
  return (
    <button
      className={classNames(
        classes.button,
        size === "small" && classes.small,
        size === "normal" && classes.normal,
        className
      )}
      onClick={onClick}
      type="button"
      {...props}
    >
      {label && <span className="sr-only">{label}</span>}
      {icon}
    </button>
  );
}
