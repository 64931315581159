import Input from "../../Forms/Input";

import classes from "../Onboarding.module.css";

type NameProps = {
  error: string;
  name: string;
  onChange: (value: string) => void;
};

export default function Name({ error = "", name, onChange }: NameProps) {
  return (
    <div className={classes.container}>
      <div className={classes.intro}>Sign Up</div>
      <div className={classes.title}>Enter your full name</div>
      {error && <div className={classes.error}>{error}</div>}
      <Input
        autoCapitalize="off"
        autoComplete="name"
        autoCorrect="off"
        label="Full name"
        onChange={onChange}
        placeholder="Full name"
        required
        value={name}
      />
    </div>
  );
}
