import { graphql } from "./__generated__/gql";

export const CREATE_CLUB = graphql(`
  mutation CreateClub($createClubInput: CreateClubInput!) {
    createClub(createClubInput: $createClubInput) {
      clubId
      name
      description
      locationId
      location {
        locationId
        name
      }
      image
      isAuthorHidden
      isPrivate
      isVisible
      author {
        userId
        name
        avatar
      }
      admins {
        userId
        name
        username
        avatar
      }
      members {
        userId
        avatar
        username
        name
      }
      events {
        eventId
        name
        image
        place
        price
        startTime
        attendees {
          userId
          avatar
        }
      }
      tags {
        tagId
        name
      }
    }
  }
`);

export const DELETE_CLUB = graphql(`
  mutation DeleteClub($clubId: ID!) {
    deleteClub(clubId: $clubId) {
      clubId
    }
  }
`);

export const GET_ALL_CLUBS = graphql(`
  query GetAllClubs {
    clubs {
      clubId
      name
      description
      image
      isPrivate
      members {
        userId
        avatar
      }
      events {
        eventId
        name
        image
        place
        price
        startTime
        attendees {
          userId
          avatar
        }
      }
    }
  }
`);

export const GET_CLUB = graphql(`
  query GetClub($clubId: ID!) {
    club(clubId: $clubId) {
      clubId
      name
      description
      locationId
      location {
        locationId
        name
      }
      image
      isAuthorHidden
      isPrivate
      isVisible
      author {
        userId
        name
        avatar
      }
      admins {
        userId
        name
        username
        avatar
      }
      membersCount
      memberlimited {
        userId
        avatar
        username
        name
      }
      events {
        eventId
        name
        image
        place
        price
        startTime
        attendees {
          userId
          avatar
        }
      }
      pastEvents {
        eventId
        name
        image
        place
        price
        startTime
        attendees {
          userId
          avatar
        }
      }
      tags {
        tagId
        name
      }
      createdAt
    }
  }
`);

export const GET_CLUB_MEMBERS = graphql(`
  query GetClubMembers($clubId: ID!) {
    club(clubId: $clubId) {
      clubId
      name
      admins {
        userId
        name
        username
        avatar
      }
      membersCount
      members {
        userId
        avatar
        username
        name
      }
    }
  }
`);

export const REMOVE_CLUB_MEMBER = graphql(`
  mutation RemoveClubMember($clubId: ID!, $userId: ID!) {
    removeClubMember(clubId: $clubId, userId: $userId)
  }
`);

export const UPDATE_CLUB = graphql(`
  mutation UpdateClub($updateClubInput: UpdateClubInput!) {
    updateClub(updateClubInput: $updateClubInput) {
      clubId
      name
      description
      locationId
      location {
        locationId
        name
      }
      image
      isAuthorHidden
      isPrivate
      isVisible
      author {
        userId
        name
        avatar
      }
      admins {
        userId
        name
        username
        avatar
      }
      members {
        userId
        avatar
        username
        name
      }
      events {
        eventId
        name
        image
        place
        price
        startTime
        attendees {
          userId
          avatar
        }
      }
      tags {
        tagId
        name
      }
    }
  }
`);
