import { FIXME } from "../../../shared/types";
import Avatar from "../../Shared/Avatar";
import Link from "../../Shared/Link";

import classes from "./ListViewRow.module.css";

interface ListViewRowProps {
  data: FIXME;
  index: number;
  style: FIXME;
}

export default function ListViewRow({ index, style, data }: ListViewRowProps) {
  const user = data[index];

  return (
    <Link
      to={`/profile/${user.userId}`}
      className={classes.row}
      onClick={user.onSelect}
      style={style}
    >
      <div className={classes.avatar}>
        <Avatar size={46} user={user} />
      </div>
      <div className={classes.name}>{user.name}</div>
      <div className={classes.username}>{user.username}</div>
    </Link>
  );
}
