import { ErrorBoundary } from "./components/Shared/ErrorMessage";
import OldOS from "./components/Shared/OldOS";
import ChatInfoPage from "./pages/ChatInfoPage";
import ChatPage from "./pages/ChatPage";
import ChatsPage from "./pages/ChatsPage";
import ClubMembersPage from "./pages/ClubMembersPage";
import ClubPage from "./pages/ClubPage";
import ClubRequestsPage from "./pages/ClubRequestsPage";
import EditClubPage from "./pages/EditClubPage";
import EditEventPage from "./pages/EditEventPage";
import EditProfilePage from "./pages/EditProfilePage";
import EventAttendeesPage from "./pages/EventAttendeesPage";
import EventPage from "./pages/EventPage";
import FollowersFollowingPage from "./pages/FollowersFollowingPage";
import NotificationsPage from "./pages/NotificationsPage";
import ProfilePage from "./pages/ProfilePage";
import SettingsPage from "./pages/SettingsPage";
import { AppProvider, AppScreen, Screens } from "./providers/AppProvider";
import { AuthProvider } from "./providers/AuthProvider";
import { ChatsProvider } from "./providers/ChatsProvider";
import { GraphQLProvider } from "./providers/GraphQLProvider";
import { LoadingProvider } from "./providers/LoadingProvider";
import { NotificationsProvider } from "./providers/NotificationsProvider";
// import { OnboardingProvider } from "./providers/OnboardingProvider";
import { PusherProvider } from "./providers/PusherProvider";
function App() {
  // Check for updates

  return (
    <ErrorBoundary>
      <div id="old-browser-warning">
        <OldOS />
      </div>
      <LoadingProvider>
        <AuthProvider>
          <GraphQLProvider>
            {/* <OnboardingProvider> */}
            <PusherProvider>
              <ChatsProvider>
                <NotificationsProvider>
                  <AppProvider>
                    <AppScreen element={ChatsPage} hideMenus name={Screens.Chats} path="/chats" />
                    <AppScreen
                      element={ChatPage}
                      hideMenus
                      name={Screens.Chat}
                      path="/chats/:chatId"
                    />
                    <AppScreen
                      element={ChatInfoPage}
                      hideMenus
                      name={Screens.ChatInfo}
                      path="/chats/:chatId/info"
                    />
                    <AppScreen element={ClubPage} name={Screens.Club} path="/clubs/:clubId" />
                    <AppScreen
                      element={ChatsPage}
                      hideMenus
                      name={Screens.ClubChats}
                      path="/clubs/:clubId/chats"
                    />
                    <AppScreen
                      element={ClubMembersPage}
                      name={Screens.ClubMembers}
                      path="/clubs/:clubId/members"
                    />
                    <AppScreen
                      element={ClubRequestsPage}
                      name={Screens.ClubJoinRequests}
                      path="/clubs/:clubId/requests"
                    />
                    <AppScreen
                      element={EditClubPage}
                      hideMenus
                      name={Screens.CreateClub}
                      path="/create/club"
                    />
                    <AppScreen
                      element={EditEventPage}
                      hideMenus
                      name={Screens.CreateEvent}
                      path="/create/event"
                    />
                    <AppScreen
                      element={EditClubPage}
                      hideMenus
                      name={Screens.EditClub}
                      path="/edit/club/:clubId"
                    />
                    <AppScreen
                      element={EditEventPage}
                      hideMenus
                      name={Screens.EditEvent}
                      path="/edit/event/:eventId"
                    />
                    <AppScreen
                      element={EditProfilePage}
                      hideMenus
                      name={Screens.EditProfile}
                      path="/edit/profile"
                    />
                    <AppScreen element={EventPage} name={Screens.Event} path="/events/:eventId" />
                    <AppScreen
                      element={EventAttendeesPage}
                      name={Screens.EventAttendees}
                      path="/events/:eventId/attendees"
                    />
                    <AppScreen
                      element={ChatsPage}
                      hideMenus
                      name={Screens.EventChats}
                      path="/events/:eventId/chats"
                    />
                    <AppScreen
                      element={NotificationsPage}
                      name={Screens.Notifications}
                      path="/notifications"
                    />
                    <AppScreen element={ProfilePage} name={Screens.Profile} path="/profile" />
                    <AppScreen
                      element={ProfilePage}
                      name={Screens.Profile}
                      path="/profile/:profileId"
                    />
                    <AppScreen
                      defaultTab={1}
                      element={ProfilePage}
                      name={Screens.Profile}
                      path="/profile/:profileId/bio"
                    />
                    <AppScreen
                      defaultTab={2}
                      element={ProfilePage}
                      name={Screens.Profile}
                      path="/profile/:profileId/clubs"
                    />
                    <AppScreen
                      defaultTab={0}
                      element={FollowersFollowingPage}
                      name={Screens.Profile}
                      path="/profile/:profileId/followers"
                    />
                    <AppScreen
                      defaultTab={1}
                      element={FollowersFollowingPage}
                      name={Screens.Profile}
                      path="/profile/:profileId/following"
                    />
                    <AppScreen element={SettingsPage} path="/settings" name={Screens.Settings} />
                  </AppProvider>
                </NotificationsProvider>
              </ChatsProvider>
            </PusherProvider>
            {/* </OnboardingProvider> */}
          </GraphQLProvider>
        </AuthProvider>
      </LoadingProvider>
    </ErrorBoundary>
  );
}

export default App;
