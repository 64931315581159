import { useState } from "react";
import {
  AdminPanelSettings as AdminPanelSettingsIcon,
  ChevronRight as ChevronRightIcon,
} from "@mui/icons-material";

import { Club, User } from "../../../graphql/__generated__/graphql";
import { getImageUrl } from "../../../shared/image-helpers";
import { FIXME } from "../../../shared/types";
import FormModal from "../FormModal";
import { Button } from "..";

import classes from "./RelatedClubs.module.css";

interface RelatedClubsProps {
  clubs: Club[];
  onChange?: FIXME;
  user: User;
}

export default function RelatedClubs({
  clubs = [],
  onChange = () => null,
  user,
}: RelatedClubsProps) {
  const [showingModal, setShowingModal] = useState(false);

  const userClubs = user?.clubs?.admin || [];
  const availableClubs = userClubs.filter((userClub: FIXME) => {
    return !clubs.find((club) => club.clubId === userClub.clubId);
  });

  const handleOnAdd = (club: FIXME) => {
    const updated = [...clubs, club];
    onChange(updated);
  };

  const handleOnRemove = (club: FIXME) => {
    const updated = clubs.filter((c) => c.clubId !== club.clubId);
    onChange(updated);
  };

  if (showingModal) {
    return (
      <FormModal onClose={() => setShowingModal(false)} title="Related Clubs">
        <div className={classes.list}>
          {availableClubs.length === 0 ? (
            <p className={classes.empty}>You do not own any clubs</p>
          ) : (
            <ul>
              {availableClubs.map((club: FIXME) => (
                <li className={classes.club} key={club.clubId}>
                  <img
                    className={classes.image}
                    src={getImageUrl({ url: club.image || "", height: 46, width: 46 })}
                  />
                  <div className={classes.text}>
                    <h3 className={classes.name}>{club.name}</h3>
                    <p className={classes.members}>
                      {club.members.length} member{club.members.length === 1 ? "" : "s"}
                    </p>
                  </div>
                  <Button className={classes.action} onClick={() => handleOnAdd(club)} size="small">
                    Link
                  </Button>
                </li>
              ))}
            </ul>
          )}
        </div>
      </FormModal>
    );
  }

  return (
    <div className={classes.wrapper}>
      <button className={classes.header} onClick={() => setShowingModal(true)} type="button">
        <AdminPanelSettingsIcon className={classes.icon} />
        <span className={classes.text}>Related Clubs</span>
        <ChevronRightIcon className={classes.arrow} />
      </button>
      {clubs.length > 0 && (
        <div className={classes.relatedClubs}>
          <ul>
            {clubs.map((club: FIXME) => (
              <li className={classes.club} key={club.clubId}>
                <img
                  className={classes.image}
                  src={getImageUrl({ url: club.image || "", height: 46, width: 46 })}
                />
                <div className={classes.text}>
                  <h3 className={classes.name}>{club.name}</h3>
                  <p className={classes.members}>
                    {club.members.length} member{club.members.length === 1 ? "" : "s"}
                  </p>
                </div>
                <Button onClick={() => handleOnRemove(club)} size="small">
                  Remove
                </Button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
