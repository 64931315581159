import { createContext, useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";

import { GET_NOTIFICATIONS, READ_NOTIFICATION } from "../graphql/notifications.graphql";
import { PusherEvents } from "../shared/pusher-events";
import { FIXME } from "../shared/types";

import { AuthContext } from "./AuthProvider";
import { PusherContext } from "./PusherProvider";

type NotificationsContextType = {
  markNotificationRead: FIXME;
  notifications: FIXME;
  notificationsCount: number;
  refetchNotifications: FIXME;
};

export const NotificationsContext = createContext<NotificationsContextType>({
  markNotificationRead: () => null,
  notifications: [],
  notificationsCount: 0,
  refetchNotifications: () => null,
});

export const NotificationsProvider = ({ children }: FIXME) => {
  const { pusher } = useContext(PusherContext);
  const { userId } = useContext(AuthContext);

  const [data, setData] = useState<FIXME>();

  const { data: notificationsData, refetch: refetchNotifications } = useQuery(GET_NOTIFICATIONS, {
    fetchPolicy: "cache-and-network",
  });
  const [markNotificationReadMutation] = useMutation(READ_NOTIFICATION, {
    onCompleted: () => refetchNotifications(),
  });

  useEffect(() => {
    if (typeof notificationsData !== "undefined" && notificationsData?.notifications) {
      setData(notificationsData?.notifications);
    } else {
      setData([])
    }
  }, [notificationsData]);

  useEffect(() => {
    const channel = pusher?.subscribe(`notifications-${userId}`);

    channel?.bind(PusherEvents.NOTIFICATION_UPDATE, (notification: FIXME) => {
      setData((current: FIXME) => [notification, ...current]);
    });

    return () => {
      channel?.unbind_all();
      channel?.unsubscribe();
    };
  }, []);

  const markNotificationRead = async (notificationId: string) => {
    await markNotificationReadMutation({
      variables: { notificationId },
    });
    const updated = data.filter(
      (notification: FIXME) => notification.notificationId !== notificationId
    );
    setData(updated);
  };

  const value: NotificationsContextType = {
    markNotificationRead,
    notifications: data,
    notificationsCount: data?.length || 0,
    refetchNotifications,
  };

  return <NotificationsContext.Provider value={value}>{children}</NotificationsContext.Provider>;
};
