import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";

import { FOLLOW, GET_IS_FOLLOWING, UNFOLLOW } from "../../../graphql/following.graphql";
import { AppContext } from "../../../providers/AppProvider";
import { FIXME } from "../../../shared/types";
import { Button } from "../../Forms";

interface FollowButtonProps {
  user: FIXME;
}

export default function FollowButton({ user }: FollowButtonProps) {
  const { refetch } = useContext(AppContext);

  const [disabled, setDisabled] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);

  const [follow] = useMutation(FOLLOW);
  const [unfollow] = useMutation(UNFOLLOW);

  const handleOnFollow = async () => {
    setDisabled(true);
    setIsFollowing(true);
    await follow({
      onCompleted: () => {
        setDisabled(false);
        refetch();
      },
      variables: { userId: user.userId },
    });
  };

  const handleOnUnfollow = async () => {
    setDisabled(true);
    setIsFollowing(false);
    await unfollow({
      onCompleted: () => {
        setDisabled(false);
        refetch();
      },
      variables: { userId: user.userId },
    });
  };

  const { data } = useQuery(GET_IS_FOLLOWING, {
    fetchPolicy: "network-only",
    variables: { userId: user.userId },
  });

  useEffect(() => {
    setIsFollowing(!!data?.isFollowing);
  }, [data]);

  return isFollowing ? (
    <Button color="secondary" disabled={disabled} fullWidth onClick={handleOnUnfollow} size="small">
      Following
    </Button>
  ) : (
    <Button disabled={disabled} fullWidth onClick={handleOnFollow} size="small">
      Follow
    </Button>
  );
}
