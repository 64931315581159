import { NotificationType } from "../../../shared/notification-helpers";
import { FIXME } from "../../../shared/types";
import ListView from "../../Shared/ListView";
import ClubAcceptedNotification from "../ClubAcceptedNotification";
import ClubAdminAddedNotification from "../ClubAdminAddedNotification";
import ClubAdminRemovedNotification from "../ClubAdminRemovedNotification";
import ClubJoinedRequestNotification from "../ClubJoinedRequestNotification";
import EventAddedNotification from "../EventAddedNotification";
import FollowedNotification from "../FollowedNotification";

function NotificationsRow({ index, style, data }: FIXME) {
  const item = data[index];

  return (
    <div style={style}>
      {item.type === NotificationType.CLUB_ACCEPTED && (
        <ClubAcceptedNotification club={item.club} notificationId={item.notificationId} />
      )}
      {item.type === NotificationType.EVENT_ADDED && (
        <EventAddedNotification
          club={item.club}
          event={item.event}
          notificationId={item.notificationId}
        />
      )}
      {item.type === NotificationType.CLUB_JOIN_REQUEST && (
        <ClubJoinedRequestNotification
          club={item.club}
          user={item.user}
          notificationId={item.notificationId}
        />
      )}
      {item.type === NotificationType.EVENT_CHANGED && <p>event changed</p>}
      {item.type === NotificationType.FOLLOWED && (
        <FollowedNotification follower={item.user} notificationId={item.notificationId} />
      )}
      {item.type === NotificationType.CLUB_ADMIN_ADDED && (
        <ClubAdminAddedNotification club={item.club} notificationId={item.notificationId} />
      )}
      {item.type === NotificationType.CLUB_ADMIN_REMOVED && (
        <ClubAdminRemovedNotification club={item.club} notificationId={item.notificationId} />
      )}
    </div>
  );
}

interface ListProps {
  notifications: FIXME;
}

export default function List({ notifications }: ListProps) {
  // Avatar height - 4.6rem - plus the 8px gap either side
  return <ListView items={notifications} itemSize={46 + 8 + 8} row={NotificationsRow} />;
}
