import { graphql } from "./__generated__/gql";

export const GET_COMPLETENESS = graphql(`
  query GetCompleteness {
    completeness {
      percentage
      suggestion
    }
  }
`);

export const GET_ONBOARDED = graphql(`
  query GetOnboarded {
    onboarded
  }
`);

export const GET_ALL_USERS = graphql(`
  query GetAllUsers {
    users {
      count
      list {
        userId
        name
        username
        headline
        bio
        avatar
        location {
          locationId
          name
        }
        images
        links {
          url
          text
        }
        tags {
          tagId
          name
          parent {
            tagId
            name
          }
          grandparent {
            tagId
            name
          }
        }
        clubs {
          created {
            clubId
            name
            description
            image
            isPrivate
            members {
              avatar
            }
          }
          joined {
            clubId
            name
            description
            image
            isPrivate
            members {
              avatar
            }
          }
        }
      }
    }
  }
`);

export const GET_USER_FOLLOWERS_FOLLOWING = graphql(`
  query GetUserFollowersFollowing($userId: ID!) {
    user(userId: $userId) {
      userId
      followers {
        userId
        name
        username
        avatar
      }
      following {
        userId
        name
        username
        avatar
      }
    }
  }
`);

export const GET_USER = graphql(`
  query GetUser($userId: ID!) {
    user(userId: $userId) {
      userId
      name
      username
      headline
      bio
      dob
      gender
      avatar
      isBlocked
      isHidden
      isShowAge
      location {
        locationId
        name
      }
      followers {
        userId
        name
        username
        avatar
      }
      following {
        userId
        name
        username
        avatar
      }
      images
      links {
        url
        text
      }
      tags {
        tagId
        name
        parent {
          tagId
          name
        }
        grandparent {
          tagId
          name
        }
      }
      clubs {
        admin {
          clubId
          name
          description
          image
          isPrivate
          members {
            userId
            avatar
          }
        }
        created {
          clubId
          name
          description
          image
          isPrivate
          author {
            userId
            name
            avatar
          }
          members {
            userId
            avatar
          }
          events {
            eventId
            name
            image
            place
            price
            startTime
            attendees {
              userId
              avatar
            }
          }
        }
        joined {
          clubId
          name
          description
          image
          isPrivate
          author {
            userId
            name
            avatar
          }
          members {
            userId
            avatar
          }
          events {
            eventId
            name
            image
            place
            price
            startTime
            attendees {
              userId
              avatar
            }
          }
        }
      }
    }
  }
`);

export const UPDATE_USER = graphql(`
  mutation UpdateUser($updateUserInput: UpdateUserInput!) {
    updateUser(updateUserInput: $updateUserInput) {
      userId
      name
      username
      headline
      bio
      dob
      gender
      avatar
      isBlocked
      isHidden
      isShowAge
      location {
        locationId
        name
      }
      followers {
        userId
        name
        username
        avatar
      }
      images
      links {
        url
        text
      }
      tags {
        tagId
        name
        parent {
          tagId
          name
        }
        grandparent {
          tagId
          name
        }
      }
      clubs {
        admin {
          clubId
          name
          description
          image
          isPrivate
          members {
            userId
            avatar
          }
        }
        created {
          clubId
          name
          description
          image
          isPrivate
          author {
            userId
            name
            avatar
          }
          members {
            userId
            avatar
          }
          events {
            eventId
            name
            image
            place
            price
            startTime
            attendees {
              userId
              avatar
            }
          }
        }
        joined {
          clubId
          name
          description
          image
          isPrivate
          author {
            userId
            name
            avatar
          }
          members {
            userId
            avatar
          }
          events {
            eventId
            name
            image
            place
            price
            startTime
            attendees {
              userId
              avatar
            }
          }
        }
      }
    }
  }
`);
