import { useContext, useState } from "react";
import { Edit as EditIcon, LocationOn as LocationOnIcon } from "@mui/icons-material";

import { AppContext } from "../../../providers/AppProvider";
import { AuthContext } from "../../../providers/AuthProvider";
import { calculateAge } from "../../../shared/date-helpers";
import { getImageUrl } from "../../../shared/image-helpers";
import { FIXME } from "../../../shared/types";
import { Button } from "../../Forms";
import IconButton from "../../Forms/IconButton";
import Link from "../../Shared/Link";
import FollowButton from "../FollowButton";
import Links from "../Links";
import MoreButton from "../MoreButton";

import classes from "./Header.module.css";
import { callNativeAppAsync, NativeAppActions } from "../../../shared/native-app-helpers";

export default function Header({ user }: FIXME) {
  // const { createUserChat } = useContext(ChatsContext);
  const { navigate } = useContext(AppContext);
  const { userId } = useContext(AuthContext);

  const [loadingChat, setLoadingChat] = useState(false);

  const isYourProfile = user.userId === userId;

  const handleOnCreateChat = async () => {
    setLoadingChat(true);
    callNativeAppAsync({
      action: NativeAppActions.START_CHAT,
      props: { userId: user.userId },
    });
  };

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.profilePic}>
          <>
            {user.avatar && (
              <img
                alt={user.name}
                className={classes.image}
                height={276}
                src={getImageUrl({ url: user.avatar, height: 552, width: 686 })}
                width={343}
              />
            )}
            {isYourProfile && (
              <div className={classes.profilePicEdit}>
                <IconButton
                  data-testid="edit-profile-action"
                  icon={<EditIcon />}
                  onClick={() => navigate("/edit/profile")}
                />
              </div>
            )}
          </>
        </div>
        {!isYourProfile && (
          <>
            {user.isBlocked ? (
              <span className={classes.blockedMessage}>⛔ This user is blocked.</span>
            ) : (
              <Button
                className={classes.sendMessageButton}
                disabled={loadingChat}
                onClick={handleOnCreateChat}
                size="small"
              >
                {loadingChat ? <>Loading...</> : <>Say Hey👋</>}
              </Button>
            )}
          </>
        )}
        <div className={classes.content}>
          <div className={classes.action}>
            {isYourProfile && (
              <IconButton icon={<EditIcon />} onClick={() => navigate("/edit/profile")} />
            )}
            {!isYourProfile && (
              <div className={classes.buttons}>
                <FollowButton user={user} />
                <MoreButton user={user} />
              </div>
            )}
          </div>
          <div className={classes.username}>{user.username}</div>
          <div className={classes.name}>
            {user.name}
            {user.isShowAge && <>, {calculateAge(user.dob)}</>}
          </div>
          <div className={classes.info}>
            <div className={classes.followers}>
              <Link to={`/profile/${user.userId}/followers`}>
                {user.followers.length} follower{user.followers.length !== 1 && "s"}
              </Link>
            </div>
            {user.location && (
              <div className={classes.location}>
                <LocationOnIcon />
                {user.location?.name}
              </div>
            )}
          </div>
          <div className={classes.headline}>{user.headline}</div>
          {user.links.length > 0 && (
            <div className={classes.links}>
              <Links user={user} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
