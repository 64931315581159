import {
  createContext,
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useMemo,
  useState,
} from "react";

import Loading from "../components/Shared/Loading";

type LoadingContextType = {
  setLoading: Dispatch<SetStateAction<boolean>>;
};

type LoadingProviderType = {
  children: ReactNode;
};

export const LoadingContext = createContext<LoadingContextType>({
  setLoading: () => null,
});

export const LoadingProvider = ({ children }: LoadingProviderType): ReactElement => {
  const [loading, setLoading] = useState(false);

  const value: LoadingContextType = useMemo(
    () => ({
      setLoading,
    }),
    []
  );

  return (
    <LoadingContext.Provider value={value}>
      <div style={{ height: "100dvh" }}>
        {loading && (
          <div
            style={{
              backgroundColor: "#fff",
              height: "100dvh",
              inset: 0,
              position: "fixed",
              zIndex: 9999,
            }}
          >
            <Loading />
          </div>
        )}
        {children}
      </div>
    </LoadingContext.Provider>
  );
};
