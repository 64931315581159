import { InsertPhotoOutlined as InsertPhotoOutlinedIcon } from "@mui/icons-material";

import { FIXME } from "../../../shared/types";

interface ChatUploadImageProps {
  className: string;
  onImageUpload: FIXME;
}

export default function ChatUploadImage({ className, onImageUpload }: ChatUploadImageProps) {
  return (
    <label className={className}>
      <input type="file" accept="image/*" onChange={onImageUpload} />
      <InsertPhotoOutlinedIcon />
      <span className="sr-only">Send image</span>
    </label>
  );
}
