import { FIXME } from "./types";

export function parsePath(pattern: string, path: string) {
  // Split pattern and path into segments
  const patternSegments = pattern.split("/");
  const pathSegments = path.split("/");

  // Initialize an empty object to hold the parameters
  const params: FIXME = {};

  // If the number of segments differ, return null (no match)
  if (patternSegments.length !== pathSegments.length) {
    return null;
  }

  // Loop through each segment of the pattern
  for (let i = 0; i < patternSegments.length; i++) {
    const patternSegment = patternSegments[i];
    const pathSegment = pathSegments[i];

    // If pattern segment starts with ':', it's a parameter
    if (patternSegment.startsWith(":")) {
      // Extract parameter name (remove the ':' prefix)
      const paramName = patternSegment.slice(1);

      // Store the value in params object
      params[paramName] = pathSegment;
    } else if (patternSegment !== pathSegment) {
      // If the segment is not a parameter and doesn't match, return null
      return null;
    }
  }

  return params;
}
