import Input from "../../Forms/Input";

import classes from "../Onboarding.module.css";

type StudyYearProps = {
  error: string;
  onChange: (value: string) => void;
  studyYear: string;
};

export default function StudyYear({ error, onChange, studyYear }: StudyYearProps) {
  return (
    <div className={classes.container}>
      <div className={classes.intro}>Sign Up</div>
      <div className={classes.title}>Enter your start year</div>
      {error && <div className={classes.error}>{error}</div>}
      <Input
        inputmode="numeric"
        label="Start Year"
        min={new Date().getFullYear() - 100}
        max={new Date().getFullYear()}
        name="start-year"
        onChange={onChange}
        required
        type="number"
        value={studyYear}
        width="width-33"
      />
    </div>
  );
}
