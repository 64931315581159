import Input from "../../Forms/Input";

import classes from "../Onboarding.module.css";

type UsernameProps = {
  error: string;
  onChange: (value: string) => void;
  username: string;
};

export default function Username({ error, onChange, username }: UsernameProps) {
  return (
    <div className={classes.container}>
      <div className={classes.intro}>Sign Up</div>
      <div className={classes.title}>Create your username</div>
      {error && <div className={classes.error}>{error}</div>}
      <Input
        autoCapitalize="off"
        autoComplete="username"
        autoCorrect="off"
        label="Username"
        onChange={onChange}
        placeholder="Username"
        required
        value={username}
      />
    </div>
  );
}
