import classes from "./Progress.module.css";

export default function Progress({ current, total }: { current: number; total: number }) {
  return (
    <div className={classes.bar}>
      <div className={classes.done} style={{ flex: current }}></div>
      <div className={classes.todo} style={{ flex: total - current }}></div>
    </div>
  );
}
