import { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";

import { Button } from "../../components/Forms";
import Avatar from "../../components/Shared/Avatar";
import BackTo from "../../components/Shared/BackTo";
import Link from "../../components/Shared/Link";
import Loading from "../../components/Shared/Loading";
import { ACCEPTING, GET_JOIN_REQUESTS, REJECTING } from "../../graphql/joining.graphql";
import { FIXME } from "../../shared/types";

import classes from "./ClubRequestsPage.module.css";

type ClubRequestsPageProps = {
  clubId: string;
};

export default function ClubRequestsPage({ clubId }: ClubRequestsPageProps) {
  if (!clubId) throw new Error("You must provide a valid clubId");

  const [disabled, setDisabled] = useState(false);

  const { data, loading, refetch } = useQuery(GET_JOIN_REQUESTS, {
    variables: { clubId },
  });

  const [accepting] = useMutation(ACCEPTING);
  const [rejecting] = useMutation(REJECTING);

  const requests = data?.getRequests || [];

  const handleOnAccept = async (user: FIXME) => {
    setDisabled(true);

    await accepting({
      variables: { clubId, userId: user.userId },
      onCompleted: () => {
        setDisabled(false);
        refetch();
      },
    });
  };

  const handleOnReject = async (user: FIXME) => {
    setDisabled(true);

    await rejecting({
      variables: { clubId, userId: user.userId },
      onCompleted: () => {
        setDisabled(false);
        refetch();
      },
    });
  };

  if (loading) {
    return <Loading />;
  }

  if (requests.length === 0) {
    return (
      <div className={classes.page}>
        <Link reset to={`/clubs/${clubId}`} className={classes.back}>
          <ArrowBackIcon />
          Back to club
        </Link>
        <p className={classes.message}>No new requests</p>
      </div>
    );
  }

  return (
    <div className={classes.page}>
      <BackTo />
      <ul className={classes.list}>
        {requests.map((request: FIXME, index: number) => (
          <li className={classes.request} key={`request-${index}-${request.userId}`}>
            <Link className={classes.content} to={`/profile/${request.userId}`}>
              <Avatar className={classes.avatar} size={46} user={request} />
              <div className={classes.name}>{request.name}</div>
              <div className={classes.username}>{request.username}</div>
            </Link>
            <div className={classes.actions}>
              <Button
                color="secondary"
                disabled={disabled}
                onClick={() => handleOnReject(request)}
                size="small"
              >
                Reject
              </Button>
              <Button
                color="secondary"
                disabled={disabled}
                onClick={() => handleOnAccept(request)}
                size="small"
              >
                Accept
              </Button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
