import { useEffect, useRef, useState } from "react";
import classNames from "classnames";

import { FIXME } from "../../../shared/types";
import Avatar from "../../Shared/Avatar";
import Link from "../../Shared/Link";

import classes from "./Description.module.css";

interface DescriptionProps {
  event: FIXME;
}

export default function Description({ event }: DescriptionProps) {
  const [showingAll, setShowingAll] = useState(false);
  const [hasShowMore, setHasShowMore] = useState(false);
  const containerRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      if (containerRef.current.scrollHeight > containerRef.current.clientHeight) {
        setHasShowMore(true);
      }
    }
  }, []);

  const toggleShowingAll = () => {
    setHasShowMore(false);
    setShowingAll(true);
  };

  return (
    <div className={classes.wrapper}>
      {!event.isAuthorHidden && (
        <>
          <Link className={classes.avatar} to={`/profile/${event.author.userId}`}>
            <Avatar size={46} user={event.author} />
          </Link>
          <h3 className={classes.author}>
            <Link to={`/profile/${event.author.userId}`}>{event.author.name}</Link>
          </h3>
          <p className={classes.subtitle}>Event Organiser</p>
        </>
      )}

      <p
        className={classNames(classes.description, showingAll && classes.showingAll)}
        ref={containerRef}
      >
        {event.description}
        {hasShowMore && (
          <button className={classes.showMoreButton} onClick={toggleShowingAll}>
            Show more
          </button>
        )}
      </p>
    </div>
  );
}
