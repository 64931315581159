import { VisibilityOutlined as VisibilityOutlinedIcon } from "@mui/icons-material";

import { FIXME } from "../../../shared/types";
import Toggle from "../Toggle";

import classes from "./AttendeesHidden.module.css";

interface AttendeesHiddenProps {
  isAttendeesHidden?: boolean;
  onChange?: FIXME;
}

export default function AttendeesHidden({ isAttendeesHidden, onChange }: AttendeesHiddenProps) {
  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <VisibilityOutlinedIcon className={classes.icon} />
        <span className={classes.text}>Hide Attendees</span>
      </div>
      <p className={classes.copy}>Events can show the attendees, or hide them</p>
      <label className={classes.setting}>
        <span className={classes.label}>Hide Attendees</span>
        <Toggle checked={isAttendeesHidden} onChange={onChange} />
      </label>
    </div>
  );
}
