import { Link as LinkIcon } from "@mui/icons-material";

import { callNativeApp, NativeAppActions } from "../../../shared/native-app-helpers";
import { FIXME } from "../../../shared/types";

import classes from "./Links.module.css";

interface LinksProps {
  user: FIXME;
}

export default function Links({ user }: LinksProps) {
  const handleOnClickLink = (url: string) => {
    callNativeApp({
      action: NativeAppActions.OPEN_URL,
      props: { url },
    });
  };

  return (
    <div className={classes.wrapper}>
      <ul className={classes.list}>
        {user.links.map((link: FIXME, index: number) => (
          <li className={classes.item} key={`user_${user.user_id}-${index}`}>
            <button
              className={classes.link}
              onClick={() => handleOnClickLink(link.url)}
              type="button"
            >
              <LinkIcon />
              {link.text}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}
