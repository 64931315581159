import { useEffect, useState } from "react";

import Suggestions, { SuggestionType } from "../shared/suggestions-helpers";
import { FIXME } from "../shared/types";

export const useSuggestions = (
  type?: SuggestionType
): { suggestions: []; addSuggestion: (item: FIXME) => void } => {
  const [suggestions, setSuggestions] = useState<FIXME>([]);

  const addSuggestion = (item: FIXME) => {
    if (type) {
      Suggestions.add(type, item);
      const list = Suggestions.get(type);
      setSuggestions(list || []);
    }
  };

  useEffect(() => {
    if (type) {
      const list = Suggestions.get(type);
      setSuggestions(list || []);
    }
  }, [type]);

  return {
    suggestions,
    addSuggestion,
  };
};
