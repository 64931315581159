import { Fragment, useContext, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { v4 as uuid } from "uuid";

import { Chat, Message } from "../../../graphql/__generated__/graphql.ts";
import { AuthContext } from "../../../providers/AuthProvider";
import { groupedMessagesByDate } from "../../../shared/chat-helpers";
import { PusherEvents } from "../../../shared/pusher-events";
import { FIXME } from "../../../shared/types";
import LoadingDots from "../../Shared/LoadingDots";
import { ScrollToBottomObserver } from "../../Shared/ScrollToBottomObserver";
import ChatMessage from "../ChatMessage";

import "react-swipeable-list/dist/styles.css";
import classes from "./ChatThread.module.css";
import ArrowUp from "../../Shared/ArrowUp";

interface ChatThreadProps {
  chat: Chat;
  messages: Message[];
  setReplyingTo: FIXME;
  loadPreviousMessages?: (chat: Chat ) => Promise<void>;
}

export default function ChatThread({ chat, messages, setReplyingTo, loadPreviousMessages }: ChatThreadProps) {
  const { userId } = useContext(AuthContext);
  const ref = useRef<HTMLDivElement>(null);

  const [userIsTyping, setUserIsTyping] = useState(false);
  const [messagesLoading, setMessagesLoading] = useState(false);

  const [preventScroll, setPreventScroll] = useState(false)

  useEffect(() => {
    let timer: FIXME;

    const handler = (evt: FIXME) => {
      const { chatId, userId: messageUserId, type } = evt.detail;
      if (
        type === PusherEvents.CHAT_USER_IS_TYPING &&
        chatId === chat.chatId &&
        messageUserId !== userId
      ) {
        clearTimeout(timer);
        setUserIsTyping(true);
        timer = window.setTimeout(() => setUserIsTyping(false), 1000);
      }
    };
    window.addEventListener("chat-event", handler);
    return () => {
      window.removeEventListener("chat-event", handler);
    };
  }, [chat.chatId, userId]);

  const grouped = groupedMessagesByDate(messages);


  const loadMore = async () => {
    if (loadPreviousMessages) {
      setMessagesLoading(true);
      setPreventScroll(true)
      const previousFirst = ref.current
      await loadPreviousMessages(chat);


      setTimeout(() => {
        if (previousFirst) {
          previousFirst.scrollIntoView({ behavior: "instant" });
        }
        setMessagesLoading(false);
        setPreventScroll(false)
      }, 500);
    }
  }

  return (
    <div className={classes.wrapper}>
      <ScrollToBottomObserver preventScroll={preventScroll} messages={messages}>
        <div className={classes.messages}>
          {userIsTyping && (
            <div className={classes.isTyping}>
              <LoadingDots />
            </div>
          )}
          {grouped.map((group, index) => (
            <Fragment key={`group-${group.date}-${index}`}>
              {group.messages.map((message: FIXME, messageIndex: number) => (
                <div
                  className={classNames(
                    classes.message,
                    message.author.userId === userId && classes.yours
                  )}
                  key={message.messageId || uuid()}
                  ref={index === (grouped.length - 1) && messageIndex === (group.messages.length - 1) ? ref : undefined}
                >
                  <ChatMessage chat={chat} message={message} setReplyingTo={setReplyingTo} />
                </div>
              ))}
              <div className={classes.group}>
                <span>{group.date}</span>
              </div>
            </Fragment>
          ))}
          {!messagesLoading && loadPreviousMessages && messages.length < chat.totalMessages &&
            <div className={classes.separatorContainer}>
              <div className={classes.separator}></div>
              <button onClick={loadMore} className={classes.loadMore}><ArrowUp /> Load Previous</button>
            </div>}
          {messagesLoading && (
            <div className={classes.isTyping}>
              <LoadingDots />
            </div>
          )}
        </div>
      </ScrollToBottomObserver>
    </div>
  );
}
