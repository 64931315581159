export default function LoadingDots() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="30"
      width="60"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="loading-dots" fill="#cbbbec">
          <circle id="dot1" cx="15" cy="15" r="6"></circle>
          <circle id="dot2" cx="30" cy="15" r="6"></circle>
          <circle id="dot3" cx="45" cy="15" r="6"></circle>
        </g>
      </g>
    </svg>
  );
}
