import { FIXME } from "../../../shared/types";
import Avatar from "../../Shared/Avatar";
import Link from "../../Shared/Link";

import classes from "./AttendeesListRow.module.css";

interface AttendeesListRowProps {
  data: FIXME;
  index: number;
  style: FIXME;
}

export default function AttendeesListRow({ index, style, data }: AttendeesListRowProps) {
  const user = data[index];

  return (
    <div className={classes.row} style={style}>
      <Link className={classes.link} to={`/profile/${user.userId}`}>
        <div className={classes.avatar}>
          <Avatar size={46} user={user} />
        </div>
        <div className={classes.name}>{user.name}</div>
        <div className={classes.username}>{user.username}</div>
      </Link>
    </div>
  );
}
