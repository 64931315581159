import { useContext, useMemo, useState } from "react";
import { TextareaAutosize } from "@mui/base";
import { Close as CloseIcon, Send as SendIcon } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import classNames from "classnames";
import throttle from "lodash.throttle";

import { ChatsContext } from "../../../providers/ChatsProvider";
import { getImageUrl } from "../../../shared/image-helpers";
import { FIXME } from "../../../shared/types";
import ChatUploadImage from "../ChatUploadImage";

import useScrollToInput from "./scrollIntoView";

import classes from "./NewMessageForm.module.css";

const { VITE_CLOUDINARY_CLOUD_NAME, VITE_CLOUDINARY_UPLOAD_PRESET } = import.meta.env;

interface NewMessageFormProps {
  chat: FIXME;
  className?: string;
  initialValue?: string;
  replyingTo: FIXME;
  setReplyingTo: FIXME;
}

export default function NewMessageForm({
  chat,
  className,
  initialValue = "",
  replyingTo,
  setReplyingTo,
}: NewMessageFormProps) {
  const [imageUploading, setImageUploading] = useState(false);
  const [message, setMessage] = useState(initialValue);
  const [inputRef, scrollToInput] = useScrollToInput();
  const { createUserIsTyping, sendMessage } = useContext(ChatsContext);
  const isIOS = () => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent);
  };
  const handleOnChange = (evt: FIXME) => {
    const { value } = evt.target;
    setMessage(value);
  };
  const handleBlur = () => {
    if (inputRef.current) {
      if (isIOS()) {
        setTimeout(() => {
          inputRef.current.style.position = "";
          inputRef.current.style.bottom = "";
          inputRef.current.style.left = "";
          inputRef.current.style.width = "";
          inputRef.current.style.zIndex = "";
        }, 100);
      }
    }
  };
  const handleOnKeyPress = useMemo(
    () =>
      throttle(() => {
        createUserIsTyping({ variables: { chatId: chat.chatId } });
      }, 600),
    [chat.chatId, createUserIsTyping]
  );

  const handleOnUpload = async (evt: FIXME) => {
    const file = evt.target.files[0];

    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", VITE_CLOUDINARY_UPLOAD_PRESET);

    try {
      setImageUploading(true);

      const response = await fetch(
        `https://api.cloudinary.com/v1_1/${VITE_CLOUDINARY_CLOUD_NAME}/upload`,
        {
          method: "POST",
          body: formData,
        }
      );

      setImageUploading(false);

      if (response.ok) {
        const result = await response.json();
        sendMessage(chat.chatId, { image: result.secure_url, replyingTo: replyingTo });
        setMessage(() => "");
        setReplyingTo(() => null);
      } else {
        console.error("Upload failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleOnSubmit = async (evt: FIXME) => {
    evt.preventDefault();
    sendMessage(chat.chatId, { replyingTo: replyingTo, text: message });
    setMessage(() => "");
    setReplyingTo(() => null);
    handleBlur();
  };

  return (
    <div className={classNames(classes.wrapper, className)} ref={inputRef}>
      {replyingTo && (
        <div className={classes.replyingTo}>
          <div className={classes.replyingToText}>
            <p className={classes.replyingToName}>{replyingTo.author.name}</p>
            {replyingTo.image && (
              <img
                className={classes.replyingToImage}
                src={getImageUrl({ url: replyingTo.image, height: 30, width: 50 })}
              />
            )}
            {replyingTo.text && <p className={classes.replyingToContent}>{replyingTo.text}</p>}
          </div>
          <div className={classes.replyingToAction}>
            <button className={classes.remove} onClick={() => setReplyingTo(null)} type="button">
              <CloseIcon />
            </button>
          </div>
        </div>
      )}
      <form className={classes.form} onSubmit={handleOnSubmit}>
        <label className="sr-only" htmlFor={`send-message-${chat.chatId}`}>
          Message
        </label>
        <TextareaAutosize
          className={classes.textarea}
          id={`send-message-${chat.chatId}`}
          maxRows={4}
          onKeyDown={handleOnKeyPress}
          onFocus={scrollToInput}
          onChange={handleOnChange}
          placeholder="Message"
          onBlur={handleBlur}
          value={message}
        />
        {message.length === 0 ? (
          <>
            {imageUploading ? (
              <span className={classes.button}>
                <CircularProgress className={classes.uploading} size={24} />
              </span>
            ) : (
              <ChatUploadImage
                className={classNames(classes.button, classes.imageButton)}
                onImageUpload={handleOnUpload}
              />
            )}
          </>
        ) : (
          <button className={classNames(classes.button, classes.messageButton)}>
            <SendIcon />
            <span className="sr-only">Send message</span>
          </button>
        )}
      </form>
    </div>
  );
}
