import { FormEvent, useContext, useReducer, useState } from "react";
import { useQuery } from "@apollo/client";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import classNames from "classnames";

import {
  Course,
  DateOfBirth,
  Gender,
  Interests,
  IsCurrentStudent,
  Location,
  Name,
  Notifications,
  Outro,
  Photos,
  Progress,
  StudyYear,
  University,
  Username,
} from "../../components/Onboarding";
import { GET_ALL_LOCATIONS } from "../../graphql/locations.graphql";
import { GET_ALL_TAGS } from "../../graphql/tags.graphql";
import { OnboardingContext, OnboardingStep } from "../../providers/OnboardingProvider";
import { OnboardingActions, onboardingReducer } from "../../reducers/onboarding.reducer";
import { callNativeApp, NativeAppActions } from "../../shared/native-app-helpers";
import { getTagCategories } from "../../shared/tag-helpers";

import classes from "./OnboardingPage.module.css";

export default function OnboardingPage() {
  const {
    getNotificationToken,
    isStudent,
    refetchOnboarded,
    saveUser,
    screen,
    step,
    setIsStudent,
    setStep,
    totalSteps,
  } = useContext(OnboardingContext);

  const [error, setError] = useState("");
  const [saving, setSaving] = useState(false);
  const [state, updateOnboarding] = useReducer(onboardingReducer, {});

  const handleOnBack = () => {
    if (screen === OnboardingStep.Notifications) {
      callNativeApp({ action: NativeAppActions.SET_BACKGROUND_WHITE });
    }

    if (step === 1) callNativeApp({ action: NativeAppActions.LOG_OUT });
    else setStep(step - 1);
  };

  const { data: locationsData } = useQuery(GET_ALL_LOCATIONS);
  const { data: tagsData } = useQuery(GET_ALL_TAGS);

  const { courses, genders, interests, universities } = getTagCategories(tagsData?.tags || []);
  const locations = locationsData?.locations || [];

  const handleOnSubmit = async (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    setSaving(true);

    const updateUserInput = {
      ...state,
      onboardingCompleted: screen === OnboardingStep.Outro,
    };

    updateUserInput.dob = updateUserInput.dob?.split("T")?.[0];

    updateUserInput.tags = [
      ...(state.interestIds || []),
      state.courseId,
      state.universityId,
    ].filter((notempty) => notempty);
    delete updateUserInput.courseId;
    delete updateUserInput.interestIds;
    delete updateUserInput.universityId;

    const error = await saveUser(updateUserInput);
    if (error) {
      setError(error);
    } else {
      setError("");

      if (screen === OnboardingStep.Gender && !state.gender) {
        setError("You must choose a gender");
        setSaving(false);
        return;
      }

      if (screen === OnboardingStep.Photos && (!state.images || state.images.length < 1)) {
        setError("You must upload at least one profile photo");
        setSaving(false);
        return;
      }

      if (
        screen === OnboardingStep.Interests &&
        (!state.interestIds || state.interestIds.length < 3)
      ) {
        setError("You must choose at least three interests");
        setSaving(false);
        return;
      }

      if (screen === OnboardingStep.Interests) {
        callNativeApp({ action: NativeAppActions.SET_BACKGROUND_VIOLET });
      }

      if (screen === OnboardingStep.Notifications) getNotificationToken();

      if (step < totalSteps) setStep(step + 1);
      if (screen === OnboardingStep.Outro) {
        await refetchOnboarded();
        callNativeApp({ action: NativeAppActions.SET_BACKGROUND_WHITE });
      }
    }

    setSaving(false);
  };

  return (
    <form
      className={classNames(
        classes.page,
        (screen === OnboardingStep.Notifications || screen === OnboardingStep.Outro) &&
          classes.altPage
      )}
      onError={() => false}
      onSubmit={handleOnSubmit}
    >
      <div className={classes.form}>
        <Progress current={step} total={totalSteps} />
        {screen === OnboardingStep.Course && (
          <Course
            courses={courses}
            courseId={state.courseId}
            error={error}
            onChange={(courseId) =>
              updateOnboarding({
                type: OnboardingActions.UPDATE_COURSE_ID,
                courseId,
              })
            }
          />
        )}
        {screen === OnboardingStep.DateOfBirth && (
          <DateOfBirth
            dob={state.dob}
            error={error}
            onChange={(dob) =>
              updateOnboarding({
                type: OnboardingActions.UPDATE_DOB,
                dob,
              })
            }
          />
        )}
        {screen === OnboardingStep.IsCurrentStudent && (
          <IsCurrentStudent
            isStudent={isStudent}
            onChange={(isStudent) => setIsStudent(isStudent)}
          />
        )}
        {screen === OnboardingStep.Gender && (
          <Gender
            error={error}
            gender={state.gender}
            genders={genders}
            onChange={(gender) =>
              updateOnboarding({
                type: OnboardingActions.UPDATE_GENDER,
                gender,
              })
            }
          />
        )}
        {screen === OnboardingStep.Interests && (
          <Interests
            error={error}
            interestIds={state.interestIds}
            interests={interests}
            onChange={(interestIds) =>
              updateOnboarding({
                type: OnboardingActions.UPDATE_INTEREST_IDS,
                interestIds,
              })
            }
          />
        )}
        {screen === OnboardingStep.Location && (
          <Location
            error={error}
            locationId={state.locationId}
            locations={locations}
            onChange={(locationId) => {
              updateOnboarding({
                type: OnboardingActions.UPDATE_LOCATION_ID,
                locationId,
              });
            }}
          />
        )}
        {screen === OnboardingStep.Name && (
          <Name
            error={error}
            onChange={(name) =>
              updateOnboarding({
                type: OnboardingActions.UPDATE_NAME,
                name,
              })
            }
            name={state.name}
          />
        )}
        {screen === OnboardingStep.Notifications && <Notifications />}
        {screen === OnboardingStep.Outro && <Outro />}
        {screen === OnboardingStep.Photos && (
          <Photos
            error={error}
            images={state.images}
            onChange={(images) =>
              updateOnboarding({ type: OnboardingActions.UPDATE_IMAGES, images })
            }
          />
        )}
        {screen === OnboardingStep.StudyYear && (
          <StudyYear
            error={error}
            onChange={(studyYear) =>
              updateOnboarding({
                type: OnboardingActions.UPDATE_STUDY_YEAR,
                studyYear,
              })
            }
            studyYear={state.studyYear}
          />
        )}
        {screen === OnboardingStep.University && (
          <University
            error={error}
            onChange={(universityId) =>
              updateOnboarding({
                type: OnboardingActions.UPDATE_UNIVERSITY_ID,
                universityId,
              })
            }
            universities={universities}
            universityId={state.universityId}
          />
        )}
        {screen === OnboardingStep.Username && (
          <Username
            error={error}
            onChange={(username) =>
              updateOnboarding({
                type: OnboardingActions.UPDATE_USERNAME,
                username,
              })
            }
            username={state.username}
          />
        )}
      </div>
      <div className={classes.buttons}>
        <button
          className={classNames(classes.button, classes.back)}
          onClick={handleOnBack}
          type="button"
        >
          <ArrowBackIcon />
        </button>
        <button
          className={classNames(classes.button, classes.forward)}
          disabled={saving}
          type="submit"
        >
          {saving ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            <>{step === totalSteps ? "Finish" : "Next"}</>
          )}
        </button>
      </div>
    </form>
  );
}
