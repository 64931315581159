import { User } from "../../../graphql/__generated__/graphql";
import { FIXME } from "../../../shared/types";
import Avatar from "../../Shared/Avatar";
import Link from "../../Shared/Link";

import classes from "./Attendees.module.css";

interface AttendeesProps {
  event: FIXME;
}

export default function Attendees({ event }: AttendeesProps) {
  if (event.isAttendeesHidden) {
    return (
      <div className={classes.wrapper}>
        <h2 className={classes.title}>Going ({event.attendees.length})</h2>
      </div>
    );
  }

  return (
    <div className={classes.wrapper}>
      <h2 className={classes.title}>Going ({event.attendees.length})</h2>
      <Link className={classes.link} to={`/events/${event.eventId}/attendees`}>
        View all
      </Link>
      <ul className={classes.list}>
        {event.attendees.map((attendee: User, index: number) => (
          <li className={classes.item} key={`${event.eventId}-${index}`}>
            <Link to={`/profile/${attendee.userId}`}>
              <Avatar size={46} user={attendee} />
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
