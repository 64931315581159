import { ReactNode } from "react";
import { ChevronLeft as ChevronLeftIcon } from "@mui/icons-material";

import { FIXME } from "../../../shared/types";

import classes from "./FormModal.module.css";

interface FormModalProps {
  children: ReactNode;
  onClose: FIXME;
  title: string;
}

export default function FormModal({ children, onClose, title }: FormModalProps) {
  return (
    <div className={classes.wrapper}>
      <button className={classes.header} onClick={onClose} type="button">
        <ChevronLeftIcon className={classes.arrow} />
        <span className={classes.text}>{title}</span>
      </button>
      <div className={classes.content}>{children}</div>
    </div>
  );
}
