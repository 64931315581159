import { FIXME } from "../../../shared/types";
import ClubsList from "../../Clubs/List";

import classes from "./Clubs.module.css";

interface ClubsProps {
  user: FIXME;
}

export default function Clubs({ user }: ClubsProps) {
  if (user.clubs.created.length === 0 && user.clubs.joined.length === 0) {
    return <p className={classes.empty}>User has not joined any clubs yet!</p>;
  }

  return (
    <div className={classes.wrapper}>
      {user.clubs.joined.length > 0 && (
        <ClubsList clubs={user.clubs.joined} title={"Clubs joined:"} />
      )}
      {user.clubs.created.length > 0 && (
        <ClubsList clubs={user.clubs.created} title={"Clubs created:"} />
      )}
    </div>
  );
}
