import { graphql } from "./__generated__/gql";

export const MARK_AS_READ = graphql(`
  mutation MarkAsRead($chatId: ID!) {
    markAsRead(chatId: $chatId)
  }
`);

export const CREATE_MESSAGE = graphql(`
  mutation CreateMessage($createMessageInput: CreateMessageInput!) {
    createMessage(createMessageInput: $createMessageInput) {
      chatId
    }
  }
`);

export const CREATE_USER_IS_TYPING = graphql(`
  mutation CreateUserIsTyping($chatId: ID!) {
    createUserIsTyping(chatId: $chatId)
  }
`);

export const CREATE_CLUB_CHAT = graphql(`
  mutation CreateClubChat($createClubChatInput: CreateClubChatInput!) {
    createClubChat(createClubChatInput: $createClubChatInput) {
      chatId
      name
      clubId
      eventId
      userId
      club {
        clubId
        name
        image
        admins {
          userId
        }
      }
      event {
        eventId
        name
        image
        admins {
          userId
        }
      }
      messages {
        messageId
        authorId
        author {
          userId
          avatar
          name
          isBlocked
        }
        image
        text
        replyingToId
        replyingTo {
          messageId
          authorId
          author {
            userId
            avatar
            name
            isBlocked
          }
          image
          text
          createdAt
          deletedAt
        }
        reactions {
          reactionId
          authorId
          type
        }
        createdAt
        deletedAt
      }
      participants {
        userId
        name
        username
        avatar
        isBlocked
      }
      unread
      createdAt
    }
  }
`);

export const CREATE_EVENT_CHAT = graphql(`
  mutation CreateEventChat($createEventChatInput: CreateEventChatInput!) {
    createEventChat(createEventChatInput: $createEventChatInput) {
      chatId
      name
      clubId
      eventId
      userId
      club {
        clubId
        name
        image
        admins {
          userId
        }
      }
      event {
        eventId
        name
        image
        admins {
          userId
        }
      }
      messages {
        messageId
        authorId
        author {
          userId
          avatar
          name
          isBlocked
        }
        image
        text
        replyingToId
        replyingTo {
          messageId
          authorId
          author {
            userId
            avatar
            name
            isBlocked
          }
          image
          text
          createdAt
          deletedAt
        }
        reactions {
          reactionId
          authorId
          type
        }
        createdAt
        deletedAt
      }
      participants {
        userId
        name
        username
        avatar
        isBlocked
      }
      unread
      createdAt
    }
  }
`);

export const CREATE_USER_CHAT = graphql(`
  mutation CreateUserChat($createUserChatInput: CreateUserChatInput!) {
    createUserChat(createUserChatInput: $createUserChatInput) {
      chatId
      name
      clubId
      eventId
      userId
      club {
        clubId
        name
        image
        admins {
          userId
        }
      }
      event {
        eventId
        name
        image
        admins {
          userId
        }
      }
      messages {
        messageId
        authorId
        author {
          userId
          avatar
          name
          isBlocked
        }
        image
        text
        replyingToId
        replyingTo {
          messageId
          authorId
          author {
            userId
            avatar
            name
            isBlocked
          }
          image
          text
          createdAt
          deletedAt
        }
        reactions {
          reactionId
          authorId
          type
        }
        createdAt
        deletedAt
      }
      participants {
        userId
        name
        username
        avatar
        isBlocked
      }
      unread
      createdAt
    }
  }
`);

export const DELETE_CHAT = graphql(`
  mutation DeleteChat($chatId: ID!) {
    deleteChat(chatId: $chatId)
  }
`);

export const DELETE_MESSAGE = graphql(`
  mutation DeleteMessage($messageId: ID!) {
    deleteMessage(messageId: $messageId)
  }
`);

export const GET_CHAT_BY_ID = graphql(`
  query GetChat($chatId: ID!, $limit: Int, $beforeDate: String, $afterDate: String) {
    chat(chatId: $chatId, limit: $limit, beforeDate: $beforeDate, afterDate: $afterDate) {
      chatId
      name
      clubId
      eventId
      userId
      club {
        clubId
        name
        image
        admins {
          userId
        }
      }
      event {
        eventId
        name
        image
        admins {
          userId
        }
      }
      participants {
        userId
        name
        username
        avatar
        isBlocked
      }
      messages {
        messageId
        authorId
        author {
          userId
          avatar
          name
          isBlocked
        }
        image
        text
        replyingToId
        replyingTo {
          messageId
          authorId
          author {
            userId
            avatar
            name
            isBlocked
          }
          image
          text
          createdAt
          deletedAt
        }
        reactions {
          reactionId
          authorId
          type
        }
        createdAt
        deletedAt
      }
      totalMessages
      unread
    }
  }
`);

export const GET_CHATS = graphql(`
  query GetChats(
    $limit: Int
    $offset: Int
    $clubId: String
    $eventId: String
    $tabaction: String
    $messagesLimit: Int
  ) {
    chats(
      limit: $limit
      offset: $offset
      clubId: $clubId
      eventId: $eventId
      tabaction: $tabaction
      messagesLimit: $messagesLimit
    ) {
      chatId
      name
      clubId
      eventId
      userId
      club {
        clubId
        name
        image
        admins {
          userId
        }
      }
      event {
        eventId
        name
        image
        admins {
          userId
        }
      }
      messages {
        messageId
        authorId
        author {
          userId
          avatar
          name
          isBlocked
        }
        image
        text
        replyingToId
        replyingTo {
          messageId
          authorId
          author {
            userId
            avatar
            name
            isBlocked
          }
          image
          text
          createdAt
          deletedAt
        }
        reactions {
          reactionId
          authorId
          type
        }
        createdAt
        deletedAt
      }
      participants {
        userId
        name
        username
        avatar
        isBlocked
      }
      totalMessages
      unread
      createdAt
    }
  }
`);

export const GET_CHAT_MESSAGES = graphql(`
  query GetChatMessages($chatId: ID!, $limit: Int, $beforeDate: String, $afterDate: String) {
    chat(chatId: $chatId, limit: $limit, beforeDate: $beforeDate, afterDate: $afterDate) {
      chatId
      unread
      totalMessages
      messages {
        messageId
        authorId
        author {
          userId
          avatar
          name
          isBlocked
        }
        image
        text
        replyingToId
        replyingTo {
          messageId
          authorId
          author {
            userId
            avatar
            name
            isBlocked
          }
          image
          text
          createdAt
          deletedAt
        }
        reactions {
          reactionId
          authorId
          type
        }
        createdAt
        deletedAt
      }
    }
  }
`);

export const CREATE_REACTION = graphql(`
  mutation CreateReaction($messageId: ID!, $reaction: ReactionType!) {
    createReaction(messageId: $messageId, reaction: $reaction)
  }
`);

export const DELETE_REACTION = graphql(`
  mutation DeleteReaction($messageId: ID!, $reaction: ReactionType!) {
    deleteReaction(messageId: $messageId, reaction: $reaction)
  }
`);

export const IS_MUTED = graphql(`
  query IsMuted($chatId: ID!) {
    isChatMuted(chatId: $chatId)
  }
`);

export const MUTE_CHAT = graphql(`
  mutation Mute($chatId: ID!) {
    muteChat(chatId: $chatId)
  }
`);

export const UNMUTE_CHAT = graphql(`
  mutation Unmute($chatId: ID!) {
    unmuteChat(chatId: $chatId)
  }
`);
