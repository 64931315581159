import { Place as PlaceIcon } from "@mui/icons-material";

import { getImageUrl } from "../../../shared/image-helpers";
import { FIXME } from "../../../shared/types";

import classes from "./Header.module.css";

interface HeaderProps {
  club: FIXME;
}

export default function Header({ club }: HeaderProps) {
  return (
    <div className={classes.wrapper}>
      {club.image && (
        <img
          className={classes.image}
          src={getImageUrl({ url: club.image, height: 502, width: 686 })}
        />
      )}
      <div className={classes.name}>{club.name}</div>
      <div className={classes.details}>
        {club.membersCount || 0} Member{club.membersCount === 1 ? "" : "s"} |{" "}
        {club.isPrivate ? "Private" : "Public"} club | <PlaceIcon /> {club.location.name}
      </div>
    </div>
  );
}
