import { getImageUrl } from "../../../shared/image-helpers";
import { FIXME } from "../../../shared/types";

import classes from "./Header.module.css";

interface HeaderProps {
  event: FIXME;
}

export default function Header({ event }: HeaderProps) {
  return (
    <div className={classes.wrapper}>
      {event.image && (
        <img
          className={classes.image}
          src={getImageUrl({ url: event.image, height: 502, width: 686 })}
        />
      )}
      <div className={classes.name}>{event.name}</div>
    </div>
  );
}
