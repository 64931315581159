import { graphql } from "./__generated__/gql";

export const ACCEPTING = graphql(`
  mutation Accepting($clubId: ID!, $userId: ID!) {
    accepting(clubId: $clubId, userId: $userId) {
      clubId
      members {
        userId
        avatar
        username
        name
      }
    }
  }
`);

export const JOINING = graphql(`
  mutation Joining($clubId: ID!) {
    joining(clubId: $clubId) {
      clubId
      members {
        userId
        avatar
        username
        name
      }
    }
  }
`);

export const GET_IS_JOINED = graphql(`
  query GetIsJoined($clubId: ID!) {
    isJoined(clubId: $clubId)
    hasRequestedJoin(clubId: $clubId)
  }
`);

export const GET_JOIN_REQUESTS = graphql(`
  query GetJoinRequests($clubId: ID!) {
    getRequests(clubId: $clubId) {
      userId
      avatar
      name
      username
    }
  }
`);

export const LEAVING = graphql(`
  mutation Leaving($clubId: ID!) {
    leaving(clubId: $clubId) {
      clubId
      members {
        userId
        avatar
        username
        name
      }
    }
  }
`);

export const REJECTING = graphql(`
  mutation Rejecting($clubId: ID!, $userId: ID!) {
    rejecting(clubId: $clubId, userId: $userId) {
      clubId
    }
  }
`);

export const REQUESTING = graphql(`
  mutation Requesting($clubId: ID!) {
    requesting(clubId: $clubId) {
      clubId
    }
  }
`);
