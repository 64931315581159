import { useState } from "react";
import {
  ChevronRight as ChevronRightIcon,
  Close as CloseIcon,
  Sell as SellIcon,
} from "@mui/icons-material";
import classNames from "classnames";

import { Tag } from "../../../graphql/__generated__/graphql";
import { useSuggestions } from "../../../hooks/useSuggestions";
import { SuggestionType } from "../../../shared/suggestions-helpers";
import { FIXME } from "../../../shared/types";
import FormModal from "../FormModal";
import Input from "../Input";

import classes from "./Tags.module.css";

interface TagsProps {
  available?: Tag[];
  onChange?: FIXME;
  searchable?: boolean;
  showSelection?: boolean;
  single?: boolean;
  suggestionsType?: SuggestionType;
  tags?: string[];
  title?: string;
}

export default function Tags({
  available = [],
  onChange = () => null,
  searchable = false,
  single = false,
  showSelection = false,
  suggestionsType,
  tags = [],
  title = "Tags",
}: TagsProps) {
  const [showingModal, setShowingModal] = useState(false);
  const { suggestions, addSuggestion } = useSuggestions(suggestionsType);

  const [search, setSearch] = useState("");
  const filter = (tag: Tag) => tag.name.toLowerCase().includes(search.toLowerCase());

  const selected: Tag[] = available.filter((tag) => tags.includes(tag.tagId));

  const handleOnToggle = (tag: Tag) => {
    const updated = tags.includes(tag.tagId)
      ? tags.filter((tagId) => tagId !== tag.tagId)
      : [...tags, tag.tagId];

    if (suggestionsType && tags.includes(tag.tagId) === false) addSuggestion(tag);

    onChange(updated);
    setSearch("");
    if (single) {
      setShowingModal(false);
    }
  };

  if (showingModal) {
    return (
      <FormModal onClose={() => setShowingModal(false)} title={title}>
        {searchable && (
          <div className={classes.search}>
            <Input
              hideLabel
              label="Search"
              onChange={setSearch}
              placeholder="Search"
              value={search}
            />
          </div>
        )}
        {showSelection && selected.length > 0 && (
          <>
            <div className={classes.list}>
              {selected.map((item) => (
                <button
                  className={classNames(classes.tag, classes.selected)}
                  key={item.tagId}
                  onClick={() => handleOnToggle(item)}
                  type="button"
                >
                  {item.name}
                  <CloseIcon />
                </button>
              ))}
            </div>
            <hr />
          </>
        )}
        <div className={classes.list}>
          {available.filter(filter).map((tag) => (
            <button
              className={classNames(classes.tag, selected.includes(tag) && classes.selected)}
              key={tag.tagId}
              onClick={() => handleOnToggle(tag)}
              type="button"
            >
              {tag.name}
            </button>
          ))}
        </div>
      </FormModal>
    );
  }

  return (
    <div className={classes.wrapper}>
      <button className={classes.header} onClick={() => setShowingModal(true)} type="button">
        <SellIcon className={classes.icon} />
        <span className={classes.text}>{title}</span>
        <ChevronRightIcon className={classes.arrow} />
      </button>
      {selected.length === 0 && suggestions.length > 0 && (
        <div className={classes.suggestions}>
          <span className={classes.label}>Suggested: </span>
          {suggestions.map((tag: Tag) => (
            <button
              className={classes.tag}
              key={tag.tagId}
              onClick={() => handleOnToggle(tag)}
              type="button"
            >
              {tag.name}
            </button>
          ))}
        </div>
      )}
      {selected.length > 0 && (
        <div className={classes.selection}>
          {selected.map((tag) => (
            <span className={classNames(classes.tag, classes.selected)} key={tag.tagId}>
              {tag.name}
            </span>
          ))}
        </div>
      )}
    </div>
  );
}
