import { useContext } from "react";
import { Edit as EditIcon } from "@mui/icons-material";

import { Tag } from "../../../graphql/__generated__/graphql";
import { AppContext } from "../../../providers/AppProvider";
import { AuthContext } from "../../../providers/AuthProvider";
import { FIXME } from "../../../shared/types";
import IconButton from "../../Forms/IconButton";
import TagsList from "../../Shared/TagsList";

import classes from "./Bio.module.css";

interface BioProps {
  user: FIXME;
}

export default function Bio({ user }: BioProps) {
  const { navigate } = useContext(AppContext);

  const { userId } = useContext(AuthContext);
  const isYourProfile = user.userId === userId;

  const aboutMe = user?.tags?.filter((tag: Tag) => tag?.grandparent?.name === "About me");
  const educationAndCareer = user?.tags?.filter(
    (tag: Tag) => tag?.grandparent?.name === "Education & Career"
  );
  const interests = user?.tags?.filter((tag: Tag) => tag?.grandparent?.name === "Interests");
  const goals = user?.tags?.filter(
    (tag: Tag) => tag?.parent?.name === "Goals" && tag?.grandparent?.name === "Goals"
  );
  const values = user?.tags?.filter((tag: Tag) => tag?.grandparent?.name === "Values");

  if (
    !user.bio &&
    aboutMe.length === 0 &&
    educationAndCareer.length === 0 &&
    interests.length === 0 &&
    goals.length === 0 &&
    values.length === 0
  ) {
    return <p className={classes.empty}>User has no bio added!</p>;
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.bio}>
        <div className={classes.title}>Bio</div>
        <div className={classes.edit}>
          {isYourProfile && (
            <IconButton icon={<EditIcon />} onClick={() => navigate("/edit/profile")} />
          )}
        </div>
        <div className={classes.text}>{user.bio}</div>
      </div>
      {aboutMe.length > 0 && (
        <div className={classes.tags}>
          <div className={classes.title}>About Me</div>
          <div className={classes.edit}>
            {isYourProfile && (
              <IconButton icon={<EditIcon />} onClick={() => navigate("/edit/profile")} />
            )}
          </div>
          <div className={classes.list}>
            <TagsList tags={aboutMe} />
          </div>
        </div>
      )}
      {educationAndCareer.length > 0 && (
        <div className={classes.tags}>
          <div className={classes.title}>Education & Career</div>
          <div className={classes.edit}>
            {isYourProfile && (
              <IconButton icon={<EditIcon />} onClick={() => navigate("/edit/profile")} />
            )}
          </div>
          <div className={classes.list}>
            <TagsList tags={educationAndCareer} />
          </div>
        </div>
      )}
      {interests.length > 0 && (
        <div className={classes.tags}>
          <div className={classes.title}>Interests</div>
          <div className={classes.edit}>
            {isYourProfile && (
              <IconButton icon={<EditIcon />} onClick={() => navigate("/edit/profile")} />
            )}
          </div>
          <div className={classes.list}>
            <TagsList tags={interests} />
          </div>
        </div>
      )}
      {goals.length > 0 && (
        <div className={classes.tags}>
          <div className={classes.title}>Goals</div>
          <div className={classes.edit}>
            {isYourProfile && (
              <IconButton icon={<EditIcon />} onClick={() => navigate("/edit/profile")} />
            )}
          </div>
          <div className={classes.list}>
            <TagsList tags={goals} />
          </div>
        </div>
      )}
      {values.length > 0 && (
        <div className={classes.tags}>
          <div className={classes.title}>Values</div>
          <div className={classes.edit}>
            {isYourProfile && (
              <IconButton icon={<EditIcon />} onClick={() => navigate("/edit/profile")} />
            )}
          </div>
          <div className={classes.list}>
            <TagsList tags={values} />
          </div>
        </div>
      )}
    </div>
  );
}
