import { getImageUrl } from "../../../shared/image-helpers.ts";
import ChevronRightIcon from "../../Shared/Icons/ChevronRight.tsx";

import classes from "./Card.module.css";

export interface LoadMoreCardProps {
  backgroundImageUrl?: string
  text?: string
  clickAction?: ()=> void
}
export default function LoadMoreCard({backgroundImageUrl, text, clickAction}: LoadMoreCardProps) {
  const namePlaceholder = "name Placehoder that is long enough"
  const placeholderImageUrl = "https://picsum.photos/200/300/?blur=2"

  const onClickAction = () =>{
    clickAction && clickAction()
  }

  return (<>
    <div className={classes.wrapper} onClick={onClickAction}>
      <img
        className={classes.image}
        src={backgroundImageUrl ? getImageUrl({ url: backgroundImageUrl, height: 230, width: 264 }) : placeholderImageUrl}
      />
      <div className={classes.content}>
        <p className={classes.privacy}>Private club</p>
        <h2 className={classes.name}>{namePlaceholder}</h2>
        <p className={classes.members}>
          29 members
        </p>
      </div>
      <div className={classes.overlay}>
        <ChevronRightIcon  />
        <p>
          {text ?? "Load More"}
        </p>
      </div>
    </div>
  </>)
}