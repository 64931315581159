import { useState } from "react";
import { Add as AddIcon } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import classNames from "classnames";

import { FIXME } from "../../../shared/types";

import classes from "./ImageUploader.module.css";

const { VITE_CLOUDINARY_CLOUD_NAME, VITE_CLOUDINARY_UPLOAD_PRESET } = import.meta.env;

interface ImageUploaderProps {
  onUploaded?: FIXME;
}

export default function ImageUploader({ onUploaded = () => null }: ImageUploaderProps) {
  const [uploading, setUploading] = useState(false);

  const handleOnUpload = async (evt: FIXME) => {
    setUploading(true);

    const uploaded = [];

    for await (const file of evt.target.files) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", VITE_CLOUDINARY_UPLOAD_PRESET);

      try {
        const response = await fetch(
          `https://api.cloudinary.com/v1_1/${VITE_CLOUDINARY_CLOUD_NAME}/upload`,
          {
            method: "POST",
            body: formData,
          }
        );

        if (response.ok) {
          const result = await response.json();
          uploaded.push(result.secure_url);
        } else {
          console.error("Upload failed:", response.statusText);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }

    onUploaded(uploaded);
    setUploading(false);
  };

  if (uploading) {
    return (
      <div className={classes.overlay}>
        <CircularProgress size={80} />
      </div>
    );
  }

  return (
    <label className={classNames(classes.uploader, "uploader")}>
      <input accept="image/*" className={classes.input} onChange={handleOnUpload} type="file" />
      <AddIcon className={classes.addIcon} />
    </label>
  );
}
