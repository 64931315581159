import { useState } from "react";

import { Tag } from "../../../graphql/__generated__/graphql";
import { ClubActions } from "../../../reducers/club.reducer";
import { SuggestionType } from "../../../shared/suggestions-helpers";
import { FIXME } from "../../../shared/types";
import Admins from "../../Forms/Admins";
import AuthorHidden from "../../Forms/AuthorHidden";
import Images from "../../Forms/Images";
import Input from "../../Forms/Input";
import Location from "../../Forms/Location";
import Privacy from "../../Forms/Privacy";
import Tags from "../../Forms/Tags";
import Visibility from "../../Forms/Visibility";
import Tabs from "../../Shared/Tabs";

import classes from "./EditClubForm.module.css";

interface EditClubFormProps {
  locations: FIXME;
  state: FIXME;
  tags: FIXME;
  updateClub: FIXME;
}

export default function EditClubForm({
  locations,
  state,
  tags = [],
  updateClub,
}: EditClubFormProps) {
  const [selectedTab, setSelectedTab] = useState(0);
  const tabs = [
    { label: "Details", onSelect: () => setSelectedTab(0) },
    { label: "Tags", onSelect: () => setSelectedTab(1) },
    { label: "Settings", onSelect: () => setSelectedTab(2) },
  ];

  return (
    <div>
      <Tabs className={classes.tabs} selectedIndex={selectedTab} tabs={tabs} />
      {selectedTab === 0 && (
        <div className={classes.tabContent}>
          <div className={classes.field}>
            <Input
              label="Name"
              onChange={(name) => {
                updateClub({ type: ClubActions.UPDATE_NAME, name });
              }}
              value={state.name}
            />
          </div>
          <div className={classes.field}>
            <Input
              label="Description"
              onChange={(description) =>
                updateClub({ type: ClubActions.UPDATE_DESCRIPTION, description })
              }
              rows={5}
              type="textarea"
              value={state.description}
            />
          </div>
          <div className={classes.field}>
            <Images
              images={state.image ? [state.image] : []}
              maximum={1}
              onChange={(images: FIXME) =>
                updateClub({ type: ClubActions.UPDATE_IMAGE, image: images?.[0] })
              }
              headingTitle="Image"
              title="Back to create club"
            />
          </div>
        </div>
      )}
      {selectedTab === 1 && (
        <div className={classes.tabContent}>
          <div className={classes.field}>
            <Location
              locationId={state.locationId}
              locations={locations}
              onChange={(locationId: string) =>
                updateClub({ type: ClubActions.UPDATE_LOCATION_ID, locationId })
              }
              suggestionsType={SuggestionType.Location}
            />
          </div>
          <div className={classes.field}>
            <Tags
              available={tags.filter((tag: Tag) => tag?.parent?.name === "University")}
              onChange={(tags: FIXME) => updateClub({ type: ClubActions.UPDATE_TAGS, tags })}
              searchable
              showSelection
              suggestionsType={SuggestionType.University}
              tags={state.tags}
              title="Related Universities"
            />
          </div>
          <div className={classes.field}>
            <Tags
              available={tags.filter(
                (tag: Tag) =>
                  tag?.grandparent?.name === "Interests" && tag?.parent?.name === "Interests"
              )}
              onChange={(tags: FIXME) => updateClub({ type: ClubActions.UPDATE_TAGS, tags })}
              suggestionsType={SuggestionType.Interests}
              tags={state.tags}
              title="Tags"
            />
          </div>
        </div>
      )}
      {selectedTab === 2 && (
        <div className={classes.tabContent}>
          <div className={classes.field}>
            <Admins
              admins={state.admins}
              author={state.author}
              onChange={(admins: FIXME) => updateClub({ type: ClubActions.UPDATE_ADMINS, admins })}
            />
          </div>
          <div className={classes.field}>
            <Visibility
              isVisible={state.isVisible}
              onChange={(isVisible: boolean) =>
                updateClub({ type: ClubActions.UPDATE_IS_VISIBLE, isVisible })
              }
              type="clubs"
            />
          </div>
          <div className={classes.field}>
            <AuthorHidden
              isAuthorHidden={state.isAuthorHidden}
              onChange={(isAuthorHidden: boolean) =>
                updateClub({ type: ClubActions.UPDATE_IS_AUTHOR_HIDDEN, isAuthorHidden })
              }
            />
          </div>
          <div className={classes.field}>
            <Privacy
              isPrivate={state.isPrivate}
              onChange={(isPrivate: boolean) =>
                updateClub({ type: ClubActions.UPDATE_IS_PRIVATE, isPrivate })
              }
              type="club"
              verb="join"
            />
          </div>
        </div>
      )}
    </div>
  );
}
