import { Autocomplete, TextField } from "@mui/material";

import { FIXME } from "../../../shared/types";

import classes from "../Onboarding.module.css";

type CourseProps = {
  courses: FIXME[];
  courseId: string;
  error: string;
  onChange: (value: string) => void;
};

export default function Course({ courses, courseId, error, onChange }: CourseProps) {
  return (
    <div className={classes.container}>
      <div className={classes.intro}>Sign Up</div>
      <div className={classes.title}>Enter your course</div>
      {error && <div className={classes.error}>{error}</div>}
      <div className={classes.autocomplete}>
        <Autocomplete
          autoComplete
          clearIcon={null}
          disablePortal
          getOptionLabel={(option) => option.name}
          multiple
          options={courses}
          onChange={(_, courses: FIXME) => {
            const course = courses[courses.length - 1];
            onChange(course?.tagId || "");
          }}
          popupIcon={null}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{ ...params.inputProps, required: !courseId }}
              label=""
            />
          )}
          value={courses.filter((option) => option.tagId === courseId) || []}
        />
      </div>
    </div>
  );
}
