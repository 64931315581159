import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { ChevronRight as ChevronRightIcon } from "@mui/icons-material";

import { GET_IS_ATTENDING } from "../../../graphql/attending.graphql";
import { FIXME } from "../../../shared/types";
import Link from "../../Shared/Link";

import classes from "./JoinChat.module.css";

interface JoinChatProps {
  event: FIXME;
}

export default function JoinChat({ event }: JoinChatProps) {
  const [isAttending, setIsAttending] = useState(false);

  const { data } = useQuery(GET_IS_ATTENDING, {
    variables: { eventId: event.eventId },
  });

  useEffect(() => {
    setIsAttending(!!data?.isAttending);
  }, [data]);

  if (!isAttending) {
    return null;
  }

  return (
    <Link className={classes.wrapper} to={`/events/${event.eventId}/chats`}>
      <div className={classes.content}>
        <h3 className={classes.title}>Join our chat</h3>
        <p className={classes.text}>Want to get involved? Join our latest discussions.</p>
      </div>
      <div className={classes.arrow}>
        <ChevronRightIcon />
      </div>
    </Link>
  );
}
