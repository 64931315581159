import { Tag } from "../graphql/__generated__/graphql";
import { FIXME } from "../shared/types";

export enum ClubActions {
  SET = "SET",
  UPDATE_ADMINS = "UPDATE_ADMINS",
  UPDATE_DESCRIPTION = "UPDATE_DESCRIPTION",
  UPDATE_IMAGE = "UPDATE_IMAGE",
  UPDATE_IS_AUTHOR_HIDDEN = "UPDATE_IS_AUTHOR_HIDDEN",
  UPDATE_IS_PRIVATE = "UPDATE_IS_PRIVATE",
  UPDATE_IS_VISIBLE = "UPDATE_IS_VISIBLE",
  UPDATE_LOCATION_ID = "UPDATE_LOCATION_ID",
  UPDATE_NAME = "UPDATE_NAME",
  UPDATE_TAGS = "UPDATE_TAGS",
}

export const clubReducer = (state: FIXME, action: FIXME) => {
  const updated = { ...state };

  switch (action.type) {
    case ClubActions.SET:
      updated.author = action.author;
      updated.admins = action.admins || [];
      updated.description = action.description;
      updated.image = action.image;
      updated.isAuthorHidden = action.isAuthorHidden;
      updated.isPrivate = action.isPrivate;
      updated.isVisible = action.isVisible;
      updated.locationId = action.locationId;
      updated.name = action.name;
      updated.tags = action.tags?.map((tag: Tag) => tag.tagId);
      break;
    case ClubActions.UPDATE_ADMINS:
      updated.admins = action.admins;
      break;
    case ClubActions.UPDATE_DESCRIPTION:
      updated.description = action.description;
      break;
    case ClubActions.UPDATE_IMAGE:
      updated.image = action.image;
      break;
    case ClubActions.UPDATE_IS_AUTHOR_HIDDEN:
      updated.isAuthorHidden = action.isAuthorHidden;
      break;
    case ClubActions.UPDATE_IS_PRIVATE:
      updated.isPrivate = action.isPrivate;
      break;
    case ClubActions.UPDATE_IS_VISIBLE:
      updated.isVisible = action.isVisible;
      break;
    case ClubActions.UPDATE_LOCATION_ID:
      updated.locationId = action.locationId;
      break;
    case ClubActions.UPDATE_NAME:
      updated.name = action.name;
      break;
    case ClubActions.UPDATE_TAGS:
      updated.tags = action.tags;
      break;
    default:
      throw new Error("Unknown clubReducer action");
  }

  return updated;
};
