import { useState } from "react";

import { Tag } from "../../../graphql/__generated__/graphql";
import { EventActions } from "../../../reducers/event.reducer";
import { SuggestionType } from "../../../shared/suggestions-helpers";
import { FIXME } from "../../../shared/types";
import AttendeesHidden from "../../Forms/AttendeesHidden";
import AuthorHidden from "../../Forms/AuthorHidden";
import Images from "../../Forms/Images";
import Input from "../../Forms/Input";
import Location from "../../Forms/Location";
import RelatedClubs from "../../Forms/RelatedClubs";
import Tags from "../../Forms/Tags";
import Visibility from "../../Forms/Visibility";
import Tabs from "../../Shared/Tabs";

import classes from "./EditEventForm.module.css";

interface EditEventFormProps {
  locations: FIXME;
  state: FIXME;
  tags: FIXME;
  updateEvent: FIXME;
  user: FIXME;
}

export default function EditEventForm({
  locations,
  state,
  tags = [],
  updateEvent,
  user,
}: EditEventFormProps) {
  const [selectedTab, setSelectedTab] = useState(0);
  const tabs = [
    { label: "Details", onSelect: () => setSelectedTab(0) },
    { label: "Tags", onSelect: () => setSelectedTab(1) },
    { label: "Settings", onSelect: () => setSelectedTab(2) },
  ];

  return (
    <div>
      <Tabs className={classes.tabs} selectedIndex={selectedTab} tabs={tabs} />
      {selectedTab === 0 && (
        <div className={classes.tabContent}>
          <div className={classes.field}>
            <Input
              label="Name"
              onChange={(name) => {
                updateEvent({ type: EventActions.UPDATE_NAME, name });
              }}
              value={state.name}
            />
          </div>
          <div className={classes.field}>
            <Input
              label="Description"
              onChange={(description) =>
                updateEvent({ type: EventActions.UPDATE_DESCRIPTION, description })
              }
              rows={5}
              type="textarea"
              value={state.description}
            />
          </div>
          <div className={classes.field}>
            <Images
              images={state.image ? [state.image] : []}
              maximum={1}
              onChange={(images: FIXME) =>
                updateEvent({ type: EventActions.UPDATE_IMAGE, image: images?.[0] })
              }
              headingTitle="Image"
              title="Back to create event"
            />
          </div>
          <div className={classes.field}>
            <Input
              label="Place"
              onChange={(place) => {
                updateEvent({ type: EventActions.UPDATE_PLACE, place });
              }}
              value={state.place}
            />
          </div>
          <div className={classes.field}>
            <Input
              label="Price"
              onChange={(price) => {
                updateEvent({ type: EventActions.UPDATE_PRICE, price });
              }}
              value={state.price}
            />
          </div>
          <div className={classes.field}>
            <Input
              label="Date & Time"
              onChange={(startTime) => {
                updateEvent({ type: EventActions.UPDATE_START_TIME, startTime });
              }}
              min={new Date().toISOString()}
              type="datetime-local"
              value={state.startTime}
            />
          </div>
          <div className={classes.field}>
            <Input
              label="Link"
              onChange={(link) => {
                updateEvent({ type: EventActions.UPDATE_LINK, link });
              }}
              type="url"
              value={state.link}
            />
          </div>
        </div>
      )}
      {selectedTab === 1 && (
        <div className={classes.tabContent}>
          <div className={classes.field}>
            <Tags
              available={tags.filter((tag: Tag) => tag?.parent?.name === "University")}
              onChange={(tags: FIXME) => updateEvent({ type: EventActions.UPDATE_TAGS, tags })}
              searchable
              showSelection
              suggestionsType={SuggestionType.University}
              tags={state.tags}
              title="Related Universities"
            />
          </div>
          <div className={classes.field}>
            <Tags
              available={tags.filter(
                (tag: Tag) =>
                  tag?.grandparent?.name === "Interests" && tag?.parent?.name === "Interests"
              )}
              onChange={(tags: FIXME) => updateEvent({ type: EventActions.UPDATE_TAGS, tags })}
              suggestionsType={SuggestionType.Interests}
              tags={state.tags}
              title="Tags"
            />
          </div>
          <div className={classes.field}>
            <RelatedClubs
              clubs={state.clubs}
              onChange={(clubs: FIXME) => updateEvent({ type: EventActions.UPDATE_CLUBS, clubs })}
              user={user}
            />
          </div>
          <div className={classes.field}>
            <Location
              locationId={state.locationId}
              locations={locations}
              onChange={(locationId: string) =>
                updateEvent({ type: EventActions.UPDATE_LOCATION_ID, locationId })
              }
              suggestionsType={SuggestionType.Location}
            />
          </div>
        </div>
      )}
      {selectedTab === 2 && (
        <div className={classes.tabContent}>
          <div className={classes.field}>
            <AttendeesHidden
              isAttendeesHidden={state.isAttendeesHidden}
              onChange={(isAttendeesHidden: boolean) =>
                updateEvent({ type: EventActions.UPDATE_IS_ATTENDEES_HIDDEN, isAttendeesHidden })
              }
            />
          </div>
          <div className={classes.field}>
            <Visibility
              isVisible={state.isVisible}
              onChange={(isVisible: boolean) =>
                updateEvent({ type: EventActions.UPDATE_IS_VISIBLE, isVisible })
              }
              type="events"
            />
          </div>
          <div className={classes.field}>
            <AuthorHidden
              isAuthorHidden={state.isAuthorHidden}
              onChange={(isAuthorHidden: boolean) =>
                updateEvent({ type: EventActions.UPDATE_IS_AUTHOR_HIDDEN, isAuthorHidden })
              }
            />
          </div>
          <div className={classes.field}>
            <Input
              inputmode="numeric"
              label="Maximum RSVPS"
              min="0"
              onChange={(maxRsvps) => {
                updateEvent({ type: EventActions.UPDATE_MAX_RSVPS, maxRsvps });
              }}
              pattern="[0-9]*"
              type="number"
              value={state.maxRsvps}
              width="width-33"
            />
          </div>
          {/* <Privacy
        isPrivate={state.isPrivate}
        onChange={(isPrivate: boolean) =>
          updateEvent({ type: EventActions.UPDATE_IS_PRIVATE, isPrivate })
        }
        type="event"
        verb="go"
      /> */}
        </div>
      )}
    </div>
  );
}
