import { useContext } from "react";
import { useMutation } from "@apollo/client";

import { DELETE_EVENT } from "../../../graphql/events.graphql";
import { AppContext } from "../../../providers/AppProvider";
import { AuthContext } from "../../../providers/AuthProvider";
import { FIXME } from "../../../shared/types";
import { Button } from "../../Forms";

import classes from "./DeleteEvent.module.css";

interface DeleteEventProps {
  event: FIXME;
}

export default function DeleteEvent({ event }: DeleteEventProps) {
  const { userId } = useContext(AuthContext);
  const { navigate } = useContext(AppContext);

  const isAdmin = event.admins.find((admin: FIXME) => admin.userId === userId);

  const [deleting] = useMutation(DELETE_EVENT);

  const handleOnDeleteEvent = async () => {
    const confirmed = confirm(
      `Are you sure you want to delete the event "${event.name}"? This cannot be undone!`
    );

    if (confirmed) {
      await deleting({
        variables: { eventId: event.eventId },
        onCompleted: () => {
          alert(`The event "${event.name}" has been deleted.`);
          navigate("/");
        },
      });
    }
  };

  if (!isAdmin) return null;

  return (
    <div className={classes.wrapper}>
      <Button color="danger" fullWidth onClick={handleOnDeleteEvent}>
        Delete Event
      </Button>
    </div>
  );
}
