import { useContext } from "react";
import { useQuery } from "@apollo/client";

import MembersListRow from "../../components/Clubs/MembersListRow";
import BackTo from "../../components/Shared/BackTo";
import ErrorMessage from "../../components/Shared/ErrorMessage";
import ListView from "../../components/Shared/ListView";
import Loading from "../../components/Shared/Loading";
import { GET_CLUB_MEMBERS } from "../../graphql/clubs.graphql";
import { AuthContext } from "../../providers/AuthProvider";
import { FIXME } from "../../shared/types";

import classes from "./ClubMembersPage.module.css";

type ClubMembersPageProps = {
  clubId: string;
};

export default function ClubMembersPage({ clubId }: ClubMembersPageProps) {
  const { userId } = useContext(AuthContext);

  if (!clubId) throw new Error("You must provide a valid clubId");

  const {
    data: clubData,
    loading,
    error,
    refetch: refetchClub,
  } = useQuery(GET_CLUB_MEMBERS, {
    variables: {
      clubId,
    },
  });

  if (error) return <ErrorMessage message={error.message} />;
  if (!clubData || !clubData?.club || loading) return <Loading />;

  const isAdmin = clubData?.club?.admins?.find((admin: FIXME) => admin.userId === userId);

  const members = clubData.club.members?.map((member) => ({
    ...member,
    clubId: clubData.club.clubId,
    currentUserIsAdmin: isAdmin,
    refetchClub,
  }));

  return (
    <div className={classes.page}>
      <div className={classes.header}>
        <BackTo />
      </div>
      <ListView
        className={classes.results}
        row={MembersListRow}
        items={members}
        itemSize={50 + 6 + 6}
      />
    </div>
  );
}
