import { graphql } from "./__generated__/gql";

export const CREATE_EVENT = graphql(`
  mutation CreateEvent($createEventInput: CreateEventInput!) {
    createEvent(createEventInput: $createEventInput) {
      eventId
      name
      description
      image
      isAuthorHidden
      isAttendeesHidden
      isPrivate
      isVisible
      locationId
      price
      link
      place
      maxRsvps
      startTime
      author {
        userId
        name
        avatar
      }
      attendees {
        userId
        name
        avatar
        username
      }
      clubs {
        clubId
        name
        image
        isPrivate
        members {
          userId
        }
      }
      admins {
        userId
      }
      tags {
        tagId
        name
      }
    }
  }
`);

export const DELETE_EVENT = graphql(`
  mutation DeleteEvent($eventId: ID!) {
    deleteEvent(eventId: $eventId) {
      eventId
    }
  }
`);

export const GET_ALL_EVENTS = graphql(`
  query GetAllEvents {
    events {
      eventId
      name
      description
      image
      price
      link
      place
      startTime
      isAuthorHidden
      isAttendeesHidden
      isPrivate
      isVisible
      attendees {
        userId
        avatar
      }
      clubs {
        clubId
        name
        image
        isPrivate
        members {
          userId
        }
      }
    }
  }
`);

export const GET_EVENT = graphql(`
  query GetEvent($eventId: ID!) {
    event(eventId: $eventId) {
      eventId
      name
      description
      image
      isAuthorHidden
      isAttendeesHidden
      isPrivate
      isVisible
      locationId
      price
      link
      place
      maxRsvps
      startTime
      author {
        userId
        name
        avatar
      }
      attendees {
        userId
        name
        avatar
        username
      }
      clubs {
        clubId
        name
        image
        isPrivate
        members {
          userId
        }
      }
      admins {
        userId
      }
      tags {
        tagId
        name
      }
      createdAt
    }
  }
`);

export const UPDATE_EVENT = graphql(`
  mutation UpdateEvent($updateEventInput: UpdateEventInput!) {
    updateEvent(updateEventInput: $updateEventInput) {
      eventId
      name
      description
      image
      isAuthorHidden
      isAttendeesHidden
      isPrivate
      isVisible
      locationId
      price
      link
      place
      maxRsvps
      startTime
      author {
        userId
        name
        avatar
      }
      attendees {
        userId
        name
        avatar
        username
      }
      clubs {
        clubId
        name
        image
        isPrivate
        members {
          userId
        }
      }
      admins {
        userId
      }
      tags {
        tagId
        name
      }
    }
  }
`);
