import { VisibilityOutlined as VisibilityOutlinedIcon } from "@mui/icons-material";

import { FIXME } from "../../../shared/types";
import Toggle from "../Toggle";

import classes from "./Hidden.module.css";

interface HiddenProps {
  isHidden?: boolean;
  onChange?: FIXME;
}

export default function Hidden({ isHidden, onChange }: HiddenProps) {
  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <VisibilityOutlinedIcon className={classes.icon} />
        <span className={classes.text}>Hidden Profile</span>
      </div>
      <p className={classes.copy}>Hidden profiles do not show up on Near Me or Search.</p>
      <label className={classes.setting}>
        <span className={classes.label}>Hidden</span>
        <Toggle checked={isHidden} onChange={onChange} />
      </label>
    </div>
  );
}
