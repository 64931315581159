export default function ArrowUp() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
      <g clip-path="url(#clip0_2178_11109)">
        <path
          d="M2.66663 8.50033L3.60663 9.44033L7.33329 5.72033V13.8337H8.66663V5.72033L12.3866 9.44699L13.3333 8.50033L7.99996 3.16699L2.66663 8.50033Z"
          fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_2178_11109">
          <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
