import { ReactNode, useState } from "react";
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from "@mui/icons-material";

import { Tag } from "../../../graphql/__generated__/graphql";

import classes from "./ExpandableTagsSection.module.css";

interface ExpandableTagsSectionProps {
  children: ReactNode;
  icon?: ReactNode;
  tags: Tag[];
  title: string;
}

export default function ExpandableTagsSection({
  children,
  icon,
  tags = [],
  title,
}: ExpandableTagsSectionProps) {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className={classes.wrapper}>
      <button className={classes.header} onClick={() => setExpanded((state) => !state)}>
        {icon}
        <span className={classes.text}>{title}</span>
        {expanded ? (
          <KeyboardArrowUpIcon className={classes.arrow} />
        ) : (
          <KeyboardArrowDownIcon className={classes.arrow} />
        )}
      </button>
      {expanded && <div className={classes.content}>{children}</div>}
      {!expanded && tags.length > 0 && (
        <div className={classes.selection}>
          {tags.map((tag) => (
            <span className={classes.tag} key={tag.tagId}>
              {tag.name}
            </span>
          ))}
        </div>
      )}
    </div>
  );
}
