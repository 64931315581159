import SortableList, { SortableItem } from "react-easy-sort";
import { Close as CloseIcon } from "@mui/icons-material";
import { arrayMoveImmutable } from "array-move";
import classNames from "classnames";

import { FIXME } from "../../../shared/types";
import ImageUploader from "../../Forms/ImageUploader";

import classes from "../Onboarding.module.css";

type PhotosType = {
  error: string;
  images: string[];
  onChange: (images: string[]) => void;
};

export default function Photos({ error, images = [], onChange }: PhotosType) {
  const handleOnRemove = (url: string) => {
    const updated = images.filter((image: string) => image !== url);
    onChange(updated);
  };

  const handleOnUploaded = (uploaded: FIXME) => {
    onChange([...images, ...uploaded]);
  };

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    const updated = arrayMoveImmutable(images, oldIndex, newIndex);
    onChange(updated);
  };

  return (
    <div className={classNames(classes.container, classes.photosWrapper)}>
      <div className={classes.intro}>Sign Up</div>
      <div className={classes.title}>Add your photos</div>
      {error && <div className={classes.error}>{error}</div>}
      <SortableList
        onSortEnd={onSortEnd}
        className={classes.photosGrid}
        draggedItemClassName="dragged"
      >
        {Array(6)
          .fill("")
          .map((_, index) => (
            <SortableItem key={`image_upload_${index}_${images?.[index]}`}>
              <div className={classes.photosContainer}>
                {images?.[index] ? (
                  <>  
                    <img className={classes.photosImage} src={images?.[index]} />
                    <button
                      className={classes.photosRemove}
                      onClick={() => handleOnRemove(images?.[index])}
                      type="button"
                    >
                      <span>Remove</span>
                      <CloseIcon />
                    </button>
                    {index === 0 && (
                      <span className={classes.photosMain}>
                        <span>Main photo</span>
                      </span>
                    )}
                  </>
                ) : (
                  <ImageUploader onUploaded={handleOnUploaded} />
                )}
              </div>
            </SortableItem>
          ))}
      </SortableList>
    </div>
  );
}
