import { useEffect, useRef, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { TextareaAutosize } from "@mui/base";
import { MoreVert as MoreVertIcon } from "@mui/icons-material";
import { Popper } from "@mui/material";

import { BLOCK, GET_IS_BLOCKED, UNBLOCK } from "../../../graphql/blocking";
import { GET_USER } from "../../../graphql/users.graphql";
import { FIXME } from "../../../shared/types";
import { Button } from "../../Forms";
import IconButton from "../../Forms/IconButton";

import classes from "./MoreButton.module.css";

interface MoreButtonProps {
  onBlockToggle?: FIXME;
  user: FIXME;
}

export default function MoreButton({ onBlockToggle = () => null, user }: MoreButtonProps) {
  const ref = useRef<FIXME>();

  const [isBlocked, setIsBlocked] = useState(false);

  const [reason, setReason] = useState("");
  const [showingModal, setShowingModal] = useState(false);
  const [showingReportModal, setShowingReportModal] = useState(false);

  const [refreshUser] = useLazyQuery(GET_USER, {
    variables: { userId: user.userId },
  });

  const [block] = useMutation(BLOCK);
  const [unblock] = useMutation(UNBLOCK);

  const handleOnBlock = async () => {
    const confirmed = window.confirm(`Are you sure you want to block ${user.name}?`);

    if (confirmed) {
      setIsBlocked(true);
      await block({
        onCompleted: () => refreshUser(),
        variables: {
          reason,
          userId: user.userId,
        },
      });
      onBlockToggle({
        blocked: true,
        user,
      });
      setReason("");
      setShowingModal(false);
      setShowingReportModal(false);
    }
  };

  const handleOnUnblock = async () => {
    setIsBlocked(false);
    await unblock({
      onCompleted: () => refreshUser(),
      variables: { userId: user.userId },
    });
    onBlockToggle({
      blocked: false,
      user,
    });
  };

  const { data } = useQuery(GET_IS_BLOCKED, {
    variables: { userId: user.userId },
  });

  useEffect(() => {
    setIsBlocked(!!data?.isBlocked);
  }, [data]);

  return (
    <div className={classes.wrapper} ref={ref}>
      <IconButton icon={<MoreVertIcon />} onClick={() => setShowingModal(!showingModal)} />
      {showingReportModal && (
        <div className={classes.overlay}>
          <div className={classes.modal}>
            <h2 className={classes.title}>Report user</h2>
            <TextareaAutosize
              className={classes.textarea}
              onChange={(evt) => {
                const { value } = evt.target;
                setReason(value);
              }}
              placeholder="Reason"
              value={reason}
            />
            <div className={classes.buttons}>
              <Button
                color="secondary"
                onClick={() => {
                  setShowingModal(false);
                  setShowingReportModal(false);
                }}
                size="small"
              >
                Cancel
              </Button>
              <Button onClick={handleOnBlock} size="small">
                Report
              </Button>
            </div>
          </div>
        </div>
      )}
      <Popper anchorEl={ref?.current} open={showingModal}>
        <div className={classes.popup}>
          {isBlocked ? (
            <Button fullWidth onClick={handleOnUnblock} size="small">
              Blocked - unblock
            </Button>
          ) : (
            <>
              <Button onClick={handleOnBlock} size="small">
                Block
              </Button>
              <Button
                onClick={() => {
                  setShowingModal(false);
                  setShowingReportModal(true);
                }}
                size="small"
              >
                Report
              </Button>
            </>
          )}
        </div>
      </Popper>
    </div>
  );
}
