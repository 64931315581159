import { useContext, useState } from "react";
import { CircularProgress } from "@mui/material";

import { NotificationsContext } from "../../../providers/NotificationsProvider";
import { FIXME } from "../../../shared/types";
import { Button } from "../../Forms";
import Link from "../../Shared/Link";

import classes from "./ClubAdminRemovedNotification.module.css";

interface ClubAdminRemovedNotificationProps {
  club: FIXME;
  notificationId: string;
}

export default function ClubAdminRemovedNotification({
  club,
  notificationId,
}: ClubAdminRemovedNotificationProps) {
  const { markNotificationRead } = useContext(NotificationsContext);

  const [loading, setLoading] = useState(false);

  const handleOnOK = () => {
    setLoading(true);
    markNotificationRead(notificationId);
  };

  return (
    <div className={classes.notification}>
      <Link className={classes.text} to={`/clubs/${club.clubId}`}>
        <span>{club.name}</span> has removed you as an admin
      </Link>
      <div className={classes.action}>
        {loading ? (
          <CircularProgress size={30} />
        ) : (
          <Button color="secondary" onClick={handleOnOK} size="small">
            OK
          </Button>
        )}
      </div>
    </div>
  );
}
