import classNames from "classnames";

import { getImageUrl } from "../../../shared/image-helpers";
import { FIXME } from "../../../shared/types";

import classes from "./Avatar.module.css";

interface AvatarPropsWithClub {
  className?: string;
  club: FIXME;
  event?: never;
  size?: 16 | 32 | 46 | 56;
  user?: never;
}

interface AvatarPropsWithEvent {
  className?: string;
  club?: never;
  event: FIXME;
  size?: 16 | 32 | 46 | 56;
  user?: never;
}

interface AvatarPropsWithUser {
  className?: string;
  club?: never;
  event?: never;
  size?: 16 | 32 | 46 | 56;
  user: FIXME;
}

export default function Avatar({
  className,
  club,
  event,
  size = 32,
  user,
}: AvatarPropsWithClub | AvatarPropsWithEvent | AvatarPropsWithUser) {
  const url: FIXME = club?.image || event?.image || user?.avatar;

  return (
    <div
      className={classNames(classes.avatar, className)}
      style={{ height: `${size}px`, width: `${size}px` }}
    >
      {url && (
        <img
          alt={user?.name}
          className={classes.image}
          height={size}
          src={getImageUrl({
            url: url,
            height: size * 2,
            width: size * 2,
          })}
          width={size}
        />
      )}
    </div>
  );
}
