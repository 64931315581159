import { getImageUrl } from "../../../shared/image-helpers";
import { FIXME } from "../../../shared/types";
import Link from "../../Shared/Link";

import classes from "./Card.module.css";

interface CardProps {
  club: FIXME;
}

export default function Card({ club }: CardProps) {
  const membersCount = club.members?.length ?? club.membersCount ?? 0

  return (
    <Link className={classes.wrapper} to={`/clubs/${club.clubId}`}>
      <img
        className={classes.image}
        src={getImageUrl({ url: club.image, height: 230, width: 264 })}
      />
      <div className={classes.content}>
        <p className={classes.privacy}>{club.isPrivate ? "Private" : "Public"} club</p>
        <h2 className={classes.name}>{club.name}</h2>
        <p className={classes.members}>
          {membersCount} member{membersCount === 1 ? "" : "s"}
        </p>
      </div>
    </Link>
  );
}
