import { useContext } from "react";

import { AuthContext } from "../../../providers/AuthProvider";
import { callNativeApp, NativeAppActions } from "../../../shared/native-app-helpers";
import { FIXME } from "../../../shared/types";
import { Button } from "../../Forms";
import JoinButton from "../JoinButton";

import classes from "./Actions.module.css";

const { VITE_SHARE_URL } = import.meta.env;

interface ActionsProps {
  club: FIXME;
}

export default function Actions({ club }: ActionsProps) {
  const { userId } = useContext(AuthContext);

  const handleOnShare = () => {
    callNativeApp({
      action: NativeAppActions.SHARE,
      props: { share: `${VITE_SHARE_URL}/clubs/${club.clubId}` },
    });
  };

  return (
    <div className={classes.wrapper}>
      {club.admins.find((admin: FIXME) => admin.userId === userId) ? (
        <Button color="secondary" fullWidth href={`/edit/club/${club.clubId}`} type="link">
          Edit Club
        </Button>
      ) : (
        <JoinButton club={club} />
      )}
      <Button fullWidth onClick={handleOnShare}>
        Invite friends
      </Button>
    </div>
  );
}
