import { LockSharp as LockSharpIcon } from "@mui/icons-material";

import { FIXME } from "../../../shared/types";
import Toggle from "../Toggle";

import classes from "./ShowAge.module.css";

interface ShowAgeProps {
  isShowAge?: boolean;
  onChange?: FIXME;
}

export default function ShowAge({ isShowAge, onChange }: ShowAgeProps) {
  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <LockSharpIcon className={classes.icon} />
        <span className={classes.text}>Show age</span>
      </div>
      <p className={classes.copy}>Whether or not to show your age on your profile</p>
      <label className={classes.setting}>
        <span className={classes.label}>Show age</span>
        <Toggle checked={isShowAge} onChange={onChange} />
      </label>
    </div>
  );
}
