import { useState } from "react";
import SortableList, { SortableItem } from "react-easy-sort";
import {
  ChevronRight as ChevronRightIcon,
  Close as CloseIcon,
  Image as ImageIcon,
} from "@mui/icons-material";
import { arrayMoveImmutable } from "array-move";

import { FIXME } from "../../../shared/types";
import FormModal from "../FormModal";
import ImageUploader from "../ImageUploader";

import classes from "./Images.module.css";

interface ImagesProps {
  images?: FIXME;
  maximum?: number;
  onChange?: FIXME;
  title?: string;
  headingTitle?: string;
}

export default function Images({
  images = [],
  maximum = 6,
  onChange = () => null,
  title = "Images",
  headingTitle = "Images",
}: ImagesProps) {
  const [showingModal, setShowingModal] = useState(false);

  const handleOnRemove = (url: string) => {
    const updated = images.filter((image: string) => image !== url);
    onChange(updated);
  };

  const handleOnUploaded = (uploaded: FIXME) => {
    onChange([...images, ...uploaded]);
  };

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    const updated = arrayMoveImmutable(images, oldIndex, newIndex);
    onChange(updated);
  };

  if (showingModal) {
    return (
      <FormModal onClose={() => setShowingModal(false)} title={title}>
        <SortableList onSortEnd={onSortEnd} className={classes.grid} draggedItemClassName="dragged">
          {Array(maximum)
            .fill("")
            .map((_, index) => (
              <SortableItem key={`image_upload_${index}_${images?.[index]}`}>
                <div className={classes.container}>
                  {images?.[index] ? (
                    <>
                      <img className={classes.image} src={images?.[index]} />
                      {(maximum === 1 || images.length > 1) && (
                        <button
                          className={classes.remove}
                          onClick={() => handleOnRemove(images?.[index])}
                          type="button"
                        >
                          <span>Remove</span>
                          <CloseIcon />
                        </button>
                      )}
                      {index === 0 && (
                        <span className={classes.main}>
                          <span>Main photo</span>
                        </span>
                      )}
                    </>
                  ) : (
                    <ImageUploader onUploaded={handleOnUploaded} />
                  )}
                </div>
              </SortableItem>
            ))}
        </SortableList>
      </FormModal>
    );
  }

  return (
    <div className={classes.wrapper}>
      <button className={classes.header} onClick={() => setShowingModal(true)} type="button">
        <ImageIcon className={classes.icon} />
        <span className={classes.text}>{headingTitle}</span>
        <ChevronRightIcon className={classes.arrow} />
      </button>
      {images?.length > 0 && (
        <div className={classes.previews}>
          {images.map((image: FIXME) => (
            <div className={classes.preview} key={image}>
              <img src={image} className={classes.previewImage} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
