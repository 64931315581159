import { v4 as uuid } from "uuid";

import { FIXME } from "./types";

export enum NativeAppActions {
  DELETE_USER = "Delete User",
  OPEN_URL = "Open URL",
  SHARE = "Share",
  SIGN_OUT = "Sign Out",

  // TODO: Use these when everyone's on 1.1.0+
  // DELETE_USER = "DELETE_USER",
  // OPEN_URL = "OPEN_URL",
  // SHARE = "SHARE",

  CONSOLE_LOG = "CONSOLE_LOG",
  EVENT_DETAILS = "EVENT_DETAILS",
  GET_ACCESS_TOKEN = "GET_ACCESS_TOKEN",
  GET_ANALYTICS_PERMISSIONS = "GET_ANALYTICS_PERMISSIONS",
  GET_LOCATION = "GET_LOCATION",
  GET_NOTIFICATIONS_PERMISSIONS = "GET_NOTIFICATIONS_PERMISSIONS",
  GET_VERSION = "GET_VERSION",
  LOG_OUT = "LOG_OUT",
  SET_BACKGROUND_VIOLET = "SET_BACKGROUND_VIOLET",
  SET_BACKGROUND_WHITE = "SET_BACKGROUND_WHITE",
  COPY_TO_CLIPBOARD = "COPY_TO_CLIPBOARD",
  START_CHAT = "START_CHAT",
  START_CLUB_CHAT="START_CLUB_CHAT",
  PROFILE_ID="PROFILE_ID"
}

const handlers: Map<string, (payload: any) => void> = new Map();

export const handleNativeCallback = (payload: any) => {
  let payloadData;
  if (typeof payload?.data === "string") {
    payloadData = JSON.parse(payload.data);
  } else {
    payloadData = payload.data;
  }
  let callbackId = payloadData.id;
  if (!callbackId) {
    // If the native App doesn't provide an ID, we get the last inserted key in the map.
    // This is for retro compatibility with older versions of the app. (before 1.2.1)
    callbackId = Array.from(handlers.keys()).pop();
  }
  const callback = handlers.get(callbackId);
  if (!callback || typeof callback !== "function") return;

  handlers.delete(callbackId);

  callback(payload);
};

export const callNativeApp = async ({
  action,
  props = {},
  callback = (payload: FIXME) => payload,
}: {
  action: NativeAppActions;
  props?: FIXME;
  callback?: (payload: FIXME) => void;
}) => {
  // console.log(`Native app event: ${action}, ${JSON.stringify(props)}`);
  const id = uuid();
  const handler = (evt: MessageEvent) => {
    const { data } = evt;
    if (typeof data === "string") {
      const parsed = JSON.parse(data);
      callback(parsed);
    } else {
      callback(data);
    }
  };

  const payload = JSON.stringify({ action, id, ...props });
  handlers.set(id, handler);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.ReactNativeWebView?.postMessage(payload);
};

export const callNativeAppAsync = ({
  action,
  props = {},
}: {
  action: NativeAppActions;
  props?: FIXME;
}): Promise<{ data: any }> => {
  return new Promise((resolve) => {
    const handler = (evt: MessageEvent) => {
      const { data } = evt;
      console.log({ asyncEvt: evt });
      if (typeof data === "string") {
        const parsed = JSON.parse(data);
        resolve(parsed);
      } else {
        resolve(data);
      }
    };

    const id = uuid();
    const payload = JSON.stringify({ action, id, ...props });
    handlers.set(id, handler);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.ReactNativeWebView?.postMessage(payload);
  });
};
