import { useQuery } from "@apollo/client";

import List from "../../components/Clubs/List";
import Actions from "../../components/Events/Actions";
import Attendees from "../../components/Events/Attendees";
import DeleteEvent from "../../components/Events/DeleteEvent";
import Description from "../../components/Events/Description";
import Details from "../../components/Events/Details";
import Header from "../../components/Events/Header";
import JoinChat from "../../components/Events/JoinChat";
import BackTo from "../../components/Shared/BackTo";
import ErrorMessage from "../../components/Shared/ErrorMessage";
import Loading from "../../components/Shared/Loading";
import { GET_EVENT } from "../../graphql/events.graphql";
import { FIXME } from "../../shared/types";

import classes from "./EventPage.module.css";

export default function EventPage({ eventId }: FIXME) {
  if (!eventId) throw new Error("You must provide a valid eventId");

  const {
    data: eventData,
    loading,
    error,
  } = useQuery(GET_EVENT, {
    fetchPolicy: "cache-and-network",
    variables: { eventId },
  });

  if (error) return <ErrorMessage message={error.message} />;
  if (!eventData || !eventData?.event || loading) return <Loading />;

  const hasClubs = eventData.event.clubs?.length && eventData.event.clubs?.length > 0;

  return (
    <div className={classes.page}>
      <BackTo />
      <Header event={eventData.event} />
      <Actions event={eventData.event} />
      <Details event={eventData.event} />
      <Description event={eventData.event} />
      <Attendees event={eventData.event} />
      <JoinChat event={eventData.event} />
      {hasClubs ? <List clubs={eventData.event.clubs} title="Related Clubs" /> : null}
      <DeleteEvent event={eventData.event} />
    </div>
  );
}
