import { VisibilityOutlined as VisibilityOutlinedIcon } from "@mui/icons-material";

import { FIXME } from "../../../shared/types";
import Toggle from "../Toggle";

import classes from "./Visibility.module.css";

interface VisibilityProps {
  isVisible?: boolean;
  onChange?: FIXME;
  type: string;
}

export default function Visibility({ isVisible, onChange, type }: VisibilityProps) {
  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <VisibilityOutlinedIcon className={classes.icon} />
        <span className={classes.text}>Visibility</span>
      </div>
      <p className={classes.copy}>
        Hidden {type} do not show up on Near Me or public profiles. You have to be given a link to
        find it.
      </p>
      <label className={classes.setting}>
        <span className={classes.label}>Visible</span>
        <Toggle checked={isVisible} onChange={onChange} />
      </label>
    </div>
  );
}
