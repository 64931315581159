import { Place as PlaceIcon, WatchLater as WatchLaterIcon } from "@mui/icons-material";

import { callNativeApp, NativeAppActions } from "../../../shared/native-app-helpers";
import { FIXME } from "../../../shared/types";

import classes from "./Details.module.css";

interface DetailsProps {
  event: FIXME;
}

export default function Details({ event }: DetailsProps) {
  const datetime = event.startTime ? new Date(event.startTime) : null;

  const date = datetime ? datetime.toLocaleDateString("en-GB", { timeZone: "UTC" }) : null;
  const time = datetime
    ? datetime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", timeZone: "UTC" })
    : null;

  const handleOnClickLink = (url: string) => {
    callNativeApp({
      action: NativeAppActions.OPEN_URL,
      props: { url },
    });
  };

  return (
    <div className={classes.wrapper}>
      <ul className={classes.list}>
        {event.price && <li className={classes.item}>Ticket price: {event.price}</li>}
        {event.link && (
          <li className={classes.item}>
            <button
              className={classes.link}
              onClick={() => handleOnClickLink(event.link)}
              type="button"
            >
              {event.link}
            </button>
          </li>
        )}
        {date && time && (
          <li className={classes.item}>
            <WatchLaterIcon />
            From {time} | {date}
          </li>
        )}
        {event.place && (
          <li className={classes.item}>
            <PlaceIcon />
            {event.place}
          </li>
        )}
      </ul>
    </div>
  );
}
