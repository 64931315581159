import { gql } from "@apollo/client";

export const GET_MY_CLIQ = gql`
  query GetMyCLIQ {
    myCLIQ {
      clubsJoined {
        clubId
        name
        image
        isPrivate
        members {
          userId
          avatar
        }
      }
      clubsOwned {
        clubId
        name
        image
        isPrivate
        members {
          userId
          avatar
        }
      }
      eventsAttending {
        eventId
        name
        image
        place
        price
        startTime
        isAttendeesHidden
        attendees {
          userId
          avatar
        }
      }
      eventsOwned {
        eventId
        name
        image
        place
        price
        startTime
        isAttendeesHidden
        attendees {
          userId
          avatar
        }
      }
      followers {
        userId
        avatar
        name
        headline
      }
      following {
        userId
        avatar
        name
        headline
      }
    }
  }
`;
