import { useContext } from "react";
import { Close as CloseIcon } from "@mui/icons-material";

import { AppContext } from "../../../providers/AppProvider";

import classes from "./FormHeader.module.css";

interface FormHeaderProps {
  title: string;
}

export default function FormHeader({ title }: FormHeaderProps) {
  const { goBack } = useContext(AppContext);

  return (
    <div className={classes.wrapper}>
      <span className={classes.title}>{title}</span>
      <button className={classes.back} onClick={goBack} type="button">
        <CloseIcon />
      </button>
    </div>
  );
}
