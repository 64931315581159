import classNames from "classnames";

import { FIXME } from "../../../shared/types";

import classes from "./Tabs.module.css";

interface TabsProps {
  className?: string;
  selectedIndex: number;
  tabs: {
    label: string;
    onSelect?: () => void;
    append?: FIXME;
    prepend?: FIXME;
  }[];
}

export default function Tabs({ className, selectedIndex, tabs }: TabsProps) {
  const handleOnSelectTab = (tab: FIXME) => {
    tab.onSelect?.();
  };

  return (
    <div className={classNames(classes.wrapper, className)}>
      <ul className={classes.tabs}>
        {tabs.map((tab: FIXME, index: number) => (
          <li
            className={classNames(classes.tab, selectedIndex === index && classes.selected)}
            key={`${tab}-${index}`}
          >
            <button
              className={classes.button}
              disabled={selectedIndex === index}
              onClick={() => handleOnSelectTab(tab)}
              type="button"
            >
              {tab.append && <span className={classes.append}>{tab.append}</span>}
              <span className={classes.label}>{tab.label}</span>
              {tab.prepend && <span className={classes.prepend}>{tab.prepend}</span>}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}
