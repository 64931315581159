import {
  createContext,
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useMutation, useQuery } from "@apollo/client";

import { UpdateUserInput } from "../graphql/__generated__/graphql";
import { CREATE_USER_NOTIFICATION_TOKEN } from "../graphql/notifications.graphql";
import { ONBOARD_USER } from "../graphql/onboarding.graphql";
import { GET_ONBOARDED } from "../graphql/users.graphql";
import { callNativeApp, NativeAppActions } from "../shared/native-app-helpers";
import { FIXME } from "../shared/types";

import { AuthContext } from "./AuthProvider";
import { LoadingContext } from "./LoadingProvider";

export enum OnboardingStep {
  Course = "Course",
  DateOfBirth = "DateOfBirth",
  Gender = "Gender",
  IsCurrentStudent = "IsCurrentStudent",
  Interests = "Interests",
  Location = "Location",
  Name = "Name",
  Notifications = "Notifications",
  Outro = "Outro",
  Photos = "Photos",
  StudyYear = "StudyYear",
  University = "University",
  Username = "Username",
}

type OnboardingContextType = {
  getNotificationToken: () => void;
  isStudent: boolean;
  onboarded: boolean;
  refetchOnboarded: FIXME;
  saveUser: (updateUserInput: UpdateUserInput) => Promise<string>;
  screen: OnboardingStep;
  setIsStudent: Dispatch<SetStateAction<boolean>>;
  setStep: Dispatch<SetStateAction<number>>;
  step: number;
  totalSteps: number;
};

type OnboardingProviderType = {
  children: ReactNode;
};

export const OnboardingContext = createContext<OnboardingContextType>({
  getNotificationToken: () => null,
  isStudent: true,
  onboarded: true,
  refetchOnboarded: () => null,
  saveUser: () => Promise.resolve(""),
  screen: OnboardingStep.Name,
  setIsStudent: () => null,
  setStep: () => null,
  step: 0,
  totalSteps: 0,
});

export const OnboardingProvider = ({ children }: OnboardingProviderType): ReactElement => {
  const { setLoading } = useContext(LoadingContext);
  const { userId } = useContext(AuthContext);

  const [onboarded, setOnboarded] = useState(false);

  const [saveUserMutation] = useMutation(ONBOARD_USER);
  const [saveUserNotificationsTokenMutation] = useMutation(CREATE_USER_NOTIFICATION_TOKEN);

  const [screens, setScreens] = useState<OnboardingStep[]>([]);
  const [isStudent, setIsStudent] = useState(true);
  const [step, setStep] = useState<number>(1);
  const [totalSteps, setTotalSteps] = useState<10 | 13>(13);

  const getNotificationToken = () => {
    callNativeApp({
      action: NativeAppActions.GET_NOTIFICATIONS_PERMISSIONS,
      callback: (details) => {
        const {
          data: { token },
        } = details;
        saveUserNotificationsTokenMutation({ variables: { token } });
      },
    });
  };

  const saveUser = async (updateUserInput: UpdateUserInput) => {
    try {
      const response = await saveUserMutation({
        variables: {
          updateUserInput: { ...updateUserInput, userId },
        },
      });
      const { errors = [] } = response;
      return errors.length > 0 ? errors.map((err) => err.message).join(", ") : "";
    } catch (err: FIXME) {
      return err.message;
    }
  };

  useEffect(() => {
    if (isStudent) {
      setScreens([
        OnboardingStep.Name,
        OnboardingStep.Username,
        OnboardingStep.DateOfBirth,
        OnboardingStep.Location,
        OnboardingStep.Gender,
        OnboardingStep.Photos,
        OnboardingStep.IsCurrentStudent,
        OnboardingStep.University,
        OnboardingStep.Course,
        OnboardingStep.StudyYear,
        OnboardingStep.Interests,
        OnboardingStep.Notifications,
        OnboardingStep.Outro,
      ]);
      setTotalSteps(13);
    } else {
      setScreens([
        OnboardingStep.Name,
        OnboardingStep.Username,
        OnboardingStep.DateOfBirth,
        OnboardingStep.Location,
        OnboardingStep.Gender,
        OnboardingStep.Photos,
        OnboardingStep.IsCurrentStudent,
        OnboardingStep.Interests,
        OnboardingStep.Notifications,
        OnboardingStep.Outro,
      ]);
      setTotalSteps(10);
    }
  }, [isStudent]);

  const { data, loading, refetch: refetchOnboarded } = useQuery(GET_ONBOARDED);

  useEffect(() => {
    if (data) setOnboarded(data.onboarded);
  }, [data]);

  const value: OnboardingContextType = {
    getNotificationToken,
    isStudent,
    onboarded,
    refetchOnboarded,
    saveUser,
    screen: screens?.[step - 1],
    step,
    setIsStudent,
    setStep,
    totalSteps,
  };

  useEffect(() => {
    setLoading(!data || loading);
  }, [data, loading, setLoading]);

  return <OnboardingContext.Provider value={value}>{children}</OnboardingContext.Provider>;
};
