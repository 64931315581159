import classNames from "classnames";

import classes from "../Onboarding.module.css";

type IsCurrentStudentProps = {
  isStudent: boolean;
  onChange: (isStudent: boolean) => void;
};

export default function IsCurrentStudent({ isStudent, onChange }: IsCurrentStudentProps) {
  return (
    <div className={classes.container}>
      <div className={classes.intro}>Sign Up</div>
      <div className={classes.title}>Are you a student?</div>
      <div className={classes.text}>
        This helps us tailor your app experience and to connect you with like-minded individuals,
        clubs and events.
      </div>
      <div className={classes.isCurrentStudent}>
        <button
          className={classNames(classes.button, isStudent && classes.selected)}
          onClick={() => onChange(true)}
          type="button"
        >
          Yes
        </button>
        <button
          className={classNames(classes.button, !isStudent && classes.selected)}
          onClick={() => onChange(false)}
          type="button"
        >
          No
        </button>
      </div>
    </div>
  );
}
