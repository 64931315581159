import { graphql } from "./__generated__/gql";

export const GET_ALL_LOCATIONS = graphql(`
  query GetAllLocations {
    locations {
      locationId
      name
    }
  }
`);

export const GET_LOCATIONS_BY_LAT_LON = graphql(`
  query GetLocationsByLatLon($latitude: Float!, $longitude: Float!) {
    locationsByLatLon(latitude: $latitude, longitude: $longitude) {
      locationId
      name
    }
  }
`);
