import { useContext, useEffect, useReducer, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";

import { Button } from "../../components/Forms";
import FormHeader from "../../components/Forms/FormHeader";
import EditProfileForm from "../../components/Profiles/EditProfileForm";
import Loading from "../../components/Shared/Loading";
import { GET_ALL_LOCATIONS } from "../../graphql/locations.graphql";
import { GET_ALL_TAGS } from "../../graphql/tags.graphql";
import { GET_USER, UPDATE_USER } from "../../graphql/users.graphql";
import { AppContext } from "../../providers/AppProvider";
import { AuthContext } from "../../providers/AuthProvider";
import { ProfileActions, profileReducer } from "../../reducers/profile.reducer";
import { FIXME } from "../../shared/types";

import classes from "./EditProfilePage.module.css";

export default function EditProfilePage() {
  const { goBack } = useContext(AppContext);
  const { userId } = useContext(AuthContext);

  const [state, updateProfile] = useReducer(profileReducer, {});
  const [error, setError] = useState("");
  const [saving, setSaving] = useState(false);

  const [saveProfile] = useMutation(UPDATE_USER);

  const { data, loading } = useQuery(GET_USER, {
    variables: { userId },
  });
  const { data: locationsData } = useQuery(GET_ALL_LOCATIONS);
  const { data: tagsData } = useQuery(GET_ALL_TAGS);

  useEffect(() => {
    if (data && data.user) {
      updateProfile({ type: ProfileActions.UPDATE_ALL, ...data.user });
    }
  }, [data]);

  const handleSaveProfile = async () => {
    setSaving(true);

    const updateUserInput = { userId, ...state };

    // TODO: This is to exclude `__typename`, which breaks updates
    updateUserInput.links = updateUserInput.links?.map((link: FIXME) => ({
      text: link.text,
      url: link.url,
    }));

    try {
      await saveProfile({
        variables: { updateUserInput },
      });
      goBack();
    } catch (error: FIXME) {
      setSaving(false);
      setError(error.message);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className={classes.page} id="edit-profile-page">
      <FormHeader title="Edit profile" />
      {error && <p className={classes.error}>{error}</p>}
      <div className={classes.form}>
        <EditProfileForm
          locations={locationsData?.locations || []}
          state={state}
          tags={tagsData?.tags || []}
          updateProfile={updateProfile}
        />
      </div>
      <div className={classes.button}>
        <Button disabled={saving} fullWidth onClick={handleSaveProfile}>
          {saving ? <>Saving...</> : <>Save</>}
        </Button>
      </div>
    </div>
  );
}
