import { Autocomplete, TextField } from "@mui/material";

import { FIXME } from "../../../shared/types";

import classes from "../Onboarding.module.css";

type UniversityProps = {
  error: string;
  onChange: (value: string) => void;
  universities: FIXME[];
  universityId: string;
};

export default function University({
  error,
  onChange,
  universities,
  universityId,
}: UniversityProps) {
  return (
    <div className={classes.container}>
      <div className={classes.intro}>Sign Up</div>
      <div className={classes.title}>Enter your university</div>
      {error && <div className={classes.error}>{error}</div>}
      <div className={classes.autocomplete}>
        <Autocomplete
          autoComplete
          clearIcon={null}
          disablePortal
          getOptionLabel={(option) => option.name}
          multiple
          options={universities}
          onChange={(_, universities: FIXME) => {
            const university = universities[universities.length - 1];
            onChange(university?.tagId || "");
          }}
          popupIcon={null}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{ ...params.inputProps, required: !universityId }}
              label=""
            />
          )}
          value={universities.filter((option) => option.tagId === universityId) || []}
        />
      </div>
    </div>
  );
}
