import { PhotoOutlined as PhotoOutlinedIcon } from "@mui/icons-material";

import { getDateTime } from "../../../shared/chat-helpers";
import { getImageUrl } from "../../../shared/image-helpers";
import { FIXME } from "../../../shared/types";
import Avatar from "../../Shared/Avatar";
import Link from "../../Shared/Link";

import classes from "./ChatListItem.module.css";

interface ChatListItemProps {
  data: FIXME;
  index: number;
  style: FIXME;
}

export default function ChatListItem({ index, style, data }: ChatListItemProps) {
  const chat = data[index];

  const message = chat.messages?.[0];
  const participant = chat.participants[0];

  return (
    <Link className={classes.row} style={style} to={`/chats/${chat.chatId}`}>
      <div className={classes.image}>
        {chat.clubId && (
          <img
            alt={chat.image}
            src={getImageUrl({ url: chat.club.image, height: 56, width: 56 })}
          />
        )}
        {chat.eventId && (
          <img
            alt={chat.image}
            src={getImageUrl({ url: chat.event.image, height: 56, width: 56 })}
          />
        )}
        {!chat.clubId && !chat.eventId && (
          <img
            alt={chat.image}
            src={getImageUrl({ url: participant.avatar, height: 56, width: 56 })}
          />
        )}
      </div>
      <div className={classes.row1}>
        {chat.clubId && (
          <>
            <div className={classes.room}>{chat.club.name}</div>
            <div className={classes.name}>{chat.name}</div>
          </>
        )}
        {chat.eventId && (
          <>
            <div className={classes.room}>{chat.event.name}</div>
            <div className={classes.name}>{chat.name}</div>
          </>
        )}
        {!chat.clubId && !chat.eventId && <div className={classes.name}>{participant?.name}</div>}
        <div className={classes.timestamp}>
          {message ? getDateTime(message.createdAt) : getDateTime(chat.createdAt)}
        </div>
      </div>
      <div className={classes.row2}>
        <div className={classes.message}>
          {message?.deletedAt ? (
            <span className={classes.blocked}>Message deleted</span>
          ) : (
            <>
              {message?.author.isBlocked ? (
                <span className={classes.blocked}>A blocked user sent a message</span>
              ) : (
                <>
                  {chat.clubOrEvent && <Avatar size={16} user={message?.author} />}
                  {message?.image && (
                    <span>
                      <PhotoOutlinedIcon /> Image
                    </span>
                  )}
                  {message?.text && <span>{message.text}</span>}
                </>
              )}
            </>
          )}
        </div>
        {chat.unread !== 0 && <div className={classes.unread}>{chat.unread}</div>}
      </div>
    </Link>
  );
}
