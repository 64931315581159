import { FIXME } from "./types";

export const getDateTime = (timestamp: FIXME, sayTodayInsteadOfTime = false): string => {
  const createdAt = new Date(timestamp);

  const inputDate = new Date(timestamp);
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  // Reset the time part for comparison
  inputDate.setHours(0, 0, 0, 0);
  today.setHours(0, 0, 0, 0);
  yesterday.setHours(0, 0, 0, 0);

  if (inputDate.getTime() === today.getTime()) {
    return sayTodayInsteadOfTime
      ? "Today"
      : createdAt.toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
        });
  } else if (inputDate.getTime() === yesterday.getTime()) {
    return "Yesterday";
  } else if (inputDate > new Date(today.setDate(today.getDate() - 7))) {
    const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    return dayNames[inputDate.getDay()];
  } else {
    return inputDate.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  }
};

export const getTime = (message: FIXME): string => {
  const timestamp = message.createdAt;
  const createdAt = new Date(timestamp);

  return createdAt.toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const groupedMessagesByDate = (messages: FIXME): { date: string; messages: FIXME }[] => {
  const groups: { date: string; messages: FIXME }[] = [];

  const dayify = (message: FIXME) => {
    const date = new Date(message.createdAt);
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(
      date.getDate()
    ).padStart(2, "0")}`;
  };

  const uniqueDays = [...new Set(messages.map((message: FIXME) => dayify(message)))];

  for (const uniqueDay of uniqueDays) {
    const group = {
      date: getDateTime(uniqueDay, true),
      messages: messages.filter((message: FIXME) => dayify(message) === uniqueDay),
    };
    groups.push(group);
  }

  return groups;
};

export const linkify = (text: string) => {
  const pattern =
    /http[s]?:\/\/(?:[a-zA-Z]|[0-9]|[$-_@.&+]|[!*\\(\\),]|(?:%[0-9a-fA-F][0-9a-fA-F]))+/g;
  return text.replace(pattern, (url) => `<a href="${url}">${url}</a>`);
};
