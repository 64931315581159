import { useContext, useState } from "react";
import { useQuery } from "@apollo/client";

import Bio from "../../components/Profiles/Bio";
import Clubs from "../../components/Profiles/Clubs";
import Feed from "../../components/Profiles/Feed";
import Header from "../../components/Profiles/Header";
import BackTo from "../../components/Shared/BackTo";
import ErrorMessage from "../../components/Shared/ErrorMessage";
import Loading from "../../components/Shared/Loading";
import Tabs from "../../components/Shared/Tabs";
import { GET_USER } from "../../graphql/users.graphql";
import { AuthContext } from "../../providers/AuthProvider";
import { FIXME } from "../../shared/types";

import classes from "./ProfilePage.module.css";

export default function ProfilePage({ defaultTab = 0, profileId }: FIXME) {
  const { userId: loggedInUserId } = useContext(AuthContext);

  const userId = profileId ?? loggedInUserId;

  const [selectedTab, setSelectedTab] = useState(defaultTab);
  const tabs = [
    { label: "Feed", link: `/profile/${userId}`, onSelect: () => setSelectedTab(0) },
    { label: "Bio", link: `/profile/${userId}/bio`, onSelect: () => setSelectedTab(1) },
    { label: "Clubs", link: `/profile/${userId}/clubs`, onSelect: () => setSelectedTab(2) },
  ];

  const {
    data: userData,
    loading,
    error,
  } = useQuery(GET_USER, {
    fetchPolicy: "cache-and-network",
    variables: { userId },
  });

  if (error) return <ErrorMessage message={error.message} />;
  if (!userData || loading) return <Loading />;

  return (
    <div className={classes.page} id="profile-page">
      <BackTo className={classes.back} />
      <Header user={userData?.user} />
      <Tabs className={classes.tabs} selectedIndex={selectedTab} tabs={tabs} />
      {selectedTab === 0 && <Feed user={userData?.user} />}
      {selectedTab === 1 && <Bio user={userData?.user} />}
      {selectedTab === 2 && <Clubs user={userData?.user} />}
    </div>
  );
}
