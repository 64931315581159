import { useEffect, useRef } from "react";

import { callNativeApp, NativeAppActions } from "../shared/native-app-helpers";
import { FIXME } from "../shared/types";

export default function useLinkifiedText() {
  const ref = useRef<FIXME>();

  useEffect(() => {
    const links = ref.current?.querySelectorAll("a") || [];
    const handler = (evt: FIXME) => {
      evt.preventDefault();
      callNativeApp({
        action: NativeAppActions.OPEN_URL,
        props: { url: evt.target.href },
      });
    };
    for (const link of links) {
      link.removeEventListener("click", handler);
      link.addEventListener("click", handler);
    }
  }, [ref]);

  return ref;
}
