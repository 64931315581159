import { useState } from "react";
import {
  ChevronRight as ChevronRightIcon,
  LocationOn as LocationOnIcon,
} from "@mui/icons-material";
import classNames from "classnames";

import { Location } from "../../../graphql/__generated__/graphql";
import { useSuggestions } from "../../../hooks/useSuggestions";
import { SuggestionType } from "../../../shared/suggestions-helpers";
import { FIXME } from "../../../shared/types";
import FormModal from "../FormModal";
import Input from "../Input";

import classes from "./Location.module.css";

interface LocationProps {
  locationId?: string;
  locations?: Location[];
  onChange?: FIXME;
  suggestionsType?: SuggestionType;
}

export default function LocationComponent({
  locationId,
  locations = [],
  onChange = () => null,
  suggestionsType,
}: LocationProps) {
  const [showingModal, setShowingModal] = useState(false);
  const { suggestions, addSuggestion } = useSuggestions(suggestionsType);

  const [search, setSearch] = useState("");
  const filter = (location: Location) => location.name.toLowerCase().includes(search.toLowerCase());

  const handleOnSelect = (location: Location) => {
    onChange(location.locationId);
    if (suggestionsType) addSuggestion(location);
    setShowingModal(false);
    setSearch(location.name);
  };

  if (showingModal) {
    return (
      <FormModal onClose={() => setShowingModal(false)} title="Location">
        <div className={classes.search}>
          <Input
            hideLabel
            label="Search"
            onChange={setSearch}
            placeholder="Search"
            value={search}
          />
        </div>
        <div className={classes.list}>
          {locations.filter(filter).map((location) => (
            <button
              className={classNames(
                classes.location,
                location.locationId === locationId && classes.selected
              )}
              key={location.locationId}
              onClick={() => handleOnSelect(location)}
              type="button"
            >
              {location.name}
            </button>
          ))}
        </div>
      </FormModal>
    );
  }

  return (
    <div className={classes.wrapper}>
      <button className={classes.header} onClick={() => setShowingModal(true)} type="button">
        <LocationOnIcon className={classes.icon} />
        <span className={classes.text}>Location</span>
        <ChevronRightIcon className={classes.arrow} />
      </button>
      {!locationId && suggestions.length > 0 && (
        <div className={classes.suggestions}>
          <span className={classes.label}>Suggested: </span>
          {suggestions.map((location: FIXME) => (
            <button
              className={classNames(
                classes.location,
                location.locationId === locationId && classes.selected
              )}
              key={location.locationId}
              onClick={() => handleOnSelect(location)}
              type="button"
            >
              {location.name}
            </button>
          ))}
        </div>
      )}
      {locationId && (
        <div className={classes.selection}>
          <span className={classNames(classes.location, classes.selected)}>
            {locations.find((location) => location.locationId === locationId)?.name}
          </span>
        </div>
      )}
    </div>
  );
}
