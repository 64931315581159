import { useContext, useState } from "react";
import { CircularProgress } from "@mui/material";

import { NotificationsContext } from "../../../providers/NotificationsProvider";
import { FIXME } from "../../../shared/types";
import { Button } from "../../Forms";
import Link from "../../Shared/Link";

import classes from "./EventAddedNotification.module.css";

interface EventAddedNotificationProps {
  club: FIXME;
  event: FIXME;
  notificationId: string;
}

export default function EventAddedNotification({
  club,
  event,
  notificationId,
}: EventAddedNotificationProps) {
  const { markNotificationRead } = useContext(NotificationsContext);

  const [loading, setLoading] = useState(false);

  const handleOnOK = () => {
    setLoading(true);
    markNotificationRead(notificationId);
  };

  return (
    <div className={classes.notification}>
      <Link className={classes.text} to={`/events/${event.eventId}`}>
        <span>{club.name}</span> has added a new event <span>{event.name}</span>!
      </Link>
      <div className={classes.action}>
        {loading ? (
          <CircularProgress size={30} />
        ) : (
          <Button color="secondary" onClick={handleOnOK} size="small">
            OK
          </Button>
        )}
      </div>
    </div>
  );
}
