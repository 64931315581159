import { SentimentVeryDissatisfied as SentimentVeryDissatisfiedIcon } from "@mui/icons-material";

import classes from "./OldOS.module.css";

export default function OldOS() {
  return (
    <div className={classes.error}>
      <div>
        <SentimentVeryDissatisfiedIcon />
        <h1>We're sorry - your OS needs to be upgraded</h1>
        <p>
          CLIQ uses features that aren't available in your current OS version. Please update to
          latest and try again
        </p>
      </div>
    </div>
  );
}
