import { ReactNode } from "react";
import classNames from "classnames";

import { FIXME } from "../../../shared/types";
import Link from "../../Shared/Link";

import classes from "./Button.module.css";

interface ButtonProps {
  children: ReactNode;
  className?: string;
  color?: "danger" | "link" | "primary" | "secondary" | "none";
  disabled?: boolean;
  fullWidth?: boolean;
  href?: string;
  onClick?: FIXME;
  size?: "small" | "normal";
  type?: "button" | "link" | "submit";
}

export default function Button({
  children,
  className,
  color = "primary",
  disabled = false,
  fullWidth = false,
  href = "",
  onClick,
  size = "normal",
  type = "button",
  ...props
}: ButtonProps) {
  return (
    <>
      {type === "link" && (
        <Link
          to={href}
          className={classNames(
            classes.button,
            color === "danger" && classes.danger,
            color === "link" && classes.link,
            color === "primary" && classes.primary,
            color === "secondary" && classes.secondary,
            color === "none" && classes.none,
            fullWidth && classes.fullWidth,
            size === "small" && classes.small,
            size === "normal" && classes.normal,
            className
          )}
          onClick={onClick}
          {...props}
        >
          <span>{children}</span>
        </Link>
      )}
      {type !== "link" && (
        <button
          className={classNames(
            classes.button,
            color === "danger" && classes.danger,
            color === "link" && classes.link,
            color === "primary" && classes.primary,
            color === "secondary" && classes.secondary,
            color === "none" && classes.none,
            fullWidth && classes.fullWidth,
            size === "small" && classes.small,
            size === "normal" && classes.normal,
            className
          )}
          disabled={disabled}
          onClick={onClick}
          type={type}
          {...props}
        >
          <span>{children}</span>
        </button>
      )}
    </>
  );
}
