import { useContext } from "react";

import { AuthContext } from "../../../providers/AuthProvider";
import { callNativeApp, NativeAppActions } from "../../../shared/native-app-helpers";
import { FIXME } from "../../../shared/types";
import { Button } from "../../Forms";
import AttendingButton from "../AttendingButton";

import classes from "./Actions.module.css";

const { VITE_SHARE_URL } = import.meta.env;

interface ActionsProps {
  event: FIXME;
}

export default function Actions({ event }: ActionsProps) {
  const { userId } = useContext(AuthContext);

  const handleOnShare = () => {
    callNativeApp({
      action: NativeAppActions.SHARE,
      props: { share: `${VITE_SHARE_URL}/events/${event.eventId}` },
    });
  };

  return (
    <div className={classes.wrapper}>
      {event.admins.find((admin: FIXME) => admin.userId === userId) ? (
        <Button color="secondary" fullWidth href={`/edit/event/${event.eventId}`} type="link">
          Edit Event
        </Button>
      ) : (
        <AttendingButton event={event} />
      )}
      <Button fullWidth onClick={handleOnShare}>
        Invite friends
      </Button>
    </div>
  );
}
