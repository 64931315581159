import { createContext, useContext, useEffect, useState } from "react";
import jwt_decode from "jwt-decode";

import { log } from "../shared/log-helpers";
import {
  callNativeApp,
  handleNativeCallback,
  NativeAppActions,
} from "../shared/native-app-helpers";
import { FIXME } from "../shared/types";

import { LoadingContext } from "./LoadingProvider";

type AuthContextType = {
  token: string;
  userId: string;
};

export const AuthContext = createContext<AuthContextType>({
  token: "",
  userId: "",
});

export const AuthProvider = ({ children }: FIXME) => {
  const { setLoading } = useContext(LoadingContext);

  const [token, setToken] = useState("");
  const [tokenLoad, settokenLoad] = useState(false);
  const [userId, setUserId] = useState("");

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const accessToken = urlParams.get("accessToken") || "";

    if (accessToken == "null" || accessToken == "") {
      log("No access token found in URL");
      callNativeApp({ action: NativeAppActions.GET_ACCESS_TOKEN });
    }

    if (accessToken) {
      log("Access token found in URL" + accessToken);
      setToken(accessToken);
    }

    if (accessToken) {
      const { user_id }: FIXME = jwt_decode(accessToken);
      // alert("user_id: "+user_id)
      setUserId(user_id);
    }
    settokenLoad(true);
  }, []);
  useEffect(() => {
    window.addEventListener("message", handleNativeCallback);

    document.addEventListener("message", handleNativeCallback);
    return () => {
      window.removeEventListener("message", handleNativeCallback);
      document.removeEventListener("message", handleNativeCallback);
    };
  }, []);

  const handleOnUpdateToken = (evt: FIXME) => {
    try {
      const payload = JSON.parse(evt.data);
      const { action, data } = payload;
      if (action === NativeAppActions.GET_ACCESS_TOKEN) {
        log(`Recieved and stored token: ${data.token}`);
        setToken(data.token);
      }
    } catch {
      //
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleOnUpdateToken);
    return () => {
      window.removeEventListener("message", handleOnUpdateToken);
    };
  }, []);

  useEffect(() => {
    log(token);
    if (tokenLoad) {
      if (!token) callNativeApp({ action: NativeAppActions.GET_ACCESS_TOKEN });
      if (token) {
        try {
          const { exp }: { exp: number } = jwt_decode(token);
          const expires = new Date(exp * 1000);
          if (expires < new Date()) {
            callNativeApp({ action: NativeAppActions.GET_ACCESS_TOKEN });
          }
        } catch {
          callNativeApp({ action: NativeAppActions.GET_ACCESS_TOKEN });
        }
      }
    }
  }, [token]);

  useEffect(() => {
    let timeoutId: number;

    if (token) {
      try {
        const { exp }: { exp: number } = jwt_decode(token);
        const expirationTime = new Date(exp * 1000);
        const currentTime = new Date();

        log(`Token expires: ${expirationTime}`);

        const timeUntilExpiration = expirationTime.getTime() - currentTime.getTime();
        const callbackTime = timeUntilExpiration - 30000; // 30 seconds before

        log(`Refreshing token in ${callbackTime}ms`);

        timeoutId = window.setTimeout(() => {
          log("Refreshing access token");
          callNativeApp({ action: NativeAppActions.GET_ACCESS_TOKEN });
        }, callbackTime);
      } catch {
        //
      }
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [token]);

  useEffect(() => {
    setLoading(!token);
  }, [setLoading, token]);

  const value: AuthContextType = {
    token,
    userId,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
