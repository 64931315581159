import { graphql } from "./__generated__/gql";

export const GET_SEARCH = graphql(`
  query Search($query: String!, $ageFrom: Int, $ageTo: Int, $locationId: ID) {
    search(query: $query, ageFrom: $ageFrom, ageTo: $ageTo, locationId: $locationId) {
      connects {
        userId
        name
        username
        avatar
      }
      clubs {
        clubId
        name
        image
        isPrivate
        members {
          userId
          avatar
        }
      }
      events {
        eventId
        name
        image
        place
        price
        isAttendeesHidden
        startTime
        attendees {
          userId
          avatar
        }
      }
    }
  }
`);
