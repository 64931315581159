import { useContext, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";

import ChatList from "../../components/Chats/ChatList";
import ChatsHeader from "../../components/Chats/ChatsHeader";
import { GET_CLUB } from "../../graphql/clubs.graphql";
import { GET_EVENT } from "../../graphql/events.graphql";

import { ChatsContext } from "../../providers/ChatsProvider";
import Loading from "../../components/Shared/Loading";

import classes from "./ChatsPage.module.css";

type ChatsPageProps = {
  clubId: string;
  eventId: string;
};

export default function ChatsPage({ clubId, eventId }: ChatsPageProps) {
  const { data: clubData } = useQuery(GET_CLUB, {
    skip: !clubId,
    variables: { clubId },
  });
  const { data: eventData } = useQuery(GET_EVENT, {
    skip: !eventId,
    variables: { eventId },
  });

  const [loaded, setLoaded] = useState(false);
  const { updateChats, relevantDataUpdate } = useContext(ChatsContext);

  // Load chats once on load
  useEffect(() => {
    async function getChats() {
      setLoaded(true);
      if (clubId || eventId) {
        await relevantDataUpdate({ clubId, eventId });
      } else {
        await updateChats();
      }
      setLoaded(false);
    }
    getChats();
  }, []);

  return (
    <>
      {loaded && (
        <div style={{ height: "100dvh" }}>
          <Loading />
        </div>
      )}

      <div className={classes.page} id="chats-page">
        <div className={classes.header}>
          <ChatsHeader club={clubData?.club} event={eventData?.event} />
        </div>
        <div className={classes.content}>
          <ChatList clubId={clubId} eventId={eventId} />
        </div>
      </div>
    </>
  );
}
