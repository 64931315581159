import { useContext, useState } from "react";
import { useMutation } from "@apollo/client";

import { REMOVE_CLUB_MEMBER } from "../../../graphql/clubs.graphql";
import { AuthContext } from "../../../providers/AuthProvider";
import { FIXME } from "../../../shared/types";
import { Button } from "../../Forms";
import Avatar from "../../Shared/Avatar";
import classes from "./MembersListRow.module.css";
import { NativeAppActions, callNativeAppAsync } from "../../../shared/native-app-helpers";

interface MembersListRowProps {
  data: FIXME;
  index: number;
  style: FIXME;
}

export default function MembersListRow({ index, style, data }: MembersListRowProps) {
  const { clubId, currentUserIsAdmin, refetchClub, ...user } = data[index];
  const [loading, setLoading] = useState(false);
  const { userId } = useContext(AuthContext);

  const [removeClubMember] = useMutation(REMOVE_CLUB_MEMBER);

  const handleOnRemoveMember = async (userId: string) => {
    const confirmed = window.confirm(
      `Are you sure you want to remove ${user.name} from this club?`
    );
    if (confirmed) {
      setLoading(true);
      await removeClubMember({ variables: { clubId, userId } });
      await refetchClub();
    }
  };

  const handleProfile = (userId: any) => {
    callNativeAppAsync({
      action: NativeAppActions.PROFILE_ID,
      props: { userId: userId },
    });
  };

  return (
    <div className={classes.row} style={style}>
      <button className={classes.link} onClick={() => handleProfile(user?.userId)}>
        <div className={classes.avatar}>
          <Avatar size={46} user={user} />
        </div>
        <div className={classes.name}>{user.name}</div>
        <div className={classes.username}>{user.username}</div>
      </button>
      {currentUserIsAdmin && user.userId !== userId && (
        <div className={classes.action}>
          <Button onClick={() => !loading && handleOnRemoveMember(user.userId)} size="small">
            Remove
          </Button>
        </div>
      )}
    </div>
  );
}
