import { graphql } from "./__generated__/gql";

export const ATTENDING = graphql(`
  mutation Attending($eventId: ID!) {
    attending(eventId: $eventId) {
      eventId
      attendees {
        userId
        name
        avatar
        username
      }
    }
  }
`);

export const GET_IS_ATTENDING = graphql(`
  query GetIsAttending($eventId: ID!) {
    isAttending(eventId: $eventId)
  }
`);

export const UNATTENDING = graphql(`
  mutation Unattending($eventId: ID!) {
    unattending(eventId: $eventId) {
      eventId
      attendees {
        userId
        name
        avatar
        username
      }
    }
  }
`);
