import { useState } from "react";
import {
  ChevronRight as ChevronRightIcon,
  Close as CloseIcon,
  Link as LinkIcon,
} from "@mui/icons-material";

import { FIXME } from "../../../shared/types";
import Button from "../Button";
import FormModal from "../FormModal";
import Input from "../Input";

import classes from "./Links.module.css";

interface LinksProps {
  links?: string[];
  onChange?: FIXME;
}

export default function Links({ links = [], onChange = () => null }: LinksProps) {
  const [showingModal, setShowingModal] = useState(false);

  const [text, setText] = useState("");
  const [url, setUrl] = useState("");

  const handleOnRemove = (remove: FIXME) => {
    const updated = links.filter((link: FIXME) => link !== remove);
    onChange(updated);
  };

  const handleOnSubmit = (evt: FIXME) => {
    evt.preventDefault();

    const updated = [...links, { text, url }];

    setText("");
    setUrl("");

    onChange(updated);
    setShowingModal(false);
  };

  if (showingModal) {
    return (
      <FormModal onClose={() => setShowingModal(false)} title="Links">
        <form className={classes.form} onSubmit={handleOnSubmit}>
          <Input label="URL" onChange={setUrl} required type="url" value={url} />
          <Input label="Name" onChange={setText} required value={text} />
          <br />
          <Button color="secondary" size="small" type="submit">
            Add link
          </Button>
          <hr className={classes.divider} />
          {links.length > 0 && (
            <div className={classes.selection}>
              {links.map((link: FIXME) => (
                <span className={classes.link} key={`${link.url}-${link.text}`}>
                  {link.text}
                  <button
                    className={classes.remove}
                    onClick={() => handleOnRemove(link)}
                    type="button"
                  >
                    <span>remove link</span>
                    <CloseIcon />
                  </button>
                </span>
              ))}
            </div>
          )}
        </form>
      </FormModal>
    );
  }

  return (
    <div className={classes.wrapper}>
      <button className={classes.header} onClick={() => setShowingModal(true)} type="button">
        <LinkIcon className={classes.icon} />
        <span className={classes.text}>Links</span>
        <ChevronRightIcon className={classes.arrow} />
      </button>
      {links.length > 0 && (
        <div className={classes.selection}>
          {links.map((link: FIXME) => (
            <span className={classes.link} key={`${link.url}-${link.text}`}>
              <LinkIcon />
              {link.text}
            </span>
          ))}
        </div>
      )}
    </div>
  );
}
