import { createContext, useContext, useEffect, useState } from "react";
import Pusher, { Channel } from "pusher-js";
import { default as PusherType } from "pusher-js/types/src/core/pusher";

import Loading from "../components/Shared/Loading";
import { PusherEvents } from "../shared/pusher-events";
import { FIXME } from "../shared/types";

import { AuthContext } from "./AuthProvider";

const { VITE_PUSHER_CLUSTER, VITE_PUSHER_KEY } = import.meta.env;
// Pusher.logToConsole = true;
const pusher = new Pusher(VITE_PUSHER_KEY, {
  cluster: VITE_PUSHER_CLUSTER,
});

export type PusherCustomEvent = {
  chatId: string;
  payload: FIXME;
  type: PusherEvents;
};

type PusherContextType = {
  pusher: PusherType;
  updateChatIds: (updatedChatIds: string[]) => void;
  unsubscribeFromAll: () => void;
};

export const PusherContext = createContext<PusherContextType>({
  pusher,
  updateChatIds: () => null,
  unsubscribeFromAll: () => null,
});

export const PusherProvider = ({ children }: FIXME) => {
  const { userId } = useContext(AuthContext);

  const [chatIds, setChatIds] = useState<string[]>([]);
  const [channels, setChannels] = useState<{ name: string; instance: Channel }[]>([]);

  const updateChatIds = (updatedChatIds: string[]) => {
    if (JSON.stringify(chatIds) === JSON.stringify(updatedChatIds)) return;
    else setChatIds(updatedChatIds);
  };

  useEffect(() => {
    if (!userId) return;

    const userChannel = pusher.subscribe(`user-${userId}`);

    setChannels([...channels, { name: "user", instance: userChannel }]);

    userChannel.bind(PusherEvents.CHAT_UPDATE_ALL, (payload: FIXME) => {
      const event = new CustomEvent("chat-event", {
        detail: { ...payload, type: PusherEvents.CHAT_UPDATE_ALL } as PusherCustomEvent,
      });
      window.dispatchEvent(event);
    });

    return () => {
      userChannel.unbind_all();
      userChannel.unsubscribe();
    };
  }, [userId]);

  useEffect(() => {
    const list: { name: string; instance: FIXME }[] = [];

    for (const chatId of chatIds) {
      const channel = pusher.subscribe(`chat-${chatId}`);

      channel.bind(PusherEvents.CHAT_NEW_MESSAGE, (payload: FIXME) => {
        const event = new CustomEvent("chat-event", {
          detail: { ...payload, type: PusherEvents.CHAT_NEW_MESSAGE } as PusherCustomEvent,
        });
        window.dispatchEvent(event);
      });

      channel.bind(PusherEvents.CHAT_USER_IS_TYPING, (payload: FIXME) => {
        const event = new CustomEvent("chat-event", {
          detail: { ...payload, type: PusherEvents.CHAT_USER_IS_TYPING } as PusherCustomEvent,
        });
        window.dispatchEvent(event);
      });

      list.push({ name: chatId, instance: channel });
    }

    setChannels([...channels, ...list]);

    return () => {
      for (const channel of list) {
        channel.instance.unbind_all();
        channel.instance.unsubscribe();
      }
    };
  }, [chatIds]);

  const unsubscribeFromAll = () => {
    channels.forEach((channel) => {
      channel.instance.unbind_all();
      channel.instance.unsubscribe();
    });
    setChannels([]);
  };

  const value: PusherContextType = {
    pusher,
    updateChatIds,
    unsubscribeFromAll,
  };

  if (!pusher) {
    return <Loading />;
  }

  return <PusherContext.Provider value={value}>{children}</PusherContext.Provider>;
};
