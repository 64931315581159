import classNames from "classnames";

import { Tag } from "../../../graphql/__generated__/graphql";
import { isTagInList } from "../../../shared/tag-helpers";

import classes from "./TagsList.module.css";

interface TagsListProps {
  onToggle?: (tag: Tag) => void;
  selectable?: boolean;
  selected?: Tag[];
  tags: Tag[];
}

export default function TagsList({
  onToggle = () => null,
  selectable = false,
  selected = [],
  tags,
}: TagsListProps) {
  return (
    <div className={classNames(classes.tags)}>
      <ul className={classes.list}>
        {tags.map((tag: Tag, index: number) => (
          <li className={classes.item} key={`${tag.name}-${index}`}>
            {selectable ? (
              <button
                className={classNames(
                  classes.tag,
                  isTagInList({ selection: selected, tag }) && classes.selected
                )}
                onClick={() => onToggle(tag)}
                type="button"
              >
                {tag.name}
              </button>
            ) : (
              <span className={classNames(classes.tag, classes.selected)}>{tag.name}</span>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}
