import ToggleComponent from "react-toggle";

import { FIXME } from "../../../shared/types";

import "./Toggle.css";

export default function Toggle({ checked, onChange }: FIXME) {
  const handleOnChange = (evt: FIXME) => {
    const { checked } = evt.target;
    onChange(checked);
  };

  return <ToggleComponent checked={checked} icons={false} onChange={handleOnChange} />;
}
