import { CircularProgress } from "@mui/material";

import classes from "./Loading.module.css";

export default function Loading() {
  return (
    <div className={classes.loading}>
      <CircularProgress size={80} />
    </div>
  );
}
