import takeRight from "lodash.takeright";
import uniqBy from "lodash.uniqby";

import { FIXME } from "./types";

const SUGGESTION_RECORD_KEY = "suggestions";

export enum SuggestionType {
  Interests = "Interests",
  Location = "Location",
  University = "University",
}

const DEFAULT_SUGGESTION_RECORD = {
  [SuggestionType.Interests]: [],
  [SuggestionType.Location]: [],
  [SuggestionType.University]: [],
};

const get = (type: SuggestionType) => {
  try {
    const record =
      window.localStorage.getItem(SUGGESTION_RECORD_KEY) ||
      JSON.stringify(DEFAULT_SUGGESTION_RECORD);

    const all = JSON.parse(record);
    const suggestions = all[type] || [];
    suggestions.reverse();
    return suggestions;
  } catch {
    return null;
  }
};

const add = (type: SuggestionType, value: FIXME) => {
  try {
    const record =
      window.localStorage.getItem(SUGGESTION_RECORD_KEY) ||
      JSON.stringify(DEFAULT_SUGGESTION_RECORD);

    const suggestions = JSON.parse(record);

    const records = [...(suggestions[type] || []), value];

    let deduped;
    if (records[0].locationId) deduped = uniqBy(records, "locationId");
    else if (records[0].tagId) deduped = uniqBy(records, "tagId");
    else deduped = records;

    const last3 = takeRight(deduped, 3);
    suggestions[type] = last3;

    window.localStorage.setItem(SUGGESTION_RECORD_KEY, JSON.stringify(suggestions));
  } catch {
    return null;
  }
};

export default {
  add,
  get,
};
