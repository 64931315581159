import { Fragment } from "react";
import { Place as PlaceIcon } from "@mui/icons-material";
import classNames from "classnames";

import { getImageUrl } from "../../../shared/image-helpers.ts";
import Avatar from "../../Shared/Avatar";
import ChevronRightIcon from "../../Shared/Icons/ChevronRight.tsx";

import classes from "./Card.module.css";

export interface LoadMoreCardProps {
  backgroundImageUrl?: string
  text?: string
  clickAction?: ()=> void
  condensed?: boolean
}
export default function LoadMoreEventsCard({backgroundImageUrl, condensed = false, text, clickAction}: LoadMoreCardProps) {
  const namePlaceholder = "name Placehoder that is long enough"
  const placeholderImageUrl = "https://picsum.photos/200/300/?blur=2"
  const avatarPlaceHolder = "https://i.pravatar.cc/16"

  const imageUrl = backgroundImageUrl ? getImageUrl({ url: backgroundImageUrl, height: condensed ? 238 : 236, width: condensed ? 280 : 244 }) : placeholderImageUrl

  const onClickAction = () =>{
    clickAction && clickAction()
  }

  return (<>
    <div className={classNames(classes.wrapper, condensed && classes.condensed)} onClick={onClickAction}>
      <img
        className={classes.image}
        src={imageUrl}
      />
      <div className={classes.content}>
        <div className={classes.name}>{namePlaceholder}</div>
        <div className={classes.price}>£30.00</div>
        <div className={classes.where}>
              <PlaceIcon /> London
        </div>
        <div className={classes.who}>
          <ul className={classes.attendees}>
            {Array(3)
              .fill(null)
              .map((_, index) => (
                <Fragment key={index}>
                    <li
                      className={classes.avatar}
                      key={`event-${index}-member`}
                    >
                      <Avatar size={16} user={{avatar: avatarPlaceHolder, name: 'placeholder'}} />
                    </li>
                </Fragment>
              ))}
          </ul>
          <p className={classes.count}>3 attending</p>
        </div>
      </div>
      <div className={classes.overlay}>
        <ChevronRightIcon />
        <p>
          {text ?? "Load More"}
        </p>
      </div>
    </div>
  </>)
}