import { useEffect, useRef, useState } from "react";
import classNames from "classnames";

import { FIXME } from "../../../shared/types";
import Avatar from "../../Shared/Avatar";
import { NativeAppActions, callNativeAppAsync } from "../../../shared/native-app-helpers";

import classes from "./Description.module.css";

interface DescriptionProps {
  club: FIXME;
}

export default function Description({ club }: DescriptionProps) {
  const [showingAll, setShowingAll] = useState(false);
  const [hasShowMore, setHasShowMore] = useState(false);
  const containerRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      if (containerRef.current.scrollHeight > containerRef.current.clientHeight) {
        setHasShowMore(true);
      }
    }
  }, []);

  const toggleShowingAll = () => {
    setHasShowMore(false);
    setShowingAll(true);
  };

  const handleProfile = (userId: any) => {
    callNativeAppAsync({
      action: NativeAppActions.PROFILE_ID,
      props: { userId: userId },
    });
  };

  return (
    <div className={classes.wrapper}>
      {!club.isAuthorHidden && (
        <>
          <button className={classes.avatar} onClick={() => handleProfile(club.author?.userId)}>
            <Avatar size={46} user={club.author} />
          </button>
          <h3 className={classes.author}>
            <button
              className={classes.clubAuthorBtn}
              onClick={() => handleProfile(club.author?.userId)}
            >
              {club.author.name}
            </button>
          </h3>
          <p className={classes.subtitle}>Club founder</p>
        </>
      )}
      <p
        className={classNames(classes.description, showingAll && classes.showingAll)}
        ref={containerRef}
      >
        {club.description}
        {hasShowMore && (
          <button className={classes.showMoreButton} onClick={toggleShowingAll}>
            Show more
          </button>
        )}
      </p>
    </div>
  );
}
