import { getImageUrl } from "../../../shared/image-helpers.ts";
import ChevronRightIcon from "../../Shared/Icons/ChevronRight.tsx";

import classes from "./Card.module.css";

export interface LoadMoreCardProps {
  backgroundImageUrl?: string
  text?: string
  clickAction?: ()=> void
}
export default function LoadMoreConnectsCard({backgroundImageUrl="https://picsum.photos/200/300/?blur=2", text, clickAction}: LoadMoreCardProps) {

  const onClickAction = () =>{
    clickAction && clickAction()
  }

  return (<>
    <div className={classes.wrapper} onClick={onClickAction}>
      <img
        className={classes.image}
        height={115}
        src={getImageUrl({ url: backgroundImageUrl, height: 230, width: 264 })}
        width={132}
      />
      <div className={classes.content}>
        <div className={classes.name}>User FullName</div>
        <div className={classes.username}>User Name</div>
      </div>
      <div className={classes.overlay}>
        <ChevronRightIcon  />
        <p>
          {text ?? "Load More"}
        </p>
      </div>
    </div>
  </>)
}