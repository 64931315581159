import { useQuery } from "@apollo/client";

import AttendeesListRow from "../../components/Events/AttendeesListRow";
import BackTo from "../../components/Shared/BackTo";
import ErrorMessage from "../../components/Shared/ErrorMessage";
import ListView from "../../components/Shared/ListView";
import Loading from "../../components/Shared/Loading";
import { GET_EVENT } from "../../graphql/events.graphql";

import classes from "./EventAttendeesPage.module.css";

type EventAttendeesPageProps = {
  eventId: string;
};

export default function EventAttendeesPage({ eventId }: EventAttendeesPageProps) {
  if (!eventId) throw new Error("You must provide a valid eventId");

  const { data: eventData, loading, error } = useQuery(GET_EVENT, { variables: { eventId } });

  if (error) return <ErrorMessage message={error.message} />;
  if (!eventData || !eventData?.event || loading) return <Loading />;

  const attendees = eventData.event.attendees;

  return (
    <div className={classes.page}>
      <div className={classes.header}>
        <BackTo />
      </div>
      <ListView
        className={classes.results}
        row={AttendeesListRow}
        items={attendees}
        itemSize={50 + 6 + 6}
      />
    </div>
  );
}
