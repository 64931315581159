import { Tag } from "../graphql/__generated__/graphql";
import { FIXME } from "../shared/types";

export enum EventActions {
  SET = "SET",

  UPDATE_CLUBS = "UPDATE_CLUBS",
  UPDATE_DESCRIPTION = "UPDATE_DESCRIPTION",
  UPDATE_IMAGE = "UPDATE_IMAGE",
  UPDATE_IS_ATTENDEES_HIDDEN = "UPDATE_IS_ATTENDEES_HIDDEN",
  UPDATE_IS_AUTHOR_HIDDEN = "UPDATE_IS_AUTHOR_HIDDEN",
  UPDATE_IS_PRIVATE = "UPDATE_IS_PRIVATE",
  UPDATE_IS_VISIBLE = "UPDATE_IS_VISIBLE",
  UPDATE_LINK = "UPDATE_LINK",
  UPDATE_LOCATION_ID = "UPDATE_LOCATION_ID",
  UPDATE_MAX_RSVPS = "UPDATE_MAX_RSVPS",
  UPDATE_NAME = "UPDATE_NAME",
  UPDATE_PLACE = "UPDATE_PLACE",
  UPDATE_PRICE = "UPDATE_PRICE",
  UPDATE_START_TIME = "UPDATE_START_TIME",
  UPDATE_TAGS = "UPDATE_TAGS",
}

export const eventReducer = (state: FIXME, action: FIXME) => {
  const updated = { ...state };

  switch (action.type) {
    case EventActions.SET:
      updated.clubs = action.clubs || [];
      updated.description = action.description;
      updated.image = action.image;
      updated.isAttendeesHidden = action.isAttendeesHidden;
      updated.isAuthorHidden = action.isAuthorHidden;
      updated.isPrivate = action.isPrivate;
      updated.isVisible = action.isVisible;
      updated.link = action.link;
      updated.locationId = action.locationId;
      updated.maxRsvps = parseInt(action.maxRsvps);
      updated.name = action.name;
      updated.place = action.place;
      updated.price = action.price;
      updated.startTime = action.startTime?.replace(".000Z", "");
      updated.tags = action.tags?.map((tag: Tag) => tag.tagId);
      break;
    case EventActions.UPDATE_CLUBS:
      updated.clubs = action.clubs;
      break;
    case EventActions.UPDATE_DESCRIPTION:
      updated.description = action.description;
      break;
    case EventActions.UPDATE_IMAGE:
      updated.image = action.image;
      break;
    case EventActions.UPDATE_IS_ATTENDEES_HIDDEN:
      updated.isAttendeesHidden = action.isAttendeesHidden;
      break;
    case EventActions.UPDATE_IS_AUTHOR_HIDDEN:
      updated.isAuthorHidden = action.isAuthorHidden;
      break;
    case EventActions.UPDATE_IS_PRIVATE:
      updated.isPrivate = action.isPrivate;
      break;
    case EventActions.UPDATE_IS_VISIBLE:
      updated.isVisible = action.isVisible;
      break;
    case EventActions.UPDATE_LINK:
      updated.link = action.link;
      break;
    case EventActions.UPDATE_LOCATION_ID:
      updated.locationId = action.locationId;
      break;
    case EventActions.UPDATE_MAX_RSVPS:
      updated.maxRsvps = parseInt(action.maxRsvps);
      break;
    case EventActions.UPDATE_NAME:
      updated.name = action.name;
      break;
    case EventActions.UPDATE_PLACE:
      updated.place = action.place;
      break;
    case EventActions.UPDATE_PRICE:
      updated.price = action.price;
      break;
    case EventActions.UPDATE_START_TIME:
      updated.startTime = action.startTime;
      break;
    case EventActions.UPDATE_TAGS:
      updated.tags = action.tags;
      break;
    default:
      throw new Error("Unknown eventReducer action");
  }

  return updated;
};
