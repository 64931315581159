import { Fragment } from "react";
import { Place as PlaceIcon } from "@mui/icons-material";
import classNames from "classnames";

import { getImageUrl } from "../../../shared/image-helpers";
import { FIXME } from "../../../shared/types";
import Avatar from "../../Shared/Avatar";
import { callNativeApp, NativeAppActions } from "../../../shared/native-app-helpers";

import classes from "./Card.module.css";

interface CardProps {
  condensed?: boolean;
  event: FIXME;
}

export default function Card({ condensed = false, event }: CardProps) {
  const datetime = event.startTime ? new Date(event.startTime) : null;

  let date = "";
  let time = "";
  if (datetime) {
    // Format date
    date = new Intl.DateTimeFormat("en-IN", {
      year: "numeric",
      month: "long",
      day: "2-digit",
    }).format(datetime);

    // Format time
    time = new Intl.DateTimeFormat("en-IN", {
      hour: "2-digit",
      minute: "2-digit",
      // second: "2-digit",
      hour12: false,
    }).format(datetime);
  }

  // const date = datetime ? datetime.toLocaleDateString("en-GB", { timeZone: "UTC" }) : null;
  // const time = datetime
  //   ? datetime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", timeZone: "UTC" })
  //   : null;

  const handleOnEVENT_DETAILS = () => {
    callNativeApp({
      action: NativeAppActions.EVENT_DETAILS,
      props: { eventId: event.eventId },
    });
  };

  return (
    <span
      className={classNames(classes.wrapper, condensed && classes.condensed)}
      onClick={handleOnEVENT_DETAILS}
    >
      <img
        className={classes.image}
        src={getImageUrl({
          url: event.image,
          height: condensed ? 238 : 236,
          width: condensed ? 280 : 244,
        })}
      />
      <div className={classes.content}>
        <div className={classes.name}>{event.name}</div>
        <div className={classes.price}>{event.price}</div>
        {date && time && (
          <div className={classes.when}>
            {date} | {time}
          </div>
        )}
        <div className={classes.where}>
          {event.place && (
            <>
              <PlaceIcon /> {event.place}
            </>
          )}
        </div>
        <div className={classes.who}>
          {!event.isAttendeesHidden && (
            <>
              <ul className={classes.attendees}>
                {Array(5)
                  .fill(null)
                  .map((_, index) => (
                    <Fragment key={index}>
                      {event.attendees[index] && (
                        <li
                          className={classes.avatar}
                          key={`event-${event.eventId}-member-${event.attendees[index].userId}`}
                        >
                          <Avatar size={16} user={event.attendees[index]} />
                        </li>
                      )}
                    </Fragment>
                  ))}
              </ul>
              <p className={classes.count}>{event.attendees.length} attending</p>
            </>
          )}
        </div>
      </div>
    </span>
  );
}
