import { useContext, useState } from "react";
import Masonry from "react-responsive-masonry";

import { AppContext } from "../../../providers/AppProvider";
import { AuthContext } from "../../../providers/AuthProvider";
import { getImageUrl } from "../../../shared/image-helpers";
import { FIXME } from "../../../shared/types";
import { Button } from "../../Forms";
import PhotoGallery from "../PhotoGallery";

import classes from "./Feed.module.css";
interface FeedProps {
  user: FIXME;
}

export default function Feed({ user }: FeedProps) {
  const { navigate } = useContext(AppContext);

  const { userId } = useContext(AuthContext);
  const isYourProfile = user.userId === userId;

  const [showingGallery, setShowingGallery] = useState(false);
  const [galleryStartIndex, setGalleryStartIndex] = useState(0);

  const handleCloseGallery = () => {
    setShowingGallery(false);
  };

  const handleShowGallery = (index: number) => {
    setGalleryStartIndex(index);
    setShowingGallery(true);
  };

  const images = user?.images?.slice(1);

  if (!images || images.length === 0) {
    return <p className={classes.empty}>User has no images to show!</p>;
  }

  return (
    <>
      {showingGallery && (
        <PhotoGallery onClose={handleCloseGallery} startIndex={galleryStartIndex} user={user} />
      )}
      <div>
        <Masonry columnsCount={2} gutter="8px">
          {images.map((image: FIXME, i: number) => (
            <img
              className={classes.image}
              key={i}
              onClick={() => handleShowGallery(i)}
              src={getImageUrl({ url: image, width: 800 })}
              style={{ width: "100%", display: "block" }}
            />
          ))}
        </Masonry>
      </div>
      {isYourProfile && (
        <Button color="link" fullWidth onClick={() => navigate("/edit/profile")} size="small">
          Edit images
        </Button>
      )}
    </>
  );
}
