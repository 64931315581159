import { Gravity, RESIZE_TYPES } from "@cld-apis/utils";
import { buildImageUrl } from "cloudinary-build-url";

const { VITE_CLOUDINARY_CLOUD_NAME } = import.meta.env;

export const getImageUrl = ({
  height,
  type = RESIZE_TYPES.FILL,
  url,
  width,
}: {
  height?: number;
  type?: string;
  url: string;
  width: number;
}) => {
  if (!url) return "";

  return buildImageUrl(url, {
    cloud: {
      cloudName: VITE_CLOUDINARY_CLOUD_NAME,
    },
    transformations: {
      format: "auto",
      gravity: Gravity.Subject,
      page: 1,
      resize: {
        height,
        type,
        width,
      },
      quality: "auto",
    },
  });
};
