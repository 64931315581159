import classNames from "classnames";

import { FIXME } from "../../../shared/types";

import classes from "../Onboarding.module.css";

type GenderProps = {
  error: string;
  gender: string;
  genders: FIXME[];
  onChange: (value: string) => void;
};

export default function Gender({ error, gender, genders, onChange }: GenderProps) {
  const handleOnToggle = (name: string) => {
    onChange(name);
  };

  return (
    <div className={classes.container}>
      <div className={classes.intro}>Sign Up</div>
      <div className={classes.title}>Select your gender</div>
      {error && <div className={classes.error}>{error}</div>}
      <div className={classes.tags}>
        {genders.map((tag) => (
          <button
            className={classNames(classes.tag, gender === tag.name && classes.selected)}
            key={tag.tagId}
            onClick={() => handleOnToggle(tag.name)}
            type="button"
          >
            {tag.name}
          </button>
        ))}
      </div>
    </div>
  );
}
