import classNames from "classnames";

import { getImageUrl } from "../../../shared/image-helpers";
import { FIXME } from "../../../shared/types";
import Link from "../../Shared/Link";

import classes from "./Card.module.css";

interface CardProps {
  user: FIXME;
}

export default function Card({ user }: CardProps) {
  return (
    <Link className={classes.wrapper} to={`/profile/${user.userId}`}>
      {user.avatar ? (
        <img
          className={classes.image}
          height={115}
          src={getImageUrl({ url: user.avatar, height: 230, width: 264 })}
          width={132}
        />
      ) : (
        <div className={classNames(classes.image, classes.empty)}></div>
      )}
      <div className={classes.content}>
        <div className={classes.name}>{user.name}</div>
        <div className={classes.username}>{user.username}</div>
      </div>
    </Link>
  );
}
