import { useState } from "react";
import { Sell as SellIcon } from "@mui/icons-material";

import { Tag } from "../../../graphql/__generated__/graphql";
import { ProfileActions } from "../../../reducers/profile.reducer";
import { SuggestionType } from "../../../shared/suggestions-helpers";
import { FIXME } from "../../../shared/types";
import ExpandableTagsSection from "../../Forms/ExpandableTagsSection";
import Gender from "../../Forms/Gender";
import Hidden from "../../Forms/Hidden";
import Images from "../../Forms/Images";
import Input from "../../Forms/Input";
import Links from "../../Forms/Links";
import Location from "../../Forms/Location";
import ShowAge from "../../Forms/ShowAge";
import Tags from "../../Forms/Tags";
import Tabs from "../../Shared/Tabs";

import classes from "./EditProfileForm.module.css";

interface EditProfileFormProps {
  locations: FIXME;
  state: FIXME;
  tags: FIXME;
  updateProfile: FIXME;
}

export default function EditProfileForm({
  locations,
  state,
  tags,
  updateProfile,
}: EditProfileFormProps) {
  const [selectedTab, setSelectedTab] = useState(0);
  const tabs = [
    { label: "Profile", onSelect: () => setSelectedTab(0) },
    { label: "About you", onSelect: () => setSelectedTab(1) },
    { label: "Settings", onSelect: () => setSelectedTab(2) },
  ];

  return (
    <div>
      <Tabs className={classes.tabs} selectedIndex={selectedTab} tabs={tabs} />
      {selectedTab === 0 && (
        <div className={classes.tabContent}>
          <div className={classes.field}>
            <Input
              label="Name"
              onChange={(name) => updateProfile({ type: ProfileActions.UPDATE_NAME, name })}
              required
              value={state.name}
            />
          </div>
          <div className={classes.field}>
            <Input
              label="Headline"
              onChange={(headline) =>
                updateProfile({ type: ProfileActions.UPDATE_HEADLINE, headline })
              }
              value={state.headline}
            />
          </div>
          <div className={classes.field}>
            <Input
              label="Bio"
              onChange={(bio) => updateProfile({ type: ProfileActions.UPDATE_BIO, bio })}
              rows={6}
              type="textarea"
              value={state.bio}
            />
          </div>
          <div className={classes.field}>
            <Images
              images={state.images}
              onChange={(images: FIXME) =>
                updateProfile({ type: ProfileActions.UPDATE_IMAGES, images })
              }
            />
          </div>
          <div className={classes.field}>
            <Location
              locations={locations}
              locationId={state.locationId}
              onChange={(locationId: string) =>
                updateProfile({ type: ProfileActions.UPDATE_LOCATION_ID, locationId })
              }
              suggestionsType={SuggestionType.Location}
            />
          </div>
          <div className={classes.field}>
            <Links
              links={state.links}
              onChange={(links: FIXME) =>
                updateProfile({ type: ProfileActions.UPDATE_LINKS, links })
              }
            />
          </div>
        </div>
      )}
      {selectedTab === 1 && (
        <div className={classes.tabContent}>
          <div className={classes.field}>
            <Gender
              gender={state.gender}
              onChange={(gender: string) =>
                updateProfile({ type: ProfileActions.UPDATE_GENDER, gender })
              }
            />
          </div>
          <div className={classes.field}>
            <ExpandableTagsSection
              icon={<SellIcon />}
              tags={tags
                .filter((tag: Tag) => tag?.grandparent?.name === "About me")
                .filter((tag: Tag) => state.tags?.includes(tag.tagId))}
              title="About me"
            >
              <>
                <div className="field">
                  <Tags
                    available={tags.filter((tag: Tag) => tag?.parent?.name === "Nationality")}
                    onChange={(tags: FIXME) =>
                      updateProfile({ type: ProfileActions.UPDATE_TAGS, tags })
                    }
                    searchable
                    showSelection
                    tags={state.tags}
                    title="Nationality"
                  />
                </div>
                <div className="field">
                  <Tags
                    available={tags.filter((tag: Tag) => tag?.parent?.name === "Faith")}
                    onChange={(tags: FIXME) =>
                      updateProfile({ type: ProfileActions.UPDATE_TAGS, tags })
                    }
                    single
                    tags={state.tags}
                    title="Faith"
                  />
                </div>
                <div className="field">
                  <Tags
                    available={tags.filter(
                      (tag: Tag) => tag?.parent?.name === "Relationship Status"
                    )}
                    onChange={(tags: FIXME) =>
                      updateProfile({ type: ProfileActions.UPDATE_TAGS, tags })
                    }
                    single
                    tags={state.tags}
                    title="Relationship Status"
                  />
                </div>
                <div className="field">
                  <Tags
                    available={tags.filter(
                      (tag: Tag) => tag?.parent?.name === "Sexual Orientation"
                    )}
                    onChange={(tags: FIXME) =>
                      updateProfile({ type: ProfileActions.UPDATE_TAGS, tags })
                    }
                    single
                    tags={state.tags}
                    title="Sexual Orientation"
                  />
                </div>
                <div className="field">
                  <Tags
                    available={tags.filter((tag: Tag) => tag?.parent?.name === "Pronoun")}
                    onChange={(tags: FIXME) =>
                      updateProfile({ type: ProfileActions.UPDATE_TAGS, tags })
                    }
                    single
                    tags={state.tags}
                    title="Pronouns"
                  />
                </div>
              </>
            </ExpandableTagsSection>
          </div>
          <div className={classes.field}>
            <ExpandableTagsSection
              icon={<SellIcon />}
              tags={tags
                .filter((tag: Tag) => tag?.grandparent?.name === "Education & Career")
                .filter((tag: Tag) => state.tags?.includes(tag.tagId))}
              title="Education & Career"
            >
              <>
                <div className="field">
                  <Tags
                    available={tags.filter((tag: Tag) => tag?.parent?.name === "University")}
                    onChange={(tags: FIXME) =>
                      updateProfile({ type: ProfileActions.UPDATE_TAGS, tags })
                    }
                    single
                    showSelection
                    suggestionsType={SuggestionType.University}
                    tags={state.tags}
                    title="University"
                  />
                </div>
                <div className="field">
                  <Tags
                    available={tags.filter((tag: Tag) => tag?.parent?.name === "Courses")}
                    onChange={(tags: FIXME) =>
                      updateProfile({ type: ProfileActions.UPDATE_TAGS, tags })
                    }
                    single
                    tags={state.tags}
                    title="Course"
                  />
                </div>
                <div className="field">
                  <Tags
                    available={tags.filter((tag: Tag) => tag?.parent?.name === "Industry")}
                    onChange={(tags: FIXME) =>
                      updateProfile({ type: ProfileActions.UPDATE_TAGS, tags })
                    }
                    single
                    tags={state.tags}
                    title="Industry"
                  />
                </div>
                <div className="field">
                  <Tags
                    available={tags.filter((tag: Tag) => tag?.parent?.name === "Career")}
                    onChange={(tags: FIXME) =>
                      updateProfile({ type: ProfileActions.UPDATE_TAGS, tags })
                    }
                    single
                    tags={state.tags}
                    title="Career"
                  />
                </div>
              </>
            </ExpandableTagsSection>
          </div>
          <div className={classes.field}>
            <Tags
              available={tags.filter(
                (tag: Tag) =>
                  tag?.grandparent?.name === "Interests" && tag?.parent?.name === "Interests"
              )}
              onChange={(tags: FIXME) => updateProfile({ type: ProfileActions.UPDATE_TAGS, tags })}
              suggestionsType={SuggestionType.Interests}
              tags={state.tags}
              title="Interests"
            />
          </div>
          <div className={classes.field}>
            <Tags
              available={tags.filter(
                (tag: Tag) => tag?.grandparent?.name === "Goals" && tag?.parent?.name === "Goals"
              )}
              onChange={(tags: FIXME) => updateProfile({ type: ProfileActions.UPDATE_TAGS, tags })}
              tags={state.tags}
              title="Goals"
            />
          </div>
          <div className={classes.field}>
            <ExpandableTagsSection
              icon={<SellIcon />}
              tags={tags
                .filter((tag: Tag) => tag?.grandparent?.name === "Values")
                .filter((tag: Tag) => state.tags?.includes(tag.tagId))}
              title="Values"
            >
              <>
                <div className="field">
                  <Tags
                    available={tags.filter((tag: Tag) => tag?.parent?.name === "Traits")}
                    onChange={(tags: FIXME) =>
                      updateProfile({ type: ProfileActions.UPDATE_TAGS, tags })
                    }
                    tags={state.tags}
                    title="Traits"
                  />
                </div>
                <div className="field">
                  <Tags
                    available={tags.filter((tag: Tag) => tag?.parent?.name === "Star Signs")}
                    onChange={(tags: FIXME) =>
                      updateProfile({ type: ProfileActions.UPDATE_TAGS, tags })
                    }
                    single
                    tags={state.tags}
                    title="Star Sign"
                  />
                </div>
              </>
            </ExpandableTagsSection>
          </div>
        </div>
      )}
      {selectedTab === 2 && (
        <div className={classes.tabContent}>
          <div className={classes.field}>
            <Hidden
              isHidden={state.isHidden}
              onChange={(isHidden: boolean) =>
                updateProfile({ type: ProfileActions.UPDATE_IS_HIDDEN, isHidden })
              }
            />
          </div>
          <div className={classes.field}>
            <ShowAge
              isShowAge={state.isShowAge}
              onChange={(isShowAge: boolean) =>
                updateProfile({ type: ProfileActions.UPDATE_IS_SHOW_AGE, isShowAge })
              }
            />
          </div>
        </div>
      )}
    </div>
  );
}
