import { useEffect, useRef } from "react";

import { FIXME } from "../../../shared/types";

export const ScrollToBottomObserver = ({ children, preventScroll, messages }: FIXME) => {
  const ref = useRef<HTMLDivElement>(null);

  const scrollIntoView = () => {
    if (ref.current && !preventScroll) {
      console.log('scrolling')
      ref.current.scrollIntoView({ behavior: "instant" });
    }
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      window.setTimeout(scrollIntoView, 100);
    });

    scrollIntoView();

    return () => {
      window.removeEventListener("resize", scrollIntoView);
    };
  }, [messages]);

  useEffect(() => {
    // Sometimes reactions push the content down, so we make sure we are at the bottom after the reactions get displayed
    window.setTimeout(scrollIntoView, 500);
  }, []);

  return (
    <div>
      {children}
      <div ref={ref} />
    </div>
  );
};
