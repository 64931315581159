import { useContext, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import {
  AdminPanelSettings as AdminPanelSettingsIcon,
  ChevronRight as ChevronRightIcon,
  Lock as LockIcon,
} from "@mui/icons-material";
import debounce from "lodash.debounce";

import { User } from "../../../graphql/__generated__/graphql";
import { GET_SEARCH } from "../../../graphql/search.graphql";
import { AuthContext } from "../../../providers/AuthProvider";
import { FIXME } from "../../../shared/types";
import Avatar from "../../Shared/Avatar";
import FormModal from "../FormModal";
import Input from "../Input";
import { Button } from "..";

import classes from "./Admins.module.css";

interface AdminsProps {
  // AdminsId?: string;
  admins: User[];
  author: User;
  onChange?: FIXME;
}

export default function Admins({
  // AdminsId,
  admins = [],
  author,
  onChange = () => null,
}: AdminsProps) {
  const { userId } = useContext(AuthContext);

  const [showingModal, setShowingModal] = useState(false);

  const [query, setQuery] = useState("");
  const [_, setLoading] = useState(false);
  const [results, setResults] = useState<FIXME>([]);

  const [search, { data }] = useLazyQuery(GET_SEARCH);

  const handleOnAdd = (adding: FIXME) => {
    const updated = [...admins, adding];
    onChange(updated);
  };

  const handleOnRemove = (removing: FIXME) => {
    const updated = admins.filter((admin) => admin.userId !== removing.userId);
    onChange(updated);
  };

  const doSearch = debounce((query: string) => {
    if (query === "") {
      setResults([]);
      return;
    }

    setLoading(true);
    setResults([]);

    search({
      onCompleted: () => setLoading(false),
      variables: { query },
    });
  }, 500);

  const handleOnChange = (value: FIXME) => {
    setLoading(true);
    setQuery(value);

    doSearch(value);
  };

  useEffect(() => {
    if (data?.search) {
      const connects = data?.search?.connects || [];
      setResults(connects);
    }
  }, [data]);

  const adminIds = admins.map((admin) => admin.userId);

  if (showingModal) {
    return (
      <FormModal onClose={() => setShowingModal(false)} title="Admins">
        <div className={classes.search}>
          <Input
            hideLabel
            label="Search"
            onChange={handleOnChange}
            placeholder="Search"
            value={query}
          />
          <ul className={classes.admins}>
            {results
              .filter((result: FIXME) => !adminIds.includes(result.userId))
              .map((result: FIXME) => (
                <li className={classes.admin} key={result.userId}>
                  <Avatar className={classes.avatar} user={result} />
                  <h4 className={classes.name}>{result.name}</h4>
                  <p className={classes.username}>{result.username}</p>
                  <div className={classes.action}>
                    <Button onClick={() => handleOnAdd(result)} size="small">
                      Add
                    </Button>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </FormModal>
    );
  }

  return (
    <div className={classes.wrapper}>
      <button className={classes.header} onClick={() => setShowingModal(true)} type="button">
        <AdminPanelSettingsIcon className={classes.icon} />
        <span className={classes.text}>Admins</span>
        <ChevronRightIcon className={classes.arrow} />
      </button>
      <ul className={classes.admins}>
        {admins.map((admin) => (
          <li className={classes.admin} key={admin.userId}>
            <Avatar className={classes.avatar} user={admin} />
            <h4 className={classes.name}>{admin.name}</h4>
            <p className={classes.username}>{admin.username}</p>
            <div className={classes.action}>
              {admin.userId === userId || admin.userId === author?.userId ? (
                <LockIcon />
              ) : (
                <Button onClick={() => handleOnRemove(admin)} size="small">
                  Remove
                </Button>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
