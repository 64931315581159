import { graphql } from "./__generated__/gql";

export const FOLLOW = graphql(`
  mutation Follow($userId: ID!) {
    follow(userId: $userId) {
      userId
      followers {
        userId
        name
        username
        avatar
      }
    }
  }
`);

export const GET_IS_FOLLOWING = graphql(`
  query GetIsFollowing($userId: ID!) {
    isFollowing(userId: $userId)
  }
`);

export const UNFOLLOW = graphql(`
  mutation Unfollow($userId: ID!) {
    unfollow(userId: $userId) {
      userId
      followers {
        userId
        name
        username
        avatar
      }
    }
  }
`);
