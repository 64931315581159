import { useEffect, useRef, useState } from "react";
import classNames from "classnames";

import { getImageUrl } from "../../../shared/image-helpers";
import { FIXME } from "../../../shared/types";

import classes from "./PhotoGallery.module.css";

interface PhotoGalleryProps {
  onClose?: FIXME;
  startIndex?: number;
  user: FIXME;
}

export default function PhotoGallery({
  onClose = () => null,
  startIndex = 0,
  user,
}: PhotoGalleryProps) {
  const images = user?.images?.slice(1);

  const [currentIndex, setCurrentIndex] = useState(startIndex);
  const galleryRef = useRef<HTMLUListElement>(null);

  const handleScroll = () => {
    if (galleryRef.current) {
      const galleryWidth = galleryRef.current.offsetWidth;
      const scrollLeft = galleryRef.current.scrollLeft;
      const index = Math.round(scrollLeft / galleryWidth);
      setCurrentIndex(index);
    }
  };

  useEffect(() => {
    if (galleryRef.current) {
      const galleryElement = galleryRef.current;
      galleryElement.addEventListener("scroll", handleScroll);
      return () => {
        galleryElement.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    if (galleryRef.current) {
      const galleryElement = galleryRef.current;
      const selectedSlide = galleryElement.childNodes[startIndex] as HTMLLIElement;
      selectedSlide.scrollIntoView({ behavior: "instant" });
    }
  }, [startIndex]);

  return (
    <div className={classes.wrapper} onClick={onClose}>
      <div>
        {currentIndex}
        <ul className={classes.list} ref={galleryRef}>
          {images.map((image: FIXME, i: number) => (
            <li className={classes.item} key={i}>
              <img
                className={classes.image}
                src={getImageUrl({ url: image, width: 800 })}
                style={{ width: "100%", display: "block" }}
              />
            </li>
          ))}
        </ul>
        <ul className={classes.pills}>
          {images.map((image: FIXME, i: number) => (
            <li
              className={classNames(classes.pill, currentIndex === i && classes.selected)}
              key={`pill-${i}`}
            >
              {image.url}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
