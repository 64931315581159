import { createContext, useContext, useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import uniqBy from "lodash.uniqby";
import { v4 as uuid } from "uuid";

import Loading from "../components/Shared/Loading";
import { Chat, Message, ReactionType } from "../graphql/__generated__/graphql";
import {
  CREATE_CLUB_CHAT,
  CREATE_EVENT_CHAT,
  CREATE_MESSAGE,
  CREATE_USER_CHAT,
  CREATE_USER_IS_TYPING,
  DELETE_CHAT,
  DELETE_MESSAGE,
  GET_CHAT_BY_ID,
  GET_CHATS,
  MARK_AS_READ,
  GET_CHAT_MESSAGES,
} from "../graphql/chat.graphql";
import { GET_USER } from "../graphql/users.graphql";
import { PusherEvents } from "../shared/pusher-events";
import { FIXME } from "../shared/types";

import { AuthContext } from "./AuthProvider";
import { PusherContext } from "./PusherProvider";

type ChatsContextType = {
  addReaction: FIXME;
  chats: FIXME;
  createClubChat: FIXME;
  createEventChat: FIXME;
  createUserChat: FIXME;
  createUserIsTyping: FIXME;
  deleteChat: FIXME;
  deleteMessage: FIXME;
  deleteReaction: FIXME;
  markAsRead: FIXME;
  sendMessage: FIXME;
  unreadCount: number;
  unreadConnectsCount: number;
  unreadClubsCount: number;
  unreadEventsCount: number;
  updateChats: FIXME;

  childLoader: FIXME;
  alldataLoad: FIXME;

  updateOffset: FIXME;
  updateTabAction: FIXME;
  relevantDataUpdate: FIXME;
  getChatByID: FIXME;
  loadMoreChatMessages: (chat: Chat) => Promise<void>;
};

export const ChatsContext = createContext<ChatsContextType>({
  addReaction: () => null,
  chats: [],
  createClubChat: null,
  createEventChat: null,
  createUserChat: null,
  createUserIsTyping: null,
  deleteChat: null,
  deleteMessage: () => null,
  deleteReaction: () => null,
  markAsRead: null,
  sendMessage: () => null,
  unreadCount: 0,
  unreadConnectsCount: 0,
  unreadClubsCount: 0,
  unreadEventsCount: 0,
  updateChats: () => null,

  childLoader: null,
  alldataLoad: null,
  updateOffset: () => null,
  updateTabAction: () => null,
  relevantDataUpdate: () => null,
  getChatByID: () => null,
  loadMoreChatMessages: async (_: Chat) => {},
});

export const ChatsProvider = ({ children }: FIXME) => {
  const { updateChatIds } = useContext(PusherContext);
  const { userId } = useContext(AuthContext);

  const [chats, setChats] = useState<FIXME>([]);
  const [loadedOnce, setLoadedOnce] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [unreadConnectsCount, setUnreadConnectsCount] = useState(0);
  const [unreadClubsCount, setUnreadClubsCount] = useState(0);
  const [unreadEventsCount, setUnreadEventsCount] = useState(0);

  const [limit] = useState(10);
  const [offset, setoffset] = useState(0);
  const [messagesLimit] = useState(50);
  const [clubId, setClubId] = useState("");
  const [eventId, setEventId] = useState("");
  const [childLoader, setchildLoader] = useState(false);
  const [alldataLoad, setalldataLoad] = useState(false);
  const [tabaction, settabaction] = useState("connects");

  const [chatId, setChatId] = useState("");

  const resetChat = () => {
    // setclubId("");
    // seteventId("");

    setChats([]);
    setoffset(0);
    setchildLoader(true);
    setalldataLoad(false);
  };
  // Function to update tab action
  const updateTabAction = (newValue: string) => {
    resetChat();
    settabaction(newValue);
  };

  // Function to update offset
  const updateOffset = () => {
    setchildLoader(true);
    setoffset((prevOffset) => {
      const newOffset = prevOffset + 1;
      return newOffset;
    });
  };

  const relevantDataUpdate = async (data: any) => {
    const updateClubId = data?.clubId ? data?.clubId : "";
    const updateEventId = data?.eventId ? data?.eventId : "";

    setClubId(updateClubId);
    setEventId(updateEventId);
  };

  useEffect(() => {
    refreshChats();
  }, [clubId, eventId]);

  const getChatByID = async (data: any) => {
    setChatId(data?.chatId);
    getChat();
  };

  const { data: userData } = useQuery(GET_USER, { variables: { userId } });

  //TODO: Use the chat_subscribers table to get all the chats a user subscribes to
  const [getChats, { refetch: refreshChats }] = useLazyQuery(GET_CHATS, {
    fetchPolicy: "network-only",
    variables: {
      clubId,
      eventId,
      limit: limit,
      offset: limit * offset,
      tabaction: tabaction,
      messagesLimit: messagesLimit,
    },
    onCompleted: (response) => {
      const responseChats = response?.chats;
      if (responseChats.length <= 9) {
        setalldataLoad(true);
      }

      const newChats = [...chats, ...responseChats];
      setChats(newChats);
      setLoadedOnce(true);
      setchildLoader(false);
    },
  });

  const [getChat] = useLazyQuery(GET_CHAT_BY_ID, {
    fetchPolicy: "network-only",
    variables: {
      chatId,
    },
    onCompleted: (response) => {
      const responseChat = response?.chat;
      setChats(() => uniqBy([...chats, responseChat], "chatId"));
    },
  });

  const [getChatMessages] = useLazyQuery(GET_CHAT_MESSAGES, {
    fetchPolicy: "network-only",
    onCompleted: ({ chat: newChat }) => {
      console.log({ newChat });
      const chat = chats.find((c: Chat) => c.chatId === newChat.chatId);
      if (!chat) return;

      const updatedChat = {
        ...chat,
        ...newChat,
        messages: uniqBy([...(chat.messages ?? []), ...(newChat.messages ?? [])], "messageId"),
      };
      setChats(chats.map((c: Chat) => (c.chatId === updatedChat.chatId ? updatedChat : c)));
    },
    onError: (error) => {
      console.log({ error });
    },
  });

  const [createClubChatMutation] = useMutation(CREATE_CLUB_CHAT);
  const [createEventChatMutation] = useMutation(CREATE_EVENT_CHAT);
  const [createMessage] = useMutation(CREATE_MESSAGE);
  const [createUserChatMutation] = useMutation(CREATE_USER_CHAT);
  const [createUserIsTyping] = useMutation(CREATE_USER_IS_TYPING);
  const [deleteChatMutation] = useMutation(DELETE_CHAT);
  const [deleteMessageMutation] = useMutation(DELETE_MESSAGE);
  const [markAsReadMutation] = useMutation(MARK_AS_READ);

  // Load chats once on load
  useEffect(() => {
    // getChats();
    setLoadedOnce(true);
  }, []);

  // This lets you set a callback, that gets fired when you create a new chat
  useEffect(() => {
    updateChatIds(chats.map((chat: FIXME) => chat.chatId));
  }, [chats]);

  // ADD a message. This just adds clientside, not to the server
  const addMessage = (chatId: string, message: FIXME) => {
    const newMessage = {
      authorId: userData?.user.userId,
      author: userData?.user,
      reactions: [],
      createdAt: new Date().toISOString(),
      ...message,
    };
    const updated = chats.map((chat: FIXME) => {
      if (chat.chatId !== chatId) return chat;
      return {
        ...chat,

        messages: [newMessage, ...chat.messages],
        unread: chat.unread + 1,
      };
    });
    setChats(() => updated);
  };

  // ADD a reaction. This just adds clientside, not to the server
  const addReaction = (chatId: string, messageId: string, reaction: ReactionType) => {
    const updated = chats.map((chat: FIXME) => {
      if (chat.chatId !== chatId) return chat;
      return {
        ...chat,
        messages: chat.messages.map((message: Message) => {
          if (message.messageId !== messageId) return message;
          return {
            ...message,
            reactions: [
              ...message.reactions,
              {
                reactionId: uuid(),
                authorId: userId,
                messageId: message.messageId,
                type: reaction,
              },
            ],
          };
        }),
      };
    });
    setChats(() => updated);
  };

  // DELETE a message. Adds clientside, and updates server
  const deleteMessage = (chatId: string, messageId: string) => {
    deleteMessageMutation({ variables: { messageId } });

    const updated = chats.map((chat: FIXME) => {
      if (chat.chatId !== chatId) return chat;
      return {
        ...chat,
        messages: chat.messages.map((message: Message) => {
          if (message.messageId !== messageId) return message;
          return {
            ...message,
            deletedAt: Date.now(),
          };
        }),
      };
    });
    setChats(() => updated);
  };

  // DELETE a reaction. This just adds clientside, not to the server
  const deleteReaction = (chatId: string, messageId: string, reactionId: string) => {
    const updated = chats.map((chat: FIXME) => {
      if (chat.chatId !== chatId) return chat;
      return {
        ...chat,
        messages: chat.messages.map((message: Message) => {
          if (message.messageId !== messageId) return message;
          return {
            ...message,
            reactions: message.reactions.filter(
              (reaction: FIXME) => reaction.reactionId !== reactionId
            ),
          };
        }),
      };
    });
    setChats(() => updated);
  };

  // SEND a message. This adds clientside and server side
  const sendMessage = (chatId: string, message: FIXME) => {
    const messageWithId = {
      messageId: uuid(),
      ...message,
    };
    addMessage(chatId, messageWithId);
    const createMessageInput = {
      chatId: chatId,
      ...messageWithId,
      replyingTo: message.replyingTo?.messageId,
    };
    createMessage({ variables: { createMessageInput } });
  };

  // Mark as read, locally and on the server
  const markAsRead = (chatId: string) => {
    const updated = chats.map((chat: FIXME) => {
      if (chat.chatId !== chatId) return chat;
      return { ...chat, unread: 0 };
    });
    setChats(updated);
    markAsReadMutation({ variables: { chatId } });
  };

  // Create Club Chat
  const createClubChat = async (
    clubId: string,
    name: string,
    callback: (chatId: string) => null
  ) => {
    const { data } = await createClubChatMutation({
      variables: { createClubChatInput: { clubId, name } },
    });
    setChats(() => uniqBy([...chats, data?.createClubChat], "chatId"));
    const chatId = data?.createClubChat?.chatId || "";
    callback(chatId);
  };

  // Create Event Chat
  const createEventChat = async (
    eventId: string,
    name: string,
    callback: (chatId: string) => null
  ) => {
    const { data } = await createEventChatMutation({
      variables: { createEventChatInput: { eventId, name } },
    });
    setChats(() => uniqBy([...chats, data?.createEventChat], "chatId"));
    const chatId = data?.createEventChat?.chatId || "";
    callback(chatId);
  };

  // Create User Chat
  const createUserChat = async (userId: string, callback: (chatId: string) => null) => {
    const { data } = await createUserChatMutation({
      variables: { createUserChatInput: { userId } },
    });
    setChats(() => uniqBy([...chats, data?.createUserChat], "chatId"));
    const chatId = data?.createUserChat?.chatId || "";
    callback(chatId);
  };

  // Unread count
  useEffect(() => {
    const unread = chats.reduce((total: number, current: FIXME) => {
      return total + current.unread;
    }, 0);
    const unreadConnects = chats
      .filter((chat: FIXME) => !chat.clubId && !chat.eventId)
      .reduce((total: number, current: FIXME) => {
        return total + current.unread;
      }, 0);
    const unreadClubs = chats
      .filter((chat: FIXME) => chat.clubId)
      .reduce((total: number, current: FIXME) => {
        return total + current.unread;
      }, 0);
    const unreadEvents = chats
      .filter((chat: FIXME) => chat.eventId)
      .reduce((total: number, current: FIXME) => {
        return total + current.unread;
      }, 0);

    setUnreadCount(unread);
    setUnreadConnectsCount(unreadConnects);
    setUnreadClubsCount(unreadClubs);
    setUnreadEventsCount(unreadEvents);
  }, [chats]);

  // DELETE a chat
  const deleteChat = async (chatId: string) => {
    await deleteChatMutation({ variables: { chatId } });

    await getChats();
  };

  // Updates the chats
  const updateChats = async () => {
    const { data } = await refreshChats();
    setChats(() => [...data.chats]);
  };

  const loadMoreChatMessages = async (chat: Chat) => {
    const earliestMessage = chat.messages?.at(-1);

    if (earliestMessage) {
      await getChatMessages({
        variables: {
          chatId: chat.chatId,
          beforeDate: earliestMessage.createdAt,
        },
      });
    }
  };

  // Handler for Pusher events
  useEffect(() => {
    const handler = (evt: FIXME) => {
      const { chatId, message, type } = evt.detail;
      if (type === PusherEvents.CHAT_UPDATE_ALL) {
        updateChats();
      }
      if (type === PusherEvents.CHAT_NEW_MESSAGE && message.authorId !== userId) {
        addMessage(chatId, message);
      }
    };
    window.addEventListener("chat-event", handler);
    return () => {
      window.removeEventListener("chat-event", handler);
    };
  }, [chats, userId]);

  if (!loadedOnce) {
    return (
      <div style={{ height: "100dvh" }}>
        <Loading />
      </div>
    );
  }

  const value: ChatsContextType = {
    addReaction,
    chats,
    createClubChat,
    createEventChat,
    createUserChat,
    createUserIsTyping,
    deleteChat,
    deleteMessage,
    deleteReaction,
    markAsRead,
    sendMessage,
    unreadCount,
    unreadConnectsCount,
    unreadClubsCount,
    unreadEventsCount,
    updateChats,

    childLoader,
    alldataLoad,
    updateOffset,
    updateTabAction,
    relevantDataUpdate,
    getChatByID,
    loadMoreChatMessages,
  };

  return <ChatsContext.Provider value={value}>{children}</ChatsContext.Provider>;
};
