import { useContext, useEffect, useReducer, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";

import EditClubForm from "../../components/Clubs/EditClubForm";
import { Button } from "../../components/Forms";
import FormHeader from "../../components/Forms/FormHeader";
import Loading from "../../components/Shared/Loading";
import { CREATE_CLUB, GET_CLUB, UPDATE_CLUB } from "../../graphql/clubs.graphql";
import { GET_ALL_LOCATIONS } from "../../graphql/locations.graphql";
import { GET_ALL_TAGS } from "../../graphql/tags.graphql";
import { AppContext } from "../../providers/AppProvider";
import { ClubActions, clubReducer } from "../../reducers/club.reducer";
import { FIXME } from "../../shared/types";

import classes from "./EditClubPage.module.css";

type EditClubPageProps = {
  clubId: string;
};

export default function EditClubPage({ clubId }: EditClubPageProps) {
  const { goBack, refetch, reset } = useContext(AppContext);

  const [state, updateClub] = useReducer(clubReducer, {
    isPrivate: false,
    isVisible: true,
  });
  const [error, setError] = useState("");
  const [saving, setSaving] = useState(false);

  const [createClubMutation] = useMutation(CREATE_CLUB);
  const [updateClubMutation] = useMutation(UPDATE_CLUB);

  const { data, loading } = useQuery(GET_CLUB, {
    variables: {
      clubId: clubId || "",
    },
    skip: !clubId,
  });
  const { data: locationsData } = useQuery(GET_ALL_LOCATIONS, { fetchPolicy: "network-only" });
  const { data: tagsData } = useQuery(GET_ALL_TAGS, { fetchPolicy: "network-only" });

  useEffect(() => {
    if (data?.club) {
      updateClub({ type: ClubActions.SET, ...data.club });
    }
  }, [data?.club]);

  const handleSaveClub = async () => {
    setSaving(true);

    const cleaned = { ...state };

    cleaned.adminIds = cleaned.admins?.map((admin: FIXME) => admin.userId);

    delete cleaned.admins;
    delete cleaned.author;

    if (clubId) {
      try {
        const updateClubInput = { clubId, ...cleaned };

        await updateClubMutation({
          variables: { updateClubInput },
        });

        refetch();
        goBack();
      } catch (error: FIXME) {
        setSaving(false);
        setError(error.message);
      }
    } else {
      try {
        const createClubInput = { ...cleaned };
        const { data } = await createClubMutation({
          variables: { createClubInput },
        });

        refetch();
        reset(`/clubs/${data?.createClub?.clubId}`);
      } catch (error: FIXME) {
        setSaving(false);
        setError(error.message);
      }
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className={classes.page}>
      <FormHeader title={`${clubId ? "Edit" : "Create"} Club`} />
      {error && <p className={classes.error}>{error}</p>}
      <div className={classes.form}>
        <EditClubForm
          locations={locationsData?.locations}
          state={state}
          tags={tagsData?.tags}
          updateClub={updateClub}
        />
      </div>
      <div className={classes.button}>
        <Button disabled={saving} fullWidth onClick={handleSaveClub}>
          {saving ? <>Saving...</> : <>{clubId ? "Save" : "Create Club"}</>}
        </Button>
      </div>
    </div>
  );
}
