import { useContext, useEffect, useReducer, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";

import EditEventForm from "../../components/Events/EditEventForm";
import { Button } from "../../components/Forms";
import FormHeader from "../../components/Forms/FormHeader";
import Loading from "../../components/Shared/Loading";
import { CREATE_EVENT, GET_EVENT, UPDATE_EVENT } from "../../graphql/events.graphql";
import { GET_ALL_LOCATIONS } from "../../graphql/locations.graphql";
import { GET_ALL_TAGS } from "../../graphql/tags.graphql";
import { GET_USER } from "../../graphql/users.graphql";
import { AppContext } from "../../providers/AppProvider";
import { AuthContext } from "../../providers/AuthProvider";
import { EventActions, eventReducer } from "../../reducers/event.reducer";
import { FIXME } from "../../shared/types";

import classes from "./EditEventPage.module.css";

type EditEventPageProps = {
  eventId: string;
};

export default function EditEventPage({ eventId }: EditEventPageProps) {
  const { goBack, refetch, reset } = useContext(AppContext);
  const { userId } = useContext(AuthContext);

  const [state, updateEvent] = useReducer(eventReducer, {
    isAttendeesHidden: false,
    isAuthorHidden: false,
    isPrivate: false,
    isVisible: true,
  });
  const [error, setError] = useState("");
  const [saving, setSaving] = useState(false);

  const [createEventMutation] = useMutation(CREATE_EVENT);
  const [updateEventMutation] = useMutation(UPDATE_EVENT);

  const { data, loading } = useQuery(GET_EVENT, {
    variables: {
      eventId: eventId || "",
    },
    skip: !eventId,
  });
  const { data: locationsData } = useQuery(GET_ALL_LOCATIONS);
  const { data: tagsData } = useQuery(GET_ALL_TAGS);
  const { data: userData } = useQuery(GET_USER, {
    variables: { userId },
  });

  useEffect(() => {
    if (data?.event) {
      updateEvent({ type: EventActions.SET, ...data.event });
    }
  }, [data?.event]);

  const handleSaveEvent = async () => {
    setSaving(true);

    const cleaned = { ...state };

    cleaned.clubIds = cleaned.clubs?.map((club: FIXME) => club.clubId);

    delete cleaned.clubs;

    if (eventId) {
      try {
        const updateEventInput = { eventId, ...cleaned };
        await updateEventMutation({
          variables: { updateEventInput },
        });

        refetch();
        goBack();
      } catch (error: FIXME) {
        setSaving(false);
        setError(error.message);
      }
    } else {
      try {
        const createEventInput = { ...cleaned };
        const { data } = await createEventMutation({
          variables: { createEventInput },
        });

        refetch();
        reset(`/events/${data?.createEvent?.eventId}`);
      } catch (error: FIXME) {
        setSaving(false);
        setError(error.message);
      }
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className={classes.page}>
      <FormHeader title={`${eventId ? "Edit" : "Create"} Event`} />
      {error && <p className={classes.error}>{error}</p>}
      <div className={classes.form}>
        <EditEventForm
          locations={locationsData?.locations}
          state={state}
          tags={tagsData?.tags}
          updateEvent={updateEvent}
          user={userData?.user}
        />
      </div>
      <div className={classes.button}>
        <Button disabled={saving} fullWidth onClick={handleSaveEvent}>
          {saving ? <>Saving...</> : <>{eventId ? "Save" : "Create Event"}</>}
        </Button>
      </div>
    </div>
  );
}
