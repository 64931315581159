import { useContext } from "react";
import { useMutation } from "@apollo/client";

import { DELETE_CLUB } from "../../../graphql/clubs.graphql";
import { AppContext } from "../../../providers/AppProvider";
import { AuthContext } from "../../../providers/AuthProvider";
import { FIXME } from "../../../shared/types";
import { Button } from "../../Forms";

import classes from "./DeleteClub.module.css";

interface DeleteClubProps {
  club: FIXME;
}

export default function DeleteClub({ club }: DeleteClubProps) {
  const { userId } = useContext(AuthContext);
  const { navigate } = useContext(AppContext);

  const isAdmin = club.admins.find((admin: FIXME) => admin.userId === userId);

  const [deleting] = useMutation(DELETE_CLUB);

  const handleOnDeleteClub = async () => {
    const confirmed = confirm(
      `Are you sure you want to delete the club "${club.name}"? This cannot be undone!`
    );

    if (confirmed) {
      await deleting({
        variables: { clubId: club.clubId },
        onCompleted: () => {
          alert(`The club "${club.name}" has been deleted.`);
          navigate("/");
        },
      });
    }
  };

  if (!isAdmin) return null;

  return (
    <div className={classes.wrapper}>
      <Button color="danger" fullWidth onClick={handleOnDeleteClub}>
        Delete Club
      </Button>
    </div>
  );
}
