import { FIXME } from "../shared/types";

export enum OnboardingActions {
  UPDATE_COURSE_ID = "UPDATE_COURSE_ID",
  UPDATE_DOB = "UPDATE_DOB",
  UPDATE_GENDER = "UPDATE_GENDER",
  UPDATE_IMAGES = "UPDATE_IMAGES",
  UPDATE_INTEREST_IDS = "UPDATE_INTEREST_IDS",
  UPDATE_LOCATION_ID = "UPDATE_LOCATION_ID",
  UPDATE_NAME = "UPDATE_NAME",
  UPDATE_STUDY_YEAR = "UPDATE_STUDY_YEAR",
  UPDATE_UNIVERSITY_ID = "UPDATE_UNIVERSITY_ID",
  UPDATE_USERNAME = "UPDATE_USERNAME",
}

export const onboardingReducer = (state: FIXME, action: FIXME) => {
  const updated = { ...state };

  switch (action.type) {
    case OnboardingActions.UPDATE_COURSE_ID:
      updated.courseId = action.courseId;
      break;
    case OnboardingActions.UPDATE_DOB:
      updated.dob = action.dob;
      break;
    case OnboardingActions.UPDATE_GENDER:
      updated.gender = action.gender;
      break;
    case OnboardingActions.UPDATE_IMAGES:
      updated.images = action.images;
      break;
    case OnboardingActions.UPDATE_INTEREST_IDS:
      updated.interestIds = action.interestIds;
      break;
    case OnboardingActions.UPDATE_LOCATION_ID:
      updated.locationId = action.locationId;
      break;
    case OnboardingActions.UPDATE_NAME:
      updated.name = action.name;
      break;
    case OnboardingActions.UPDATE_STUDY_YEAR:
      updated.studyYear = action.studyYear ? parseInt(action.studyYear) : null;
      break;
    case OnboardingActions.UPDATE_UNIVERSITY_ID:
      updated.universityId = action.universityId;
      break;
    case OnboardingActions.UPDATE_USERNAME:
      updated.username = action.username;
      break;
    default:
      throw new Error("Unknown onboardingReducer action");
  }

  return updated;
};
