import classes from "./ChatInfoPage.module.css";
import { useContext, useEffect, useState } from "react";
import { Chat } from "../../graphql/__generated__/graphql.ts";
import { FIXME } from "../../shared/types.ts";
import { ChatsContext } from "../../providers/ChatsProvider.tsx";
import { getImageUrl } from "../../shared/image-helpers.ts";
import { Switch } from "@mui/material";
import { useLazyQuery, useMutation } from "@apollo/client";
import { IS_MUTED, MUTE_CHAT, UNMUTE_CHAT } from "../../graphql/chat.graphql.ts";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { AppContext } from "../../providers/AppProvider.tsx";

type ChatPageProps = {
  chatId: string;
};

export default function ChatInfoPage({ chatId }: ChatPageProps) {
  const { chats } = useContext(ChatsContext);
  const { goBack } = useContext(AppContext);
  const [chat, setChat] = useState<Chat>()
  const [isMuted, setIsMuted] = useState(false)

  const [getIsMuted, {refetch: refreshChatMuted}] = useLazyQuery(IS_MUTED, {
    fetchPolicy: "cache-and-network",
    variables: { chatId },
    onCompleted: ({ isChatMuted }) => {
      setIsMuted(isChatMuted)
    },
  });

  const [muteChat] = useMutation(MUTE_CHAT);
  const [unmuteChat] = useMutation(UNMUTE_CHAT);


  useEffect(() => {
    const chatFound = chats.find((chat: FIXME) => chat.chatId === chatId);
    setChat(chatFound)
    getIsMuted()
  }, [chats, chatId]);

  const handleMuteChange = async (evt:React.ChangeEvent<HTMLInputElement>) => {
    console.log({value: evt.target.checked})
    if(evt.target.checked) {
      await muteChat({
        variables:{
          chatId
        }
      })
    }else {
      await unmuteChat({
        variables:{
          chatId
        }
      })
    }
    await refreshChatMuted()
  }

  return (
    <>
      <header className={classes.wrapper}>
        <button className={classes.back} onClick={goBack} type="button">
          <ArrowBackIcon />
        </button>
        <span className={classes.title}>
          Chat info
        </span>
      </header>
      <div className={classes.hero}>
        <div>
          {chat?.clubId && (
            <>
              <div className={classes.imgWrapper}>
              <img
                className={classes.image}
                src={getImageUrl({ url: chat.club?.image ?? '', height: 84, width: 84 })}
              />
              </div>
              <div className={classes.name}>{chat.name}</div>
              <div className={classes.room}>{chat.club?.name}</div>
            </>
          )}
          {chat?.eventId && (
            <>
            <div className={classes.imgWrapper}>
              <img
                className={classes.image}
                src={getImageUrl({ url: chat.event?.image ?? '', height: 84, width: 84 })}
              />
            </div>
              <div className={classes.name}>{chat.name}</div>
              <div className={classes.room}>{chat.event?.name}</div>
            </>
          )}
          {!chat?.clubId && !chat?.eventId && (
            <>
            <div className={classes.imgWrapper}>
              <img
                className={classes.image}
                src={getImageUrl({ url: chat?.participants?.[0]?.avatar ?? '', height: 84, width: 84 })}
              />
            </div>/
              <div className={classes.name}>{chat?.participants?.[0]?.name}</div>
              <div className={classes.room}>{chat?.participants?.[0]?.username}</div>
            </>
          )}
        </div>
      </div>
      <div className={classes.mute}>
        <span className={classes.muteText}>Mute Chat</span>
        <Switch className={classes.muteToggle} size={"medium"}
                checked={isMuted}
                onChange={handleMuteChange}
                inputProps={{ 'aria-label': 'controlled' }}/>
      </div>
      <div>

      </div>
    </>
  )
}