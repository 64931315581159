import { useContext, useState } from "react";
import { useMutation } from "@apollo/client";
import { ChevronRight as ChevronRightIcon } from "@mui/icons-material";
import classNames from "classnames";
import jwt_decode from "jwt-decode";
import { useLongPress } from "use-long-press";

import { Button } from "../../components/Forms";
import { DELETE_USER_NOTIFICATION_TOKEN } from "../../graphql/notifications.graphql.ts";
import { AppContext } from "../../providers/AppProvider";
import { AuthContext } from "../../providers/AuthProvider";
import {
  callNativeApp,
  callNativeAppAsync,
  NativeAppActions,
} from "../../shared/native-app-helpers";
import { FIXME } from "../../shared/types";

import classes from "./SettingsPage.module.css";

export default function SettingsPage() {
  const { build, version } = useContext(AppContext);
  const { token } = useContext(AuthContext);

  const [showDeveloperMenu, setShowDeveloperMenu] = useState(false);

  const [deleteUserNotificationsTokenMutation] = useMutation(DELETE_USER_NOTIFICATION_TOKEN);

  const longPress = useLongPress(
    () => {
      setShowDeveloperMenu(true);
    },
    { threshold: 2000 }
  );

  const handleDeleteUser = async () => {
    const confirmed = window.confirm("Are you sure? This cannot be undone");
    if (confirmed) {
      await deleteNotificationToken();
      await callNativeApp({ action: NativeAppActions.DELETE_USER });
    }
  };

  const deleteNotificationToken = async () => {
    const { data } = await callNativeAppAsync({
      action: NativeAppActions.GET_NOTIFICATIONS_PERMISSIONS,
    });
    console.log({ fcmToken: data.token });
    if (data.token)
      await deleteUserNotificationsTokenMutation({ variables: { token: data.token } });
  };

  const handleLogOut = async () => {
    await deleteNotificationToken();
    await callNativeApp({ action: NativeAppActions.LOG_OUT });
    // TODO: Remove once everyone is at a latest version
    await callNativeApp({ action: NativeAppActions.SIGN_OUT });
  };

  const handleOpenLink = (url: string) => {
    callNativeApp({
      action: NativeAppActions.OPEN_URL,
      props: { url },
    });
  };

  return (
    <div className={classes.page}>
      <div className={classNames(classes.section, classes.privacy)}>
        <h2 className={classes.subtitle}>Privacy & Security</h2>
        {/* <Button className={classes.settingslink} color="none" fullWidth>
          <div>Change password</div>
          <ChevronRightIcon />
        </Button> */}
        <Button
          className={classes.settingslink}
          color="none"
          fullWidth
          onClick={() => handleOpenLink("https://cliq.ghost.io/terms-and-conditions/")}
        >
          <div>View T&Cs</div>
          <ChevronRightIcon />
        </Button>
        <Button
          className={classes.settingslink}
          color="none"
          fullWidth
          onClick={() => handleOpenLink("https://cliq.ghost.io/privacy-policy")}
        >
          <div>View Privacy Policy</div>
          <ChevronRightIcon />
        </Button>
        <Button className={classes.settingslink} color="none" fullWidth onClick={handleDeleteUser}>
          <div>Delete my account</div>
          <ChevronRightIcon />
        </Button>
      </div>
      {/* <div className={classes.section}>
        <h2 className={classes.subtitle}>Notifications</h2>
        <Button className={classes.settingslink} color="none" fullWidth>
          <div>Manage</div>
          <ChevronRightIcon />
        </Button>
      </div> */}

      <div className={classNames(classes.section, classes.logout, classes.last)}>
        <Button fullWidth onClick={handleLogOut}>
          Log Out
        </Button>
      </div>

      <div className={classes.developer}>
        {showDeveloperMenu && (
          <>
            <p className={classes.debugInfo}>
              <span>Token:</span> {token}
            </p>
            <p className={classes.debugInfo}>
              <span>Expires:</span>{" "}
              {new Date((jwt_decode(token) as FIXME).exp * 1000).toISOString()}
            </p>
          </>
        )}
        <p className={classes.version} {...longPress()}>
          version: {version} ({build})
        </p>
      </div>
    </div>
  );
}
