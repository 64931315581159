import { useContext, useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import { ATTENDING, GET_IS_ATTENDING, UNATTENDING } from "../../../graphql/attending.graphql";
import { GET_EVENT } from "../../../graphql/events.graphql";
import { ChatsContext } from "../../../providers/ChatsProvider";
import { FIXME } from "../../../shared/types";
import { Button } from "../../Forms";

interface AttendingButtonProps {
  event: FIXME;
}

export default function AttendingButton({ event }: AttendingButtonProps) {
  const { updateChats } = useContext(ChatsContext);

  const [isAttending, setIsAttending] = useState(false);
  const [loading, setLoading] = useState(false);

  const [refreshEvent] = useLazyQuery(GET_EVENT, {
    variables: { eventId: event.eventId },
  });

  const { data, refetch: refetchIsAttending } = useQuery(GET_IS_ATTENDING, {
    fetchPolicy: "cache-and-network",
    variables: { eventId: event.eventId },
  });

  const [attending] = useMutation(ATTENDING);
  const [unattending] = useMutation(UNATTENDING);

  const handleOnAttending = async () => {
    setIsAttending(true);
    setLoading(true);

    await attending({
      onCompleted: () => {
        refreshEvent();
        refetchIsAttending();
        updateChats();
      },
      variables: { eventId: event.eventId },
    });
  };

  const handleOnUnattending = async () => {
    const confirmed = window.confirm(`Are you sure you don't want to go to ${event.name}?`);
    if (confirmed) {
      setIsAttending(false);
      await unattending({
        onCompleted: () => {
          refreshEvent();
          refetchIsAttending();
          updateChats();
        },
        variables: { eventId: event.eventId },
      });
    }
  };

  useEffect(() => {
    setIsAttending(!!data?.isAttending);
  }, [data]);

  const isFull = event.maxRsvps && event.attendees.length >= event.maxRsvps;

  if (isAttending)
    return (
      <Button
        color="secondary"
        data-testid="unattend-event-action"
        fullWidth
        onClick={handleOnUnattending}
      >
        Going
      </Button>
    );

  if (isFull) {
    return (
      <Button color="secondary" data-testid="event-fully-booked" fullWidth>
        Fully booked
      </Button>
    );
  }

  return (
    <Button
      data-testid="attend-event-action"
      disabled={loading}
      fullWidth
      onClick={handleOnAttending}
    >
      RSVP
    </Button>
  );
}
