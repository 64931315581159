import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { ChevronRight as ChevronRightIcon } from "@mui/icons-material";

import { GET_IS_JOINED } from "../../../graphql/joining.graphql";
import { FIXME } from "../../../shared/types";
import { callNativeApp, NativeAppActions } from "../../../shared/native-app-helpers";
import { AuthContext } from "../../../providers/AuthProvider";
import { useContext } from "react";
import classes from "./JoinChat.module.css";

interface JoinChatProps {
  club: FIXME;
}

export default function JoinChat({ club }: JoinChatProps) {
  const [isJoined, setIsJoined] = useState(false);
  const { data } = useQuery(GET_IS_JOINED, {
    variables: { clubId: club.clubId },
  });
  const { userId } = useContext(AuthContext);
  useEffect(() => {
    setIsJoined(!!data?.isJoined);
  }, [data]);
  if (!isJoined) {
    return null;
  }
  const handleChat = () => {
    const isAuthor =
      club?.admins?.some((item: { userId: any }) => item.userId === userId) ||
      club.author?.userId === userId;

    const payload = {
      clubId: club?.clubId,
      image: club?.image,
      name: club?.name,
      isAuthor,
    };
    callNativeApp({
      action: NativeAppActions.START_CLUB_CHAT,
      props: { data: payload },
    });
  };

  return (
    <button className={classes.wrapper} onClick={handleChat}>
      <div className={classes.content}>
        <h3 className={classes.title}>Join our chat</h3>
        <p className={classes.text}>Want to get involved? Join our latest discussions.</p>
      </div>
      <div className={classes.arrow}>
        <ChevronRightIcon />
      </div>
    </button>
  );
}
