import { ReactElement, useEffect, useRef, useState } from "react";

import { FIXME } from "../../../shared/types";
import Loading from "../../Shared/Loading";
import Card from "../Card";

import classes from "./List.module.css";
import LoadMoreCard from "../Card/LoadMore.tsx";

interface ListProps {
  clubs: FIXME;
  title: ReactElement | string;
  isLoading?: boolean
  listTotal?: number
  loadMoreAction?: () => Promise<void>
  error?: string
}

export default function List({ clubs, title, isLoading = false, listTotal=0, loadMoreAction, error}: ListProps) {
  const currentLastCardRef = useRef<HTMLLIElement>(null)
  const containerRef = useRef<HTMLUListElement>(null)
  const [savedLastCardRef,setSavedLastCardRef] = useState<HTMLLIElement|null>(null)
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false)

  useEffect(() => {
    if(!isLoadingMore) return

    console.log({savedLastCardRef})

    if(savedLastCardRef && containerRef.current){
      containerRef.current.scrollLeft = savedLastCardRef.offsetLeft
    }
    setIsLoadingMore(false)
  }, [clubs]);

  useEffect(() => {
    if(!error) return

    setIsLoadingMore(false)
  }, [error]);

  const onLoadMoreAction = async () =>{
    setIsLoadingMore(true)
    setSavedLastCardRef(currentLastCardRef.current)
    if(loadMoreAction) {
      await loadMoreAction()
    }
  }

  return (
    <div className={classes.wrapper}>
      <h2 className={classes.title}>{title}</h2>
      <ul className={classes.list} ref={containerRef}>
        {isLoading ? (
          <Loading />
        ) : (
          clubs.map((club: FIXME, index: number) => (
            <li
              ref={index === clubs.length -1 ? currentLastCardRef : undefined}
              className={classes.item} key={`${club.clubId}-${index}`}>
              <Card club={club} />
            </li>
          ))
        )
        }
        { clubs.length < listTotal && !isLoading &&
          <li className={classes.item} key={`load-more`}>
            {isLoadingMore? <Loading /> :<LoadMoreCard clickAction={onLoadMoreAction} text={error? error: 'Load More Clubs'}/>}
          </li>
        }
      </ul>
    </div>
);
}
