// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState } from "react";
import { SentimentVeryDissatisfied as SentimentVeryDissatisfiedIcon } from "@mui/icons-material";

import classes from "./Error.module.css";

export default function ErrorMessage({ message }: { message: string }) {
  const [showError, setShowError] = useState(false);

  return (
    <div className={classes.error}>
      <div>
        <SentimentVeryDissatisfiedIcon />
        <h1>Oh no! Something went wrong...</h1>
        {showError ? (
          <div>
            <pre>{message}</pre>
          </div>
        ) : (
          <button onClick={() => setShowError(true)} type="button">
            Show error
          </button>
        )}
      </div>
    </div>
  );
}

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
      showError: false,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  handleShowError() {
    this.setState({
      showError: true,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={classes.error}>
          <div>
            <SentimentVeryDissatisfiedIcon />
            <h1>Oh no! Something went wrong...</h1>
            {this.state.showError ? (
              <div>
                <pre>{this.state.error?.message}</pre>
                <pre>{JSON.stringify(this.state.errorInfo, null, 2)}</pre>
              </div>
            ) : (
              <button onClick={() => this.handleShowError()} type="button">
                Show error
              </button>
            )}
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
