import { useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { MyLocation as MyLocationIcon } from "@mui/icons-material";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";

import { GET_LOCATIONS_BY_LAT_LON } from "../../../graphql/locations.graphql";
import { callNativeApp, NativeAppActions } from "../../../shared/native-app-helpers";
import { FIXME } from "../../../shared/types";
import IconButton from "../../Forms/IconButton";

import classes from "../Onboarding.module.css";

type LocationProps = {
  error: string;
  locationId: string;
  locations: FIXME[];
  onChange: (value: string) => void;
};

export default function Location({ error, locationId, locations, onChange }: LocationProps) {
  const [getLocationsByLatLon] = useLazyQuery(GET_LOCATIONS_BY_LAT_LON);

  const [loading, setLoading] = useState(false);

  const handleOnGetLocation = () => {
    setLoading(true);
    callNativeApp({
      action: NativeAppActions.GET_LOCATION,
      callback: async (details) => {
        const {
          data: { latitude, longitude },
        } = details;

        const response = await getLocationsByLatLon({
          variables: { latitude, longitude },
        });
        const locations = response.data?.locationsByLatLon;
        const [location] = locations || [];
        onChange(location?.locationId || "");
        setLoading(false);
      },
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.intro}>Sign Up</div>
      <div className={classes.title}>Enter your location</div>
      {error && <div className={classes.error}>{error}</div>}
      <div className={classes.autocomplete}>
        <Autocomplete
          autoComplete
          clearIcon={null}
          disablePortal
          getOptionLabel={(option) => option.name}
          multiple
          options={locations}
          onChange={(_, locations: FIXME) => {
            const location = locations[locations.length - 1];
            onChange(location?.locationId || "");
          }}
          popupIcon={null}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{ ...params.inputProps, required: !locationId }}
              label=""
              placeholder="Location"
            />
          )}
          value={locations.filter((option) => option.locationId === locationId) || []}
        />
        <div className={classes.prepend}>
          {loading ? (
            <CircularProgress color="inherit" size={24} />
          ) : (
            <IconButton
              icon={<MyLocationIcon />}
              label="Select location"
              onClick={handleOnGetLocation}
            />
          )}
        </div>
      </div>
    </div>
  );
}
