import { useContext } from "react";
import { useQuery } from "@apollo/client";
import { ChevronRight as ChevronRightIcon } from "@mui/icons-material";

import { GET_JOIN_REQUESTS } from "../../../graphql/joining.graphql";
import { AuthContext } from "../../../providers/AuthProvider";
import { FIXME } from "../../../shared/types";
import Link from "../../Shared/Link";

import classes from "./JoinRequests.module.css";

interface JoinRequestProps {
  club: FIXME;
}

export default function JoinRequests({ club }: JoinRequestProps) {
  const { userId } = useContext(AuthContext);

  const isAdmin = club.admins.find((admin: FIXME) => admin.userId === userId);

  const { data } = useQuery(GET_JOIN_REQUESTS, {
    fetchPolicy: "cache-and-network",
    variables: { clubId: club.clubId },
    skip: !isAdmin,
  });

  if (!isAdmin) return null;

  const requests = data?.getRequests || [];

  if (requests.length > 0) {
    return (
      <Link className={classes.wrapper} to={`/clubs/${club.clubId}/requests`}>
        <span className={classes.text}>
          {requests.length} request{requests.length !== 1 && "s"} to join
        </span>
        <ChevronRightIcon />
      </Link>
    );
  }

  return null;
}
