import classNames from "classnames";

import logo from "../../../assets/logo.svg";

import classes from "../Onboarding.module.css";

export default function Outro() {
  return (
    <div className={classNames(classes.container, classes.splash)}>
      <div className={classes.logo}>
        <img alt="CLIQ" src={logo} />
      </div>
      <div className={classes.text}>
        <p>
          <strong>Connect with like-minded individuals</strong>
        </p>
      </div>
    </div>
  );
}
