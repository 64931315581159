import { LockSharp as LockSharpIcon } from "@mui/icons-material";

import { FIXME } from "../../../shared/types";
import Toggle from "../Toggle";

import classes from "./Privacy.module.css";

interface PrivacyProps {
  isPrivate?: boolean;
  onChange?: FIXME;
  type: string;
  verb: string;
}

export default function Privacy({ isPrivate, verb, onChange, type }: PrivacyProps) {
  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <LockSharpIcon className={classes.icon} />
        <span className={classes.text}>Privacy</span>
      </div>
      <p className={classes.copy}>
        To join a private {type}, you must request to {verb}, and be accepted by an admin.
      </p>
      <label className={classes.setting}>
        <span className={classes.label}>Private</span>
        <Toggle checked={isPrivate} onChange={onChange} />
      </label>
    </div>
  );
}
