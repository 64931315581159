import { graphql } from "./__generated__/gql";

export const BLOCK = graphql(`
  mutation Block($userId: ID!, $reason: String) {
    block(userId: $userId, reason: $reason) {
      userId
    }
  }
`);

export const GET_IS_BLOCKED = graphql(`
  query GetIsBlocked($userId: ID!) {
    isBlocked(userId: $userId)
  }
`);

export const UNBLOCK = graphql(`
  mutation Unblock($userId: ID!) {
    unblock(userId: $userId) {
      userId
    }
  }
`);
