import { User } from "../../../graphql/__generated__/graphql";
import { FIXME } from "../../../shared/types";
import Avatar from "../../Shared/Avatar";
import { NativeAppActions, callNativeAppAsync } from "../../../shared/native-app-helpers";
import classes from "./Members.module.css";
import Link from "../../Shared/Link";

interface MembersProps {
  club: FIXME;
}

export default function Members({ club }: MembersProps) {
  const handleProfile = (userId: any) => {
    callNativeAppAsync({
      action: NativeAppActions.PROFILE_ID,
      props: { userId: userId },
    });
  };
  return (
    <div className={classes.wrapper}>
      <h2 className={classes.title}>Club members({club.membersCount})</h2>
      <Link className={classes.link} to={`/clubs/${club.clubId}/members`}>
        View all
      </Link>
      <ul className={classes.list}>
        {club.memberlimited.map((member: User, index: number) => (
          <li className={classes.item} key={`${club.clubId}-${index}`}>
            <span className={classes.btn} onClick={() => handleProfile(member?.userId)}>
              <Avatar size={46} user={member} />
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
}
